.blog{
    &__post{
        background-color: $artichoke_light;
        margin: 8px;
        height: calc(100% - 16px);
        display: flex;
        flex-direction: column;
        figure{
            margin: 0;
            position: relative;
            overflow: hidden;
            img{
                object-fit: cover;
                height: 150px;
                width: 100%;
                @include transition(all, 0.2s, ease-in);
                @include media-breakpoint-up(sm) {
                    height: 200px;
                }
            }
            .time{
                background-color: #fff;
                position:absolute;
                z-index: 5;
                left: 15px;
                top: 15px;
                display: flex;
                padding: 2px 8px;
                align-items: center;
                color: $darkest_grey;
                [class^=icon-]{
                    margin-right: 4px;
                }
            }
        }
        .meta-info{
            padding: 20px;
            flex: 2;
            display: flex;
            flex-direction: column;
            .heading{
                margin: 0;
                font-family: $title_font;
                font-size: 1.1rem;
                @include media-breakpoint-up(lg) {
                    font-size: 1.4rem;
                }
                a{
                    color: $darkest_grey !important;

                    &:hover{
                        color: $artichoke_dark !important;
                    }
                }
            }
            .excerpt{
                flex: 2;
            }
        }
        .post-data{
            color: $artichoke_dark;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 0.8rem;
            margin-top: 0.5rem;
            .sep{
                margin: 0 8px;
            }
        }

        &:hover{
            img{
                @include transform(scale(1.1));
            }
        }
    }
    &__full{
        .title{
            text-align: center;
            @include media-breakpoint-up(md) {
                padding: 0 3rem;
            }
            .heading{
                margin: 0;
            }
        }
        .post-data{
            margin: 2rem 0;
            color: $grey;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            .time, .date{
                display: flex;
                align-items: center;
                margin: 0 0.6rem;
                &::before{
                    font-family: 'icomoon';
                    margin-right: 4px;
                }
            }
            .time::before{
                content: "\e911";
            }
            .date::before{
                content: "\e917";
            }
        }
        .image{
            margin-bottom:2rem;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
    &__body{
        img{
            max-width: 100%;
            height: auto;
            display: block;
            margin: 1rem 0;
        }
    }
    &__share{
        padding-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        a{
            margin: 0 1rem;
            font-size: 2rem;
            color: $darkest_grey !important;
            @include media-breakpoint-up(md) {
                margin: 0 1.5rem;
                font-size: 3rem;
            }
            &:hover{
                color: $artichoke_dark !important;
            }
        }
    }
    &__related-post{
        background-color: $vanilla_lightest;
        padding: 2.5rem 0;
        @include media-breakpoint-up(md) {
            padding: 5rem 0;
        }

        .blog-posts{
            @include media-breakpoint-down(sm) {
                max-width: 100%;
            }
        }

        .title{
            text-align: center;
            margin-bottom: 1rem;
            @include media-breakpoint-up(md) {
                margin-bottom: 2rem;
            }
        }
    }
}