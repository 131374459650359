.traceability{
    &__box{
        border-top: 1px solid #ddd;
        @include media-breakpoint-up(lg){
            margin-top: 1.5rem;
        }
        .vertical-traceability &{
            border-top: 0;
        }
        .accordion__title{
            @include media-breakpoint-up(lg){
                justify-content: flex-start;
            }
            i{
                @include media-breakpoint-up(lg){
                    color: $grey;
                    margin-left: 20px;
                }
            }
        }
    }
    &__steps {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%; 
        @include media-breakpoint-up(lg){
            flex-direction: row;
        }
        .vertical-traceability &{
            flex-direction: column;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        > li {
            display: flex;
            flex: 2;
            flex-direction: column;
            position: relative;
            font-size: 0.8rem;
            margin: 0;
            padding: 10px 0;
            @include media-breakpoint-up(sm){
                font-size: 1rem;
            }
            .vertical-traceability &{
                font-size: 1rem;
            }
            [class^="icon-"] {
                background-color: #ffffff; 
                position: relative;
                z-index: 1;
                width: 48px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include border-radius(50%);
                color: $darkest_grey;
                border:1px solid #dddddd;   
                font-size: 1.5rem;
                &.icon-breed{
                    background-image: initial !important;
                }
            }
            &:after {   
                content: '';
                position: absolute;
                display: block;
                background: #dddddd;
                width: 4px;
                height: 100%;
                top: 10px;
                left: 50%;
                margin-left: -2px;
                
                @include media-breakpoint-up(lg){
                    width: 100%;
                    height: 4px;
                    left: 10px;
                    top: 50%; 
                    margin-left: 0;
                }
                .vertical-traceability &{
                    width: 4px;
                    height: 100%;
                    top: 10px;
                    left: 50%;
                    margin-left: -2px;
                }
            }
            &:last-child:after {
                display: none;
            }

            &:nth-child(even){
                .traceability__content{
                    flex-direction: row-reverse;
                    @include media-breakpoint-up(lg){
                        flex-direction: column-reverse;  
                    }
                    .vertical-traceability &{
                        flex-direction: row-reverse !important;
                    }
                    .text{
                        text-align: right;
                        @include media-breakpoint-up(lg){
                            text-align: left;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        }
                        .vertical-traceability &{
                            text-align: right !important;
                            display: initial;
                            flex-direction: initial;
                            justify-content: initial;
                        }
                    }
                }
            }
        }
    }

    &__content{
        display: flex;
        align-items: center;
        @include media-breakpoint-up(lg){
            flex-direction: column;
            align-items: flex-start;
        }
        .vertical-traceability &{
            flex-direction: row;
            align-items: center;
        }
        &::before, .text{
            flex: 2;
            padding: 0 10px;
            @include media-breakpoint-up(lg){
                padding: 5px 10px 5px 0;  
                flex: auto;
                height: 65px;
            }
            .vertical-traceability &{
                padding: 0 10px;
                flex: 2;
                height: auto !important;
            }
        }
        &::before{
            content: "";
            display: block;
        }
        .text{
            .title{
                color: $artichoke_dark;
                line-height: 1;
                padding-bottom: 4px;
                font-size: 0.9rem;
                @include media-breakpoint-up(lg){
                    white-space: nowrap;  
                }
            }
            .val{
                font-family: $title_font;
                font-size: 0.9rem;
                @include media-breakpoint-up(lg){
                    white-space: nowrap;  
                }
                .vertical-traceability &{
                    white-space:initial !important;
                }
            }
        }
    }
}