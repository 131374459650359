.compare{
    &__section{
        .icon-checkmark{
            color:$green_color;
        }
        [class*="col-"]{
            padding: 10px;
            text-align: center;
        }
        .icon-grutto{
            font-size: 2rem;
            color:$primary_color;
        }
    }
    &__spec{
        text-align: left !important;
        white-space: nowrap;
        @include media-breakpoint-down(sm) {
            white-space: initial;
        }
        justify-content: left !important;
    }
    &__item{
        [class*="col-"]{
            border-bottom: 1px solid #ccc;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &__price, &__name{
        padding-top: 20px !important;
        padding-bottom: 20px !important;

        &.bg--gray_light{

            &.compare__name{
                @include border-radius(6px 6px 0 0);
            }
            &.compare__price{
                @include border-radius(0 0 6px 6px);
            }
        }
    }
    &__logo{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

// Type 2 -----------------------
.comparison{
    &__tbl{
        .col__title{
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            .item{
                font-size: 0.9rem;
                @include media-breakpoint-up(lg) {
                    font-size: 1rem;
                }
            }
        }
        .col__body{
            text-align: center;
            flex: 2;
            display: flex;
            overflow-x: scroll;
            @include border-radius(6px);

            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }
            &::-webkit-scrollbar:vertical {
                width: 10px;
            }
            &::-webkit-scrollbar:horizontal {
                height: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, .5);
                border-radius: 10px;
                border: 1px solid #ffffff;
            }
            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #ddd;
            }


            @include media-breakpoint-up(lg) {
                overflow-x: initial;
                box-shadow: initial;
            }
            & > div{
                flex-grow: 1;
                @include media-breakpoint-up(lg) {
                    min-width: 20%;
                }
            }
            &.item-3{
                & > div{
                    @include media-breakpoint-up(lg) {
                        min-width: 33.33%;
                    }
                }
            }
            &.item-4{
                & > div{
                    @include media-breakpoint-up(lg) {
                        min-width: 25%;
                    }
                }
            }
            &.item-5{
                & > div{
                    @include media-breakpoint-up(lg) {
                        min-width: 20%;
                    }
                }
            }
            &.item-6{
                & > div{
                    @include media-breakpoint-up(lg) {
                        min-width: 16.66%;
                    }
                }
            }
        }
        .compareLogo, .item{
            border-bottom: 1px solid $gray-light;
        }
        .compareLogo, .col__body .item{
            padding: 10px;
        }
        .package-per-person{
            padding: 8px 10px; 
            @include media-breakpoint-up(lg) {
                padding: 20px 10px; 
            }
            h4{
                margin: 0;
                line-height: 30px;
                font-size: 20px;
                @include media-breakpoint-up(lg) {
                    font-size: 24px;
                }
            }
        }
        .icon-checkmark{
            color: $green_color;
        }
        .compareLogo{
            line-height: 1;
            @include media-breakpoint-up(lg) {
                line-height: 1.2;
            }
        }
        .col__grutto{
            @include border-radius(6px);
            text-align: center;
            .compareLogo, .item{
                border-color:#fff;
            }
            .compareLogo{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                @include media-breakpoint-up(lg) {
                    font-size: 3rem;
                }
            }
        }
        .item{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        // width -----
        .col__title{
            width: 100px;
            @include media-breakpoint-up(lg) {
                width: 200px;
            }
        }
        .col__grutto{
            width: 70px;
            @include media-breakpoint-up(lg) {
                width: 140px;
            }
        }

        // height -----
        .compareLogo{
            height: 120px;
            @include media-breakpoint-up(lg) {
                height: 140px;
            }
            img{
                height:50px;
                @include media-breakpoint-up(lg) {
                    height: auto;
                }
            }
        }
        .item{
            height: 45px;
            @include media-breakpoint-up(lg) {
                height: 45px;
            }
            &.height--2x{
                height: 85px;
                @include media-breakpoint-up(lg) {
                    height: 70px;
                }
            }
        }

    }
}