.breadcrumb{
    padding: 2rem 0;
    ol{
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;

        li{
            display: flex;
            align-items: center;
            font-size: 0.9rem;
            &::after{
                font-family: 'icomoon';
                content: "\e923";
                width: 26px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
                @include transform(rotate(-90deg));
                color: $artichoke-light;
            }
            &:last-child{
                &::after{
                    content: none;
                }
            }
        }
    }
}