.bundle__popup{
    padding: 65px 0 0 0 !important;
    @include media-breakpoint-up(md){
        padding: 30px !important;
    }
    .modal-dialog{
        background-color: #fff;
        position: relative;
        z-index: 299;
        width: 500px;
        @include media-breakpoint-up(md){
            @include border-radius(6px);
            overflow: hidden;
        }
    }
    .modal-header{
        padding: 1rem;
    }
    .bundle__popup--body{
        overflow-y: scroll;
        height: calc(100vh - 120px);
        @include media-breakpoint-up(md){
            height: 80vh;
        }
    }
}


.popup{
    &_accordion{
        .accordion__item {
            border-top: 1px solid #ddd;
        }
        .accordion{
            &__body{
                max-height: 0;
                overflow: hidden;
                @include transition(all, 0.5s, ease);
            }
            &__item{
                border-top: 1px solid #ddd;

                &.open{
                    .handle{
                        &::after{
                            transform: rotate(-180deg);
                        }
                    }
                    .accordion__body{
                        max-height: 5000px;
                    }
                }
            }
            &__title{
                .handle{
                    position: relative;
                    cursor: pointer;
                    padding:0.8rem 2.5rem 0.8rem 0.8rem;
                    font-family: $title_font;
                    line-height: 1.4;
                    font-size: 0.9rem !important;
                    display: flex;
                    align-items: center;
                    @media (min-width: 425px) {
                        font-size: 1rem !important;
                    }
                    @media (min-width: 768px) {
                        font-size: 1.125rem !important;
                    }
                    &::after{
                        content: "";
                        width: 30px;
                        height: 30px;
                        position: absolute;
                        display: block;
                        top: 50%;
                        margin-top: -15px;
                        right: 0.5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $secondary_color;
                        font-family: "icomoon";
                        content: "\e923";
                        @include transition(all, 0.4s, ease);
                        font-size: 1.4rem;
                        font-weight: normal;
                    }
                    [class^=icon-]{
                        margin-right: 8px;
                        font-size: 1.5rem;
                        width: 30px;
                        text-align: center;
                    }
                }
            }
        }
    }
}