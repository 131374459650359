.farmshop-items{
    max-width: 100%;
    padding-bottom: 3rem;
    @include media-breakpoint-up(md) {
        padding-bottom: 0;
    }
    @include media-breakpoint-up(xxl) {
        @include make-container-max-widths();
    }

    .row{
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row-reverse;

        .pack-overview{
            background-color: #fff;
            width: 100%;
            display: flex;
            flex-direction: column;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 19;
            @include box-shadow(1px ,1px , 10px, rgba(0,0,0,0.2));
            @include media-breakpoint-up(md) {
                flex-direction: column-reverse;
                width: 35%;
                margin:0.8rem;
                z-index: initial;
                position: sticky;
                top: 172px;
                @include box-shadow(0 ,0 , 0, rgba(0,0,0,0.0));
                margin-top: 62px;
            }
            @include media-breakpoint-up(lg) {
                width: 28%;
            }
            @include media-breakpoint-up(xl) {
                width: 24%;
            }
            .title{
                font-size: 1.1rem;
                text-align: center;
                font-family: $title_font;
                color: $primary;
                margin-bottom: 0.4rem;
            }
            .overview-items, .cta{
                filter: blur(0px);
                @include transition(all, 0.3s, linear);
            }
            .overview-items{
                max-height: 0;
                overflow: hidden;
                @include media-breakpoint-up(md) {
                    display: block;
                    max-height: 58vh;
                    overflow: auto;
                }
                &-holder{
                    padding: 0.6rem;
                }
                .itm{
                    padding: 4px 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: $vanilla_lightest;
                    position: relative;
                    &:nth-child(2n-1){
                        background-color: #fff;
                    }

                    .icon-gift{
                        font-size: 1.5rem;
                        color: $primary;
                    }
                }
                .quantity{
                    width: 100px;
                    input{
                        background-color: transparent;
                    }

                    .plus-btn{
                        span{
                            display: none;
                        }
                    }
                }
                .h-title{
                    display: flex;
                    flex-direction: column;
                    flex: 2;
                    padding-right: 1rem;
                    .name{
                        font-family: $title_font;
                    }
                    .info{
                        color: $grey;
                        font-size: 0.9rem;
                    }
                }
                .empty-cart{
                    font-size: 0.9rem;
                    text-align: justify;
                    background-color: $vanilla_lightest;
                    padding: 1.5rem 0.5rem;
                }
            }
            .cta{
                display: flex;
                align-items: center;
                border-bottom: 1px solid $lightest_grey;
                padding: 0.4rem 0.8rem;
                @include media-breakpoint-up(md) {
                    padding: 0.6rem;
                    border-bottom: 0;
                }
                .price{
                    flex: 2;
                    padding-right: 0.5rem;
                    display: flex;
                    align-items: center;
                    .amount{
                        margin-right: 0.5rem;
                        font-size: 1.5rem;
                        @include media-breakpoint-up(md) {
                            font-size: 2.125rem;
                        }
                        line-height: 1;
                        font-family: $title_font;
                        sup{
                            top: -6px;
                            left: 0px;
                            font-size: 1.1rem;
                            @include media-breakpoint-up(md) {
                                font-size: 1.125rem;
                                top: -10px;
                                left: -2px;
                            }
                        }
                    }
                    &::before{
                        font-family: 'icomoon' !important;
                        content: '\e904';
                        width: 30px;
                        height: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.4rem;
                        color: $artichoke_regular;
                        margin-right: 6px;
                        @include media-breakpoint-up(md) {
                            content: none;
                        }
                    }
                    .txt{
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                    }
                }
                .btn{
                    font-size: 0.9rem !important;
                    &.disabled{
                        background-color: #bababa;
                        border-color: #bababa;
                        cursor: initial;
                    }
                }
            }
            &.open{
                .overview-items{
                    @include media-breakpoint-down(sm) {
                        max-height: 48vh;
                        overflow: auto;
                    }
                }
                .price::before{
                    @include transform(rotate(180deg));
                }
            }
            &.is-loading{
                .btn{
                    cursor: progress !important;
                }
            }
        }
        .col{
            @include media-breakpoint-up(md) {
                flex: 2;
            }
        }
    }
}