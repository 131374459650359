.mission{
    &__slide{
      background-color: $artichoke_lightest;
      position: relative;
      .cow{
            width: 170px;
            height: 210px;
            position: absolute;
            right: 0;
            bottom: 0;
            img{
                width: 100%;
                height: 100%;
                opacity: 0.2;
                @include media-breakpoint-up(lg){
                    opacity: 1;
                }
            }
            @include media-breakpoint-up(xs){
                width: 210px;
                height: 259px;
            }
      }
      .chicken{
        position: absolute;
        bottom: 60px;
        opacity: 0.2;
        @include media-breakpoint-up(lg){
            opacity: 1;
        }
      }
      &__logo{
        max-width:300px;
        margin:20px auto;
        text-align: center;
        color:$red_color;
        font-size: 5rem;
        line-height: 1;
        @include media-breakpoint-up(md){
            font-size: 8rem;
        }
      }
    }
    &__content{
      padding-bottom: 40px;
      text-align: center;
      z-index: 9;
      position: relative;

      @include media-breakpoint-down(md){
          //padding-bottom: 300px;
      }
    }
}



.carousel{
    height: 450px;
    position: relative;
    @include media-breakpoint-up(md){
        height:600px;
    }
    &__holder{
        position: relative;
        width: 100%;
        overflow: hidden;
        .text__holder{
            z-index: 10;

            h1{
                @extend .heading--h1;
                margin-bottom: 0.5rem;
            }
            h2{
                @extend .heading--h2;
            }
            h3{
                @extend .heading--h3;
            }
            h4{
                @extend .heading--h4;
            }  

        }
        &.slider-text-top, &.slider-text-bottom, &.slider-text-left, &.slider-text-right{
            .carousel{
                height: 300px;
                @include media-breakpoint-up(md){
                    height:400px;
                }
                @include media-breakpoint-up(lg){
                    height:600px;
                }
            }
        }

        &.slider-text-right, &.slider-text-left{
            @extend .row;
            margin: 0 !important;
            align-items: center;
            .text{               
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 16px;
                padding-right: 16px;

                
                @include media-breakpoint-up(sm){
                    max-width: 540px;
                    margin: 0 auto;
                }
                @include media-breakpoint-up(md){
                    max-width: 720px;
                    margin: 0 auto;
                    padding-left: 0;
                    padding-right: 0;
                }
                @include media-breakpoint-up(lg){
                    max-width: 390px;
                    margin: 0;
                }
                @include media-breakpoint-up(xl){
                    max-width: 485px;
                }
                @include media-breakpoint-up(xxl){
                    max-width: 558px;
                }
            }
            .text__holder{
                padding: 0;
                display: flex;
            }
            .carousel{
                padding: 0;
            }
            .text__holder{
                @extend .col-lg-5;
            }
            .carousel{ 
                @extend .col-lg-7;
            }
        }
        &.slider-text-right{
            flex-direction: column-reverse;
            @include media-breakpoint-up(lg){
                flex-direction: row-reverse;
            }
            &.mobile-reverse{
                flex-direction: column;
                @include media-breakpoint-up(lg){
                    flex-direction: row-reverse;
                }
            }
            .text{
                @include media-breakpoint-up(lg){
                    padding-left: 30px;
                    padding-right: 0;
                }
            }
        }
        &.slider-text-left{
            .text__holder{
                justify-content: flex-end;
            }
            &.mobile-reverse{
                flex-direction: column-reverse;
                @include media-breakpoint-up(lg){
                    flex-direction: row;
                }
            }
            .text{
                @include media-breakpoint-up(lg){
                    padding-right: 30px;
                    padding-left: 0;
                }
            }
        }

        &.slider-text-top, &.slider-text-bottom{
            height: auto;
            text-align: center;
            display: flex;
            flex-direction: column-reverse;
            .carousel, .text__holder{ 
                @extend .container;
                padding: 0;

                @include media-breakpoint-down(sm){
                    max-width: 100%;
                }
            }
            .carousel{
                padding: 0 !important;
            }
            .text{
                padding-right: 5px;
                padding-left: 5px;
            }
        }
        &.slider-text-top{
            flex-direction: column;
            padding: 30px 0 0 0;
            @include media-breakpoint-up(md){
                padding: 40px 0 40px 0;
            }
            @include media-breakpoint-up(lg){
                padding: 60px 0 60px 0;
            }
            .text{
                margin-bottom: 20px;
            }
        }
        &.slider-text-bottom{
            padding: 0 0 30px 0;
            @include media-breakpoint-up(md){
                padding: 40px 0 40px 0;
            }
            @include media-breakpoint-up(lg){
                padding: 60px 0 60px 0;
            }
            .text{
                margin-top: 20px;
            }
        }

        &:hover{
            .carousel__bottom{
                opacity: 1;
            }
            .carousel__nav{
                opacity: 1;
            }
        }
    }
    &__video{
        .video{
            background-color: black;
        }
        
        // video background color
        .bg--transparent{
            .video{
                background-color: transparent !important;
            }
        }
        
        &.slider-text-top, &.slider-text-bottom, &.slider-text-left, &.slider-text-right{
            .carousel{
                height: auto !important;
                overflow: hidden;

                .swiper-wrapper, .video, .item{
                    position: relative;
                    padding-bottom: 56.25%; /* 16:9 */
                    height: 0;

                }
                iframe, img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                .carousel__content{
                    position: absolute !important;
                }
                .carousel__bottom{
                    z-index: 999999999999999999999;
                }
                // -------
                @include media-breakpoint-down(sm){
                    max-width: 100%;
                }
            }
        }
    }

    &__content{
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding:0 40px #{$grid-gutter-width * 2} 40px;
        height: calc(100% - 70px);
        @include media-breakpoint-up(lg){
            height: calc(100% - 80px);
            bottom: 40px;
            padding: #{$grid-gutter-width * 2};
        }

        img{
            width: auto !important;
            height: auto !important;
        }
        .area{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    &__nav{
        position: absolute !important;
        width: 100%;
        bottom: 0;
        padding:0 0 10px 0;
        list-style: none;
        display: flex;
        justify-content: center;
        z-index: 20;
        margin: 0;
        @include transition(all, 0.2s, ease-in);
        @include media-breakpoint-up(lg){
            padding:0 0 15px 0;
            opacity: 0.5;
        }
        li{
            height: 14px;
            width: 14px;
            margin: 0 4px;
            border:1px solid #fff;
            @include border-radius(20px);
            @include box-shadow(1px ,1px , 5px, rgba(0,0,0,0.4));
            cursor: pointer;
            @include media-breakpoint-up(lg){
                height: 16px;
                width: 16px;
                border:2px solid #fff;
            }
            &.active{
                background-color: #fff;
            }
        }
    }
    &__bottom{
        position: absolute !important;
        height: 0;
        top: 50%;
        margin-top: -20px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        @include transition(all, 0.2s, ease-in);
        @include media-breakpoint-up(lg){
            opacity: 0;   
            padding: 0 5px;
        }
        .prev, .next{
            width: 40px;
            height: 40px;
            display: flex;
            background-color: rgba(255,255,255,0.4);
            @include transition(all, 0.4s, ease);
            @include media-breakpoint-up(md){
                margin: 0 8px;
                padding: 0;
            }
            span{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0.3;
                color: $darkest_grey;
                font-weight: bold;
            }
            &.active{
                cursor: pointer;
                background-color: rgba(255,255,255,0.9);
                span{
                    opacity: 1;
                    color: $darkest_grey;
                }
                &:hover{
                    background-color: #fff;
                    @include box-shadow(1px, 1px, 8px, rgba(0,0,0,0.2));
                }
            }
        }
        .next{
            span{
                @include transform(rotate(0));
            }
        }
        .prev{
            span{
                @include transform(rotate(180deg));
            }
        }
    }
    &:hover{
        .carousel__bottom{
            opacity: 1;
        }
        .carousel__nav{
            opacity: 1;
        }
    }
    .badge{
        background-color: #fff;
        padding: 4px 20px;
        @include border-radius(20px);
        .badge__item{
            display: flex;
            align-items: center;
            font-size: 1rem;
            color:$primary_color;
            [class^="icon-"] {
                margin-right: 4px;
            }
        }
    }
    .swiper-container{
        width: 100%;
    }

    .items{
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .item{
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }
            &.object-top{
                .slider-img{
                    object-position: top;
                }
            }
            &.object-center{
                .slider-img{
                    object-position: center;
                }
            }
            &.object-bottom{
                .slider-img{
                    object-position: bottom;
                }
            }
        }
        &.slider-single-item{
            .item{
                width: 100%;
            }
        }
    }

    .video, iframe{
        width: 100%;
        height: 100%;
    }

}

.title__width{
    width: 100%;
    @include media-breakpoint-up(sm){
        width: 100%;
    }
    @include media-breakpoint-up(md){
        width: 500px;
    }
    @include media-breakpoint-up(lg){
        width: 600px;
    }
    @include media-breakpoint-up(xl){
        width: 700px;
    }
}

.title{
    &__special{ // Handwritten font
        font-family: $title_font;
        font-size: 3.75rem;
        margin: 0;
        font-weight: 300;
        @include media-breakpoint-up(sm){
            font-size: 4.25rem;
        }
        @include media-breakpoint-up(lg){
            font-size: 5rem;
        }
    }
    &__general{ // General titles fonts
        font-family: $title_font;
        font-size: 2rem;
        line-height:2.25rem;
        margin: 0;
        @extend .title__width;
        @include media-breakpoint-up(sm){
            font-size: 2.8rem;
            line-height:2.8rem;
        }
        @include media-breakpoint-up(lg){
            font-size: 3.3rem;
            line-height:3.3rem;
        }
    }
    &__body-light{
        font-family: $title_font;
        font-size: 1.2rem;
        line-height: 1.5rem;
        font-weight: 100;
        margin: 0;
        @extend .title__width;
        @include media-breakpoint-up(sm){
            font-size: 1.6rem;
            line-height: 2rem;
        }
        @include media-breakpoint-up(lg){
            font-size: 1.9rem;
            line-height: 2.2rem;
        }
    }
    &__body-bold{
        font-family: $title_font;
        font-size: 1.25rem; 
        margin: 0;
        line-height: 1.5rem;
        @extend .title__width;
        @include media-breakpoint-up(sm){
            font-size: 1.6rem;
            line-height: 2rem;
        }
        @include media-breakpoint-up(lg){
            font-size: 1.9rem;
            line-height: 2.3rem;
        }
    }
    &__body-small{
        font-family: $title_font;
        font-size: 1rem; 
        margin: 0;
        line-height: 1.3rem;
        @extend .title__width;
        @include media-breakpoint-up(sm){
            font-size: 1.3rem;
            line-height: 1.5rem;
        }
        @include media-breakpoint-up(lg){
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }
}

// 768px image responsive breakpoint up(md)
// .size-md-2x for svg content size
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        .size#{$infix}-2x { @include transform(scale(1.4)); transform-origin: 50% 100%; }
        .size#{$infix}-3x { @include transform(scale(1.8)); transform-origin: 50% 100%; }
        .size#{$infix}-4x { @include transform(scale(2.2)); transform-origin: 50% 100%; }
    }
}
.origin-bottom{transform-origin: 50% 0;}

// The first version of the slider text
.carousel{
    &__text{
        font-family: $title_font;
        font-size: 2.7rem;
        line-height: 52px;
        max-width: 780px;
        font-weight: 300;
        color:$primary_color;
        @include media-breakpoint-down(md){
            font-size: 2.2rem;
            line-height: 1.2;
        }
        @include media-breakpoint-down(xs){
            font-size: 1.8rem;
            line-height: 1.2;
        }
        &--bold{
            font-family: $title_font;
        }
        &--light{
            color:#fff;
        }
        &--black{
            color:$darkest_grey;
        }
        &--underline{
            text-decoration: underline;
            text-underline-position: under
        }

        &_desc{
            width: 320px;
            font-size: 1.3rem;
            line-height: 1.1;
            font-family: $title_font;

            .arrow_down{
                margin-bottom: -15px;
            }
        }

    }
}

// --------------------------
.arrow-disabled {
    pointer-events: none;
}
.customSlider{
    width: 100%;
}
.slider{
    width: 100%;
}
.slider .slide{
    width: 100%;
    height: 601px;
    object-fit: cover;
}
.slider .slide img{
    width: 100vw;
    height: 601px;
    object-fit: cover;
    display: block; 
}