html{
  scroll-behavior: smooth;
}
body, #root {
  min-height: 100vh;
  width: 100%;
}
#root {
    display: flex;
    flex-direction: column;
    .page--body {
        flex: 2;
        min-height: calc(70vh - 66px);
        @include media-breakpoint-up(md) {
          min-height: calc(70vh - 93px);
        }
    }
}
iframe{
  border: 0;
}
.container {
  padding-left: calc($grid-gutter-width *2);
  padding-right: calc($grid-gutter-width *2);
  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
  &-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .row {
    // width: 100%;
    &__spacing {
      margin-top: calc($grid-gutter-width *4);
    }
    &.no-gutter {
      margin: 0;
    }
  }
}

// text-align
.text{
  &--center{
      text-align: center;
      iframe{
        margin: 0 auto;
      }
      video{
        margin: 0 auto;
        @include media-breakpoint-down(sm) {
          width: 100%;
          height: auto;
        }
      }
  }
  &--right{
      text-align: right;
  }
  &--left{
      text-align: left;
  }
  &--justify{
    text-align: justify;
  }
  &--bold{
    font-weight: bold;
  }
  &--nowrap{
    white-space: nowrap;
  }
  &--underline{
    text-decoration: underline;
  }
  &-uppercase, &--uppercase{
    text-transform: uppercase;
  }
  &-lowercase, &--lowercase{
    text-transform: lowercase;
  }
  &-initial, &--initial{
    text-transform: initial !important;
  }
}

.back__row{
  min-height: 10px;
  a{
    display: none;
  }
}

// Link
a, a:link {
    color: $artichoke_dark;
    @include transition(all, 0.2s, linear);

    &:hover {
        text-decoration: none;
        color: $darkest_grey;
    }
}

// Image
.img-fluid{
  max-width: 100%;
}

// BTN -------
.btn, a.btn {
    padding: 0.6rem 1rem 0.7rem 1rem;
    line-height: 1;
    background-color: $primary_color;
    color:#fff;
    display: inline-flex;
    align-items: center;
    @include transition(all, 0.2s, linear);
    font-size: 1.1rem;
    border:0;
    border:1px solid $primary_color;
    cursor: pointer;
    text-transform: uppercase;
    
    &:hover{
      color:#fff;
      background-color: darken($primary_color, 10%);
    }

    // BTN color -------
    &--primary, &--blue{
      background-color: $primary_color;
      color:#fff !important;
      border-color: $primary_color;
      &:hover {
          color:#fff;
          background-color: darken($primary_color, 10%);
      }
    }
    &--secondary, &--red{
      background-color: $secondary_color;
      color:#fff !important;
      border-color: $secondary_color;
      &:hover {
          color:#fff;
          background-color: darken($secondary_color, 18%);
      }
    }
    &--light, &--white{
      background-color: #fff;
      color: $darkest_grey;
      border-color: #fff;
      &:hover{
          background-color: $vanilla_light;
          border-color: $vanilla_light;
          color: $darkest_grey;
      }
    }
    &--caramel{
      background-color: $caramel_color;
      color:$darkest_grey;
      border-color:$caramel_color;
      &:hover{
        color:$darkest_grey;
        background-color: darken($caramel_color, 10%);
      }
    }
    &--green{
      background-color: $green_color;
      color:#fff;
      border-color:$green_color;
      &:hover{
        color:#fff;
        background-color: #3F802F;
      }
    }


    // BTN size -------
    &--full {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &--full-80 {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    // BTN type -------
    &--outline, &--border{
      background-color: transparent;
      border:1px solid $primary_color;
      color:$primary_color;
      &.btn--red, &.btn--secondary{
        border:1px solid $secondary_color;
        color:$secondary_color !important;
        &:hover{
          background-color:$secondary_color;
          color:#fff !important;
        }
      }
      &.btn--white{
        border:1px solid $white;
        color:$white;
        &:hover{
          background-color:$white;
          color:$darkest_grey;
        }
      }
      &:hover{
        background-color:$primary_color;
        color:#fff;
      }
    }
    &--disable{
      cursor: auto;
      background-color: $gray-base !important;
      border-color:$gray-base !important;
      color:#fff !important;
      &.btn--secondary, &.btn--secondary:hover{
        color:#868686 !important;
      }
      &:hover{
        background-color:$gray-base !important;
        color:#fff !important;
      }
    }
}

// Font
.font{
  &-italic{
    font-style: italic;
  }
  &-bold{
    font-weight: bold;
  }
}

// Product top switch item
.button-group{
    display: flex;
    padding-bottom: 5px;
    background-color: #fff;

    &__holder{
      padding-bottom: 5px;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar  {
        height: 3px;
        background: $gray-light;
        @include border-radius(5px);
      }
      &::-webkit-scrollbar-thumb {
        background-color:darken($color: $gray-light, $amount:30);
        @include border-radius(5px);
      }
      @include media-breakpoint-up(lg) {
        overflow-x: initial;
      }

    }
    @include media-breakpoint-up(lg) {
      @include border-radius(100px);
      overflow-x: initial;
      padding: 3px;
      background-color: $gray_light;
    }
    &__item{
      min-height: 38px;
      flex-basis: 1;
      flex: 1;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-family: $title_font;
      font-weight: 500;
      color:$darkest_grey !important;
      margin-right: 2px;
      @include border-radius(4px);
      background-color: rgba(0,0,0,0.05);
      text-align: center;
      padding: 0 5px;
      line-height: 1;
      white-space: nowrap;
      @include media-breakpoint-up(sm) {
        font-size: 1rem;
      }
      @include media-breakpoint-up(lg) {
        min-width: initial;
        background-color: rgba(0,0,0,0);
        font-size: 1.2rem;
      }
      &:first-child{
          @include border-radius(18px 4px 4px 18px);
      }
      &:last-child{
        @include border-radius(4px 18px 18px 4px);
        margin-right: 0;
      }
    }
    &--red{
      &.active{
        background-color: $red_color;
        color:#fff !important;
      }
      @include media-breakpoint-up(lg) {
        &:hover{
          background-color: $red_color;
          color:#fff !important;
        }
      }
    }
    &--blue{
      &.active{
        background-color: $primary_color;
        color:#fff !important;
      }
      @include media-breakpoint-up(lg) {
        &:hover{
          background-color: $primary_color;
          color:#fff !important;
        }
      }
    }
    &--black{
      &.active{
        background-color: #000;
        color:#fff !important;
      }
      @include media-breakpoint-up(lg) {
        &:hover{
          background-color: #000;
          color:#fff !important;
        }
      }
    }
}

// Table ------------------------------
.nutritional-facts{
  width: 100%;
  margin: 32px 0;
  tbody{
    tr{
      &:nth-child(odd){
        background-color: lighten($color: $black, $amount: 88%);
      }
      td{
        padding: 5px;
        border-bottom: 1px solid lighten($color: $black, $amount: 80%);
      }
    }
  }
}

// input ------------------------------
.form-control{
  border:1px solid #BEBEBE;
  padding: 8px 16px;
  @include transition(all, 0.2s, linear);
  &:focus{
    outline: 0;
    border-color: $primary_color;
  }
}

.input{
  &__checkbox{
    input{
      display: none;
    }
    label{
      cursor: pointer;
      position: relative;
      padding-left: 25px;
      &:before, &:after{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
      }
      &:before{
        color: #cccccc;
        font-size: 1.5rem;
        content: "";
        border: 1px solid #BBB;
        background-color: #fff;
        @include border-radius(4px);
      }
    }
    input[type=checkbox]:first-child:checked + label::before{
      background-color: $primary_color;
      border-color: $primary_color;
    }
    input[type=checkbox]:first-child:checked + label::after{
      font-family: 'icomoon' !important;
      content: '\e909';
      font-size: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}


// Text Shadow -------------------------
.text{
  &--shadow1{
      @include text-shadow(2px, 2px, 3px, rgba(0,0,0,0.2));
  }
  &--shadow2{
      @include text-shadow(3px, 4px, 4px, rgba(0,0,0,0.3));
  }
  &--shadow3{
      @include text-shadow(3px, 5px, 6px, rgba(0,0,0,0.4));
  }
  &--shadow4{
      @include text-shadow(4px, 5px, 8px, rgba(0,0,0,0.5));
  }
}

// ul , ol - list -------------------------
ul.list, ol.list{
    padding-left: 20px;
    margin-bottom: 30px;
    &--green {
        color:$green_color;
    }
    &--bold{
      font-weight: bold;
    }
    &--checkbox {
        list-style: none;
        padding-left: 0;
        font-weight: bold;
        li {
            padding-bottom: 3px;
            &:before {
                font-family: "icomoon";
                content: "\e909";
                margin-right:5px;
                font-size: 0.7rem;
            }
        }
    }
    &--underline {
        list-style: none;
        padding-left: 0;
        li {
            padding: 8px 0;
            border-bottom: 1px solid $gray_light;
            &:last-of-type {
              border-bottom: 0;
            }
        }
    }
    &--numbers {
      list-style: none;
      ounter-reset: list-counter;
      > li {
        margin-bottom: calc($grid-gutter-width *2);
        counter-increment: list-counter;
        padding-left: 40px;
        position: relative;
        &::before {
          content: counter(list-counter);
          background: $gray-light;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          display: inline-block;
          line-height: 2rem;
          text-align: center;
          margin-right: 0.5rem;
          position: absolute;
          left: 0;
          top:-4px;
        }
      }
    }
    &--primary{
        background-color: $primary_color;
        .heading{
            color:#fff;
        }
    }
    &--no-list {
      list-style: none;
      padding: 0;
      margin:0;
    }
    &--icon{
      list-style: none;
      padding: 0;
      li{
        display: flex;
        align-items:center;
        padding:6px 0;
      }
      [class^="icon-"] {
        margin-right:10px;
        font-size: 1.7rem;
        min-width: 28px;
      }
    }
}

// Section - Page
.section{
  &--gray{
      background-color: $gray_light;
  }
  &--primary{
      background-color: $primary_color;

      .heading{
          color:#fff;
      }
  }
}

// Title Heading
.htitle{
    letter-spacing: 1.5px;
    font-weight: 900;
    margin: 10px 0;
    text-transform: uppercase;
    font-size: 1.1rem !important;
    font-family: $body_font !important;

    &--line{
        text-align: center;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &::after, &::before{
            content: "";
            height: 1px;
            background-color: $darkest_grey;
            width: 100%;
        }
        &::after{
            margin-left: 20px;
        }
        &::before{
            margin-right: 20px;
        }
    }

    &--red{
        color:red;

        &::after, &::before{
            background-color: red;
        }
    }
}

// Page Body heading
.page__body{
  h2{
    @extend .heading--h2;
  }
  h3{
    @extend .heading--h3;
  }
  h4{
    @extend .heading--h4;
  }
  h5{
    @extend .heading--h5;
  }
}

// Module ------------------------------
.module{
  &__text-box, &__html-box{
    h2{
      @extend .heading--h2;
    }
    h3{
      @extend .heading--h3;
    }
    h4{
      @extend .heading--h4;
    }
    h5{
      @extend .heading--h5;
    }
  }

  &__category{
    background-color:$gray_light;
    min-height: 684px;
    @include media-breakpoint-up(md) {
      min-height: 684px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 966px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 908px;
    }
    @include media-breakpoint-up(xxl) {
      min-height: 942px;
    }

    &.none--categorey{
      min-height: initial;
    }
  }
  &__category-switch{
    background-color: $vanilla-lightest;
    .tabs{
      background-color: $vanilla-lightest;
      list-style: none;
      margin: 0;
      display: flex;
      padding: 10px;
      position: sticky;
      top: 65px;
      z-index: 88;
      overflow: auto;
      justify-content: center;
      @include media-breakpoint-up(md) {
        z-index: 50;
      }
      @include media-breakpoint-up(md) {
        top: 90px;
      }
      @include media-breakpoint-up(lg) {
        top: 0;
      }
      &.left{
        justify-content: flex-start;
        @media (min-width: 425px) {
          justify-content: center;
        }
      }
      li{
        display: flex;
        margin: 0 4px 0 0;
        flex: 2;
        @media (min-width: 425px) {
          flex: initial;
        }
        a.category__item{
          background-color: #fff;
          margin: 0;
          padding: 4px 8px;
          flex:2;
          @include media-breakpoint-up(sm) {
            padding: 4px 16px;
          }
          &.active{
            background-color: $artichoke_dark;
            color: $white !important;
            [class^=icon-] {
              filter: invert(1) brightness(2);
            }
          }
          .body{
            margin: 0;
            @include border-radius(0);
            padding: 0;
            background-color: transparent;
            min-width:initial;
            figure{
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 42px;
              height: 30px;
            }
            .title{
              font-size:1rem;
              font-family: $body_font;
              margin: 2px 0 0 0;
            }
          }
        }
      }
    }
    .category__items{
      padding: 1rem 0;
      @include media-breakpoint-up(md) {
        padding: 1rem 0;
      }
      .animal_group{
        width: calc(100% - 16px);
        margin: 1rem 8px;
        display: flex;
        padding-left: 0;
        padding-right: 0;

        .title{
            display: flex;
            font-family: $title_font;
            width: 100%;
            line-height: 1;
            align-items: flex-end;
            font-size: 1.2rem;
            width: 100%;
            @media (min-width: 768px) {
                font-size: 1.4rem;
            }
            &::after{
                content: " ";
                height: 1px;
                flex: 2;
                background-color: $vanilla-dark;
                align-items: flex-end;
                margin: 0 0 4px 10px;
            }
        }

        hr{
            margin: 0;
            border: 0;
            margin-top: -160px;
            @include media-breakpoint-up(md) {
              margin-top: -180px;
            }
            @include media-breakpoint-up(lg) {
              margin-top: -80px;
            }

        }
      }

      .highlight--grutto_choice{
        width: initial !important;
        margin-left: 8px;
        margin-top: 0;
        .package__title a:before{
          transform: scale(0.8);
          right: -3px !important;
        }
      }
    }
    .x-scroll{
      .container{
        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
        > .row{
          padding-top: 10px;
          margin-bottom: 30px;
          @include media-breakpoint-up(lg) {
            // justify-content: center;
          }
        }
      }
    }
    &.none--categorey{
      .container > .row{
        margin: 2rem 0;
        @include media-breakpoint-up(md) {
          margin: 3rem 0 2rem 0 ;
          justify-content: center;
        }
      }
      .animal_group{
        display: none;
      }
    }
  }

  &__title{
    text-align: center;
    padding: 0 1.8rem;
    margin:0 auto 0.6rem auto;
    @include media-breakpoint-up(md) {
      margin-bottom: 1.6rem;
      max-width: 1100px;
    }
    p{
      margin: 0;
    }
  }
  &__farmer{
    height: 250px;
    position: relative;
    background-color: $caramel_color;
    @include media-breakpoint-up(md) {
      height: 350px;
    }
    @include media-breakpoint-up(lg) {
      height: 450px;
    }
    &__expand{
      height: 150px;
      background-color: $gray_light;
      @include media-breakpoint-up(md) {
        display: none;
        height: auto;
      }
    }
    &__bg{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__body{
      width: 100%;
      position: absolute;
      z-index: 20;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-top: 30px;
      @include gradient(rgba(0,0,0,0), rgba(0,0,0,1));
      .col{
        margin-bottom: -130px;
        @include media-breakpoint-up(md) {
          margin-bottom: -10px;
        }
        @include media-breakpoint-up(lg) {
          margin-bottom: -20px;
        }
      }
    }
    &__info{
      display: flex;
      align-items: center;
      flex-direction: column;
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
    &__image{
      @include media-breakpoint-up(md) {
        padding-right:1.2rem;
      }
      img{
        width: 140px;
        height: 140px;
        background-color: #fff;
        @include border-radius(100%);
        object-fit: cover;
        border:2px solid #fff;
        @include media-breakpoint-up(md) {
          width: 200px;
          height: 200px;
        }
      }
      .icon-farmers{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-color: #e6e6e6;
        @include border-radius(100%);
        font-size: 6rem;
        overflow: hidden;
        color:#BBB;
        border:2px solid #fff;
        width: 140px;
        height: 140px;

        @include media-breakpoint-up(md) {
          width: 200px;
          height: 200px;
          font-size: 8rem;
        }
      }
    }
    &__text{
      flex: 2;
      padding-top:1rem;
      @include media-breakpoint-up(lg) {
        padding-top:1.5rem;
      }
      .badge{
        position: absolute;
        width: 100%;
        top:0;
        left: 0;
        justify-content: center;
        margin-top: -90px;
        display: flex;
        @include media-breakpoint-up(md) {
          position: initial;
          width: auto;
          margin-bottom: 1rem;
          margin-top: 0;
          justify-content: flex-start;
        }
        &__item{
          background-color: #fff;
          padding:6px 12px;
          display: flex;
          align-items: center;
          span{
            margin-right: 5px;
          }
        }
      }
    }
    &__name{
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media-breakpoint-up(md) {
        justify-content: space-between;
        align-items: flex-start;
      }
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
      .heading{
        margin-bottom: 1rem;
        @include media-breakpoint-up(lg) {
          margin:0;
        }
      }
      a{
        color:$darkest_grey;
        @include media-breakpoint-up(md) {
          color:#fff;
        }
        &:hover{
          @include media-breakpoint-up(md) {
            color:$vanilla_lightest;
          }
        }
      }
    }
    &__cta{
      white-space: nowrap;
    }
  }
  &__meatcut{
    padding-top: 90px;
    padding-bottom: 60px;
  }

  // newsletter
  &__newsletter{
    padding: 2rem 1rem;
    background-color: $vanilla_light;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      padding: 3rem 0;
    }
    .body{
      h2{
        color: $darkest_grey !important;
        max-width: 800px;
        margin: 0 auto;
      }
    }
    p{
      margin: 0;
    }
    .message{
      margin-top: 8px;
    }
    &.msg--white{
      .message{
        @extend .message--white;
      }
    }
  }

  // popup ------------
  &__newsletter-popup{
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;
    height: 100%;

    &::after{
      position: fixed;
      content: '';
      left: 0;
      top: 0;
      z-index: 199;
      width: 100%;
      height: 100%;
      background-color: #00000085; //whatever you want
      opacity: 0;
      transition: all 0.5s linear;
      @include transition(all, 0.5s, linear);
    }
    &.show{
      position: fixed;
      opacity: 1;
      padding: 30px 10px;
      z-index: 299;
      &::after{
        opacity: 1;
      }
      > .container{
        z-index: 299;
      }
    }
    .newsletter__close {
      position: absolute;
      right: -5px;
      top: -5px;
      background-color: #fff;
      @include border-radius(4px);
      display: flex;
      padding: 10px;
      @include box-shadow(1px ,1px , 10px, rgba(0,0,0,0.2));
    }
    & > .container {
      background-color: white;
      position: relative;
      max-width: 400px;
      padding: 15px;
      @include border-radius(4px);

      .img-fluid{
        @include border-radius(4px);
      }

      .form{
        width: 100%;

        .form__body{
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
    .newsletter__holder{
      overflow-x: scroll;
      max-height: 80vh;

      @include media-breakpoint-up(md) {
        overflow-x: initial;
        max-height: auto;
      }
    }

  }

  &__product-highlight{
    background-color: $artichoke-lightest;
    @extend .category__holder;
    > .container{
      > .row{
        justify-content: center;
      }
    }

  }
  &__related-products, &__product-highlight{
    @extend .x-scroll;
    .container{
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        //padding: 0;
      }
    }
    .highlight--grutto_choice{
      width:100%;
      margin-left: 0;
      margin-top: -4px;
      .package__title{
        a{
          &:before{
            width: 85px !important;
            height: 85px !important;
            right: -2px !important;
            top: -10px !important;
          }
        }
      }
    }

  }
  &__related-products{
    background-color: $vanilla_lightest;
    padding: 30px 0;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      padding: 50px 0;
    }
    &--title{
      text-align: center;
      padding: 5px 30px;
      margin-bottom: 10px;
      @include media-breakpoint-up(md) {
        margin-bottom: 30px;
      }
    }
  }

  // Recipe Module
  &__recipe-highlight, &__related-recipes{
    background-color: $vanilla-lightest;
    padding: 2rem 0;
    @extend .x-scroll;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      padding: 4rem 0;
    }
    .container{
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        padding: 0;
      }
    }
    .list__item{
      height: calc(100% - 12px);

      .list__image{
        @include media-breakpoint-down(sm) {
          height: 150px;
        }
      }
    }
    &--title{
      text-align: center;
      padding: 5px 30px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
      }
    }
    .see-all{
      display: flex;
      justify-content: center;
      margin-top: 15px;
      a{
        min-width: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-up(lg) {
          min-width: 300px;
        }
      }
    }
  }

  &__navigator{
    background-color: $gray_light;
    padding: 2rem 0;
    position: relative;
    @include media-breakpoint-up(lg) {
        padding: 4rem 0;
    }

    .desc{
        background-color: #fff;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        position: relative;
        z-index: 9;
        display: flex;
        align-items: stretch;
        flex-direction: column-reverse;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }

        .text{
            padding: 1.5rem;
            align-items: stretch;
            @include media-breakpoint-up(lg) {
                width: 50%;
                padding: 2.5rem;
            }
        }
        .pic{
            align-items: stretch;
            @include media-breakpoint-up(lg) {
                width: 50%;
            }
            img{
                width: 100%;
                height: 180px;
                object-fit: cover;
                @include media-breakpoint-up(md) {
                    height: 300px;
                }
                @include media-breakpoint-up(lg) {
                    height: 100%;
                    min-height: 300px;
                }
                @include media-breakpoint-up(xl) {
                  min-height: 400px;
                }
            }
        }
    }
    &::after{
        content: " ";
        display: block;
        width: 100%;
        height: 130px;
        background-color: $artichoke_dark;
        position: absolute;
        top: 0;
        z-index: 0;
        @include media-breakpoint-up(lg) {
            height: 170px;
        }
    }

    .navigator-menu{
      margin: 0;
      padding: 0;

      li{
          list-style: none;
          &:first-child{
              border-top: 1px solid $gray_light;
          }

          a{
              padding: 15px 20px 15px 0;
              display: block;
              font-family: $title_font;
              display: flex;
              align-items: center;
              justify-content: space-between;
              @include transition(all, 0.3s, ease-in);
              border-bottom: 1px solid $gray_light;

              &::after{
                  content: "\e923";
                  font-family: 'icomoon';
                  @include transform(rotate(270deg));
                  color: #808080;
              }

              &:hover{
                //border-bottom-color: #ddd;
                &::after{
                  color: $secondary_color;
                }
              }
          }
      }
    }
  }
  //FarmShop highlight
  &__farmshop-highlight{
    background-color: $vanilla-light;
    .mf-items{
      .row, [class*="col-"]{
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    .mf-body{
      text-align: center;
      @include media-breakpoint-up(md) {
        padding-bottom: 1rem;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
      .mf-btn, .mf-text, .hide-on-mobile{
        display: none;
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }
    .see-all{
      padding-top: 1.5rem;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
    .body-left, .body-right, .body-top, .body-bottom{
      padding: 2rem 0;
      @include media-breakpoint-up(lg) {
        padding: 4rem 0;
      }
      .mf-content{
        .body{
          p{
            margin-bottom: 0;
            @include media-breakpoint-up(lg) {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
    .body-left, .body-right{
      .mf-content{
        justify-content: space-between;
        align-items: center;  
      }
      .farmshop__item{
        background-color: $white;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        @include media-breakpoint-up(lg) {
          width: calc(100% - 20px);
        }
      }
      .h-scroll{
        @include media-breakpoint-down(sm) {
          max-width: 100% !important;
        }
        .mf-items{
          > .row{
            flex-wrap: nowrap;
            justify-content: flex-start !important;
            overflow-x: auto;
            padding-bottom: 0.6rem;

            .fs-item{
              @include media-breakpoint-down(md) {
                max-width: 190px;
                padding: 0 0.2rem !important;
              }
            }

            @include media-breakpoint-up(lg) {
              flex-wrap: wrap;
              padding-bottom: 0;
            }
          }
        }
      }
    }
    .body-top, .body-bottom{
      .x-scroll{
        display: flex;
        flex-direction: column;

        .mf-content{
          @include make-container();
          @include make-container-max-widths();
        }
      }
      .body{
        max-width: 800px;
        text-align: center;
        ul{
          list-style: inside;
          padding: 0;
        }
      }
      .farmshop__item{
        .img{
          img{
              height: 140px !important;
              @include media-breakpoint-up(md){
                  height: 160px !important;
              }
          }
        }
      }
      .fs-item{
        @include media-breakpoint-down(md) {
          max-width: 190px;
        }
      }
    }

    .body-right{
      .mf-content{
        flex-direction: row-reverse;
      }
    }
    .body-top{
      .cta{
        margin-top: 1rem;
      }
    }
    .body-bottom{
      .x-scroll{
        .container{
          order: 1;
        }
      }
      .mf-content{
        order: 2;
        margin-top: 1rem;
      }
      .cta{
        order: 3;
        margin-top: 1rem;
      }
    }
    .farmshop__item{
      &.highlight-christmas{
        .farmshop__title{
            padding-right: 1.5rem;
        }
        > a{
            &::before{
                display: flex;
                position: absolute;
                background-size: contain;
                width: 32px;
                height: 34px;
                right: 0.45rem;
                top: -4px;
                content: " ";
                background-image: url(../../../img/farmshop/highlight-christmas.svg) !important;
                background-repeat: no-repeat;
            }
        }
    }
    }
  }
  &__popular_farmshop{
    padding: 2.5rem 0;
    @include media-breakpoint-up(lg) {
      padding: 4rem 0;
    }
    .h-title{
      text-align: center;
      padding-bottom: 1.5rem;
      p{
        margin-bottom: 0;
      }
    }
    .see-all{
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }

  &__blog_highlight{
    .container{
      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
      .row{
        @include media-breakpoint-down(md) {
          padding-bottom: 0.6rem;
        }
      }
    }
    .blog-normal{
      background-color: $vanilla_lightest;
      padding: 2rem 0;
      @include media-breakpoint-up(lg) {
        padding: 4rem 0;
      }
    }
    .see-all{
      text-align: center;
      padding-right: 2rem;
      padding-left: 2rem;
      padding-top: 1rem;
      @include media-breakpoint-up(lg) {
        padding-top: 1.5rem;
      }
    }
    .blog__special-post{
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(md) {
          flex-direction: row;
      }
      .image{
          @include media-breakpoint-up(md) {
              width: 50%;
          }
          @include media-breakpoint-up(lg) {
              width: 60%;
          }
          img{
              height: 250px;
              width: 100%;
              object-fit: cover;
              @include media-breakpoint-up(sm) {
                  height: 350px;
              }
              @include media-breakpoint-up(md) {
                  height: 500px;
              }
          }
      }
      .text{
          @include media-breakpoint-up(md) {
              width: 50%;
          }
          @include media-breakpoint-up(lg) {
              width: 40%;
          }
      }
      .post-data{
          color: $grey;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 0.3rem;

          .sep{
              margin: 0 8px;
              color: $light_grey;
          }
      }
      .text{
          display: flex;
          align-items: center;
          padding: 2rem;
          @include media-breakpoint-up(md) {
              padding: 2rem 5rem;
          }
          .heading{
              font-family: $title_font;
              margin-bottom: 1rem;
              line-height: 1.2;
              a{
                color: $darkest_grey !important;
                &:hover{
                  color: $artichoke_dark !important;
                }
              }
          }
          .read-more{
              a{
                  text-decoration: underline;
              }
          }
      }
      &:nth-child(even){
          flex-direction: column;
          @include media-breakpoint-up(md) {
              flex-direction: row-reverse;
          }
      }
    }
  }

}


.newsletter{
  &__holder{
    align-items: center;
    @include media-breakpoint-up(lg) {
      margin-left: calc($grid-gutter-width * -2);
      margin-right: calc($grid-gutter-width * -2);
    }
    .body, .form{
      @include media-breakpoint-up(lg) {
        padding: 0 calc($grid-gutter-width * 2);
      }
    }

    .form{
      &__body{
        padding-bottom: 16px;
      }
      .mc-field-group{
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(md) {
          flex-direction: row;
        }
        .email{
          flex: 2;
          @include media-breakpoint-up(md) {
            margin-right: 4px;
          }
        }
        .btn{
          justify-content: center;
          margin-top: 10px;
          @include media-breakpoint-up(md) {
            margin-top: 0;
          }
        }
      }
    }
  }
  &__text-right, &__text-left{
    .body{
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }
    .form{
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }
  }
  &__text-right{
    .body{
      padding-top: 30px;
      @include media-breakpoint-up(lg) {
        padding-top: 0;
      }
    }
    .row{
      flex-direction: column-reverse;
      @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
      }
    }
  }
  &__text-left{
    .body{
      padding-bottom: 30px;
      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }
    }
  }

  &__text-top, &__text-bottom{
    text-align: center;
    .row{
      justify-content: center;
    }
    .body{ width: 100%;}
    .form{
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: 70%;
      }
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }
  }
  &__text-bottom{
    .row{
      flex-direction: column-reverse;
    }
    .body{
      padding-top: 30px;
    }
  }
  &__text-top{
    .body{
      padding-bottom: 1.5rem;
    }
    .form{
      padding-bottom: calc($grid-gutter-width * 2);
    }
  }
}

// Reviews HTML module
.reviews{
  padding-top:30px;
  padding-bottom: 30px;
  @extend .x-scroll;
  @include media-breakpoint-up(lg) {
    padding-top:90px;
    padding-bottom: 90px;
  }
  .container{
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
  &__item{
    border:1px solid $gray-light;
    margin:0 0 16px 0;
    padding:20px;
    height: calc(100% - 20px);
    background-color: #fff;
    @include box-shadow(1px, 1px, 10px, rgba(0,0,0,0.15));
    @include media-breakpoint-up(md) {
      margin:0 8px 20px 8px;
      @include box-shadow(0, 0, 0, rgba(0,0,0,0));
    }
  }
  &__title{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    max-width: 290px;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
      max-width: initial;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom:40px;
    }
    .rate{
      min-width: 110px;
      min-height: 110px;
      font-size: 1.8rem;
      margin-right: 20px;
      border:4px solid $darkest_grey;
      color: $darkest_grey;
      @include border-radius(100%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $title_font;
      @include media-breakpoint-up(md) {
        min-width: 160px;
        min-height: 160px;
        font-size: 3rem;
      }
    }
    .logo_box{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    p{
      color:#828282;
      font-size: 0.9rem;
      margin-bottom: 0;
      line-height: 1.4;
      @include media-breakpoint-up(md) {
        font-size: 1.1rem;
      }
    }
    .google_logo{
      width: 150px;
      height: 55px;
      background: url(../../../img/reviews/review-google.png);
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        width: 200px;
        height: 81px;
      }
    }
  }
  &__head{
    display: flex;
    padding-bottom: 10px;
    &:before{
      content: " ";
      width: 42px;
      height: 42px;
      background-image: url(../../../img/reviews/google-trusted-store.png);
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 10px;
    }
    .star{
      height: 18px;
      width: 100px;
      background: url(../../../img/reviews/stars.svg);
      display: block;
      background-repeat: no-repeat;
    }
    .name{
      font-family: $title_font;
      color:lighten($color: $black, $amount: 35%)
    }
  }

  // Trusted Shops for UK and DE - dynamic module -----
  &.uk, &.de{
    .reviews__head{
      &:before{
        background-image: url(../../../img/reviews/TrustedShops-rgb-Siegel_50Hpx.png);
      }
    }
  }
  .trustedshops_logo{
    min-width: 90px;
    min-height: 90px;
    background-image: url(../../../img/reviews/TrustedShops-rgb-Siegel_200Hpx.png);
    background-size: contain;
    background-repeat: no-repeat;
    @include media-breakpoint-up(md) {
      min-width: 140px;
      min-height: 140px;
    }
  }


  // Trusted Shops for the HTML module use --------------
  &.trustedshops{
    .reviews__head{
      &:before{
        background-image: url(../../../img/reviews/TrustedShops-rgb-Siegel_50Hpx.png);
      }
    }
  }
  .trustedshops{
    &__box{
      padding-left: 15px;
      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
    &__rate{
      font-family: $title_font;
      font-size: 1.6rem;
      @include media-breakpoint-up(md) {
        font-size: 2.5rem;
      }
      span{
        font-family: $title_font;
        font-weight: 100;
      }
    }
    &__star{
      height: 20px;
      width: 140px;
      background-image: url(../../../img/reviews/stars.svg);
      background-size: contain;
      background-position: left;
      display: block;
      background-repeat: no-repeat;
      @include media-breakpoint-up(md) {
        height: 32px;
        width: 160px;
      }
    }
    &__rate-name{
      font-size: 1.2rem;
      color:lighten($color: $black, $amount: 30%);
      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }
    }
  }
  .see-all{
    margin-top: 1rem;
  }
  .btn{
    border-color: $secondary !important;
    color: $secondary !important;
    background-color: transparent !important;

    &:hover{
        background-color: $secondary !important;
        color: #fff !important;
    }
  }
}


// List Item /-/ Post-list
.list{
    &__item{
        overflow: hidden;
        position: relative;
        margin: 0 calc($grid-gutter-width / 2) calc($grid-gutter-width * 4) calc($grid-gutter-width / 2);
        height:calc(100% - $grid-gutter-width *4);
        display: flex;
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .list__image {
          object-fit: cover;
          height: 210px;
          overflow: hidden;
          background-color: $gray-light;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include transition(all, 0.2s, ease-in);
          }
        }

        &:hover {
          img{
            @include transform(scale(1.1));
          }
        }

        .list__badges{
          bottom: 0;
        }
        .list__info {
          top: 0;
        }
        .list__info, .list__badges{
          padding: $grid-gutter-width;
          position: absolute;
          left: 0;

          .badge {
            background-color: #fff;
            padding:0 7px;
            display: inline-flex;
            margin-right: 6px;
            &__item {
              padding:7px;
              display: flex;
              align-items: center;

              [class^="icon-"] {
                margin-right:4px;
              }
            }
            &--bio{
              background-color: $green_color;
              color:#fff;
              font-family: $title_font;
            }
            &--premium{
              font-family: $title_font;
            }
            &--label-rouge{
              font-family: $title_font;
              background-color: $red_color;
              color:#fff;
            }
          }
        }

        .list__description {
          background: $artichoke_light;
          padding: calc($grid-gutter-width *2);
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-family: $title_font;
            color: $white;
            font-size: 1.375rem;
            line-height: 26px;
            a{
              color: $darkest_grey;
            }
          }

          .excerpt {
            margin: 0 0 1em 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            p{
              margin: 0;
            }
            &.text--white *{
              color: $white !important;
            }
          }

          .read-more {
            color: $artichoke_dark;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
              color: $darkest_grey;
            }
          }
        }
    }
    &__title{
        font-family: $title_font;
        color:$primary_color;
        font-size: 1.3rem;
        span{
            background-color: $caramel_color;
            padding: 8px;
            display: inline-flex;
        }
    }
    &--light{
        .list__title{
            span{
                background-color:#fff;
            }
        }
    }
}


.desc {
    &__info {
        padding: 20px;
        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            @include media-breakpoint-up(md) {
              display: flex;
              flex-wrap: wrap;
            }
            li {
                @include media-breakpoint-up(md) {
                  padding: 5px 0;
                }
                &.fld {
                  width: 100%;
                  display: flex;

                  @include media-breakpoint-up(md) {
                    width: 30%;
                  }
                }
                &.val {
                  width: 100%;
                  font-weight: bold;
                  display: flex;
                  flex-wrap: wrap;
                  margin-bottom: 1em;
                  padding-bottom: 1em;
                  border-bottom: 1px solid rgba(0,0,0, 0.1);
                  &:last-child{
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: 0;
                  }
                  @include media-breakpoint-up(md) {
                    width: 70%;
                    margin-bottom: 0;
                    border-bottom: 0;
                  }

                  span{
                    padding-right: 8px;
                    &:after{
                      content: ",";
                    }
                    &:last-child{
                      &:after{
                        content: none;
                      }
                    }
                  }
                }
            }
        }
        p {
            margin-bottom: 0;
        }
    }
}

.excerpt--full{
  padding-bottom: 30px;
  h2{
    @extend .heading--h3;
  }
  h3{
    @extend .heading--h4;
  }
  h4{
    @extend .heading--h5;
  }
}

.farmer__image, .recipe__image{
  img{
    width: 100%;
    @include border-radius($border-radius, $border-radius, 0, 0 );
  }
}

.bio-label img{
  float: right;
  margin-left: 12px;
  margin-bottom: 6px;
  margin-top: 3px;
}

// farmer detail page
.cover{
  &__farmer{
    position: relative;
    background-color:darken($color: $gray-base, $amount: 10);
    display: flex;
    align-items: flex-end;
    height: 250px;
    margin-bottom: 140px;
    @include media-breakpoint-up(md) {
      height: 400px;
      margin-bottom: 0;
    }
    .avatar{
      margin-right: 0;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        margin-bottom: -30px;
        margin-right: 30px;
      }
      img{
        width: 250px;
        height: 250px;
        object-fit: cover;
        @include border-radius(100%);
        border:3px solid #fff;
        @include media-breakpoint-down(md) {
          width: 200px;
          height: 200px;
        }
        @include media-breakpoint-down(sm) {
          width: 160px;
          height: 160px;
        }
      }
    }
    .title{
      width: 100%;
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column-reverse;
      }
      .heading{
        color:#fff;
        padding-right: 130px;
        font-size: 2rem;
        margin-bottom: 16px;
        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
        @include media-breakpoint-down(sm) {
          padding-right: 0;
          text-align: center;
          color:$darkest_grey;
          font-size: 1.4rem;
          margin-top: 10px;
        }
      }
    }
    .specs{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: row-reverse;
      margin-bottom: 10px;
      min-height: 70px;
      @include media-breakpoint-down(sm) {
        justify-content: center;
        align-items: center;
        min-height: auto;
      }

      .location{
        padding: 6px 15px;
        background-color: #fff;
        display: flex;
        align-items: center;
        margin-bottom: -47px;
        @include media-breakpoint-down(sm) {
          margin: 0 15px;
          background-color: $gray-light;
          min-height: 40px;
        }
        span{
          margin-right: 5px;
        }
      }
      .msc{
          .logo{
            display: block;
            height: 60px;
            width: 50px;
            background-image: url(../../../img/bio/certified-sustainable-seafood-by-msc.svg);
            background-size: contain;
            background-repeat: no-repeat;
            @include media-breakpoint-up(md) {
              height: 100px;
              width: 80px;
            }
          }
      }
      .label-rouge{
        .logo{
          display: block;
          height: 50px;
          width: 60px;
          background-image: url(../../../img/bio/label-rouge.svg);
          background-size: contain;
          background-repeat: no-repeat;
          @include media-breakpoint-up(md) {
            height: 75px;
            width: 90px;
          }
        }
      }
    }

    &.nl, &.de{
      .bio{
        .logo{
          display: block;
          height: 40px;
          width: 60px;
          background-color: #95c11f;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(../../../img/bio/organic-certification.svg);
          @include media-breakpoint-up(md) {
            height: 60px;
            width: 90px;
          }
        }
      }
    }
    &.uk{
      .bio{
        .logo{
          display: block;
          height: 50px;
          width: 50px;
          background-color: #fff;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(../../../img/bio/sa_organic_black.svg);
          border: 1px solid $gray-light;
          @include border-radius(3px);
          @include media-breakpoint-up(md) {
            height: 60px;
            width: 60px;
            border: 0;
            @include border-radius(0);
          }
        }
      }
    }
    &.fr{
      .bio{
        .logo{
          display: block;
          height: 50px;
          width: 50px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(../../../img/bio/agriculture-biologique.svg);
          @include media-breakpoint-up(md) {
            height: 70px;
            width: 70px;
          }
        }
      }
    }


    .container{
      position: relative;
      z-index: 1;
      margin-bottom: -150px;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      .body{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .farmer-user{
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;

          @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: flex-end;
            background-color: transparent;
          }
        }
      }
    }
  }
  &__bg{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    .gradient{
      height: 200px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      @include gradient(rgba(0,0,0,0), rgba(0,0,0,1));
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    img[src=""],
    img:not([src]){
      opacity: 0;
    }
    img[src="*"]{
      opacity: 1;
    }
  }
}

.farmer{
  &__body{
    margin-top: 80px;
    margin-bottom: 50px;
    column-count: 2;
    column-gap: 80px;
    text-align: justify;
    @include media-breakpoint-down(md) {
      column-count: 1;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
    .excerpt--full{
      ul, ol{
        -webkit-column-break-inside: avoid; /* Chrome, Safari */
        page-break-inside: avoid;           /* Theoretically FF 20+ */
        break-inside: avoid-column;         /* IE 11 */
        display:table;                      /* Actually FF 20+ */
      }
    }
    h3{
      @extend .heading--h3;
    }
    h4{
      @extend .heading--h4;
    }
    a{
      font-weight: bold;
    }
  }
}

// New Farmers
.new-farmers{
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $caramel_color;
  display: flex;
  &:last-child{
    border-bottom: 0;
  }
  .heading{
    margin-top: 5px;
  }
  &__img{
    padding-right: 20px;
    font-size: 3rem;
    color: darken($color:$gray_light, $amount:15);
  }
  &__title{
    border-bottom:1px solid $gray-light;
    margin-bottom: 30px;

    h3{
      margin:0;
      padding:10px 0;
      display: flex;
      align-items: center;
      font-family: $title_font;
      font-size: 1.8rem;

      .icon{
        width: 60px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  .breeds{
    span{
      padding-right: 8px;
      &:after{
        content: ",";
      }
      &:last-child{
        &:after{
          content: none;
        }
      }
    }
  }
}

// Gallery
.gallery {
    display: flex;
    overflow: hidden;
    img {
      width: 80%;
    }
}


// FAQ
.faq__item {
    background-color: $gray_light;
    margin-bottom: 10px;
    details[open] {
      @include transition(all, 0.2s, linear);
      summary:after {
        transform: rotate(0);
      }
    }

    details summary { list-style-type: none; } /* Firefox */
    details summary::-webkit-details-marker { display: none; } /* Chrome */
    details summary::marker { display: none; }

    summary, .faq__body{
      padding: calc($grid-gutter-width *2) calc($grid-gutter-width *3) ; //$grid-gutter-width = 8px
    }
    summary {
        position: relative;
        font-weight: bold;
        .question{
          display:block;
          width:calc(100% - 24px);
          margin:0;
          font-size: 0.9rem;
          font-family: $body_font;
        }
        &:focus {
            outline: 0;
        }
        &::-webkit-details-marker {
            display: none
        }
        &:after {
          height: 24px;
          width: 24px;
          font-family: "icomoon";
          content: "\e904";
          transform: rotate(180deg);
          @include transition(0.2s);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.8rem;
          position: absolute;
          right:calc($grid-gutter-width *3);
          top:50%;
          margin-top: -12px;
        }
    }
    .faq__body {
        padding-top:5px;
        @include media-breakpoint-down(md) {
          overflow-x:scroll;
          width: calc(100% - 24px);
          padding-right: 0;
          table{
            td{
              &:last-child{
                //white-space: nowrap;
              }
            }
          }
        }
    }
}
.faq-table{
  @extend .table;
  @extend .table-striped;
  @extend .table-bordered;
  width:100%;

  tbody tr:nth-of-type(even){
    background-color:rgba(255,255,255,0.6);
  }
  th{
    background-color:rgba(0,0,0,0.05);
  }
}

// Contact
.contact{
  &__box{
    border:1px solid $gray-light;
    a{
      color: $darkest_grey !important;
    }
  }
  &__item{
    padding: 20px;
    border-bottom: 1px solid $gray-light;
    @include media-breakpoint-up(lg) {
      padding: 30px;
    }
    [class^="icon-"] {
        color:$artichoke_dark;
        font-size: 1.6rem;
        min-width: 40px;
        @include media-breakpoint-up(lg) {
          min-width: 50px;
          font-size: 2rem;
        }
    }
    &:last-child{
      border-bottom: 0;
    }
  }
  &--lefticon{
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    color: $darkest_grey;
    &:hover{
      background-color: lighten($color: $gray-light, $amount: 5);
    }
  }
}




.align {
  &__left { float: left; }
  &__right { float: right; }
}
.text-align {
  &__left { text-align: left; }
  &__right { text-align: right; }
  &__center { text-align: center; }
}

.tooltip__hover {
  position: relative;
  &:hover {
    cursor: pointer;
  }

  &:hover &__hover {
    opacity: 1;
  }

  &__hover {
    position: absolute;
    top: 0;
    opacity: 0;
  }

  &__no-hover {
    opacity: 1;
  }
}


// 404 page
.page404{
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img{
    width: 280px;
  }
  @include media-breakpoint-up(lg) {
    img{
      width: auto;
    }
  }

  h2, p{
    color:$darkest_grey;
    text-align: center;
  }
  h2{
    font-size: 2.5rem;
  }
  p{
    font-size: 1.125rem;
  }
}

// newsletter --------------------
.newsletter{
  background-color: $vanilla_lightest;
  border:1px solid $vanilla_dark;
  border-top-width: 2px;
  @include border-radius(4px);
  padding: 20px;
  margin-top: 15px;
  @include media-breakpoint-up(md) {
    margin-top:0;
    &:before{
      content:"";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 10px solid $vanilla_dark;
      display: block;
      position: absolute;
      top:-10px;
      left: 50%;

    }
  }
  .heading{
    font-size:1.125rem;
    font-family: $title_font;
  }
  .mc-field-group{
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(xxl) {
      flex-direction: row;
    }
    .btn{
      justify-content: center;
    }
    .form-control{
      flex: 2;
      margin-bottom: 5px;
      @include media-breakpoint-up(xxl) {
        margin-bottom: 0;
        margin-right: 5px;
      }
    }
  }
  .message{
    margin-top: 10px;
  }
}

// message -----------------------
.message{
  border:1px solid;
  padding: 10px;
  @include border-radius(4px);
  display: flex;
  align-items: center;
  &:before{
    font-family: 'icomoon';
    font-size:1.5rem;
    margin-right: 10px;
  }
  &--success{
    border-color:$green_color;
    color:$green_color;
    &:before{
      content: "\e930";
    }
  }
  &--error{
    border-color:$red_color;
    color:$red_color;
    &:before{
      content: "\e92f";
    }
  }
  &--warning{
    border-color:$caramel_color;
    color:#666666;
    &:before{
      content: "\e90a";
      color:$caramel_color;
    }
  }
  &--white{
    border-color:#fff;
    color:#fff;
  }

  // Urgency Message on product page
  &__urgency{
    display: flex;
    align-items: center;
    animation-name: urgency;
    animation-duration: 1.5s;
    margin-top: 20px;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    .status{
      color:#fff;
      @include border-radius(3px 8px 3px 3px);
      padding: 4px 30px 4px 12px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      font-family: $title_font;
      position: relative;
      line-height: 1.3;
      &:before{
        font-family: 'icomoon';
        font-size:1.2rem;
        margin-right: 4px;
      }
      &:after{
        content: "";
        display: block;
        width: 0;
        height: 0;
        //border-top: 6px solid transparent;
        border-left: 8px solid;
        border-bottom: 10px solid transparent;
        position: absolute;
        right:-8px;
        top:15px;
      }
    }
    .description{
      border:1px solid;
      padding: $grid-gutter-width calc($grid-gutter-width * 3);
      @include border-radius(8px 3px 3px 3px);
      line-height: 1;
    }
    &.success{
      .status{
        background-color:#20BF6B;
        &:before{
          content: "\e930";
        }
        &:after{
          border-left-color:#20BF6B;
        }
      }
      .description{
        border-color:#20BF6B;
        color:#20BF6B;
      }
    }
    &.error{
      .status{
        background-color: #FA8231;
        &:before{
          content: "\e92f";
        }
        &:after{
          border-left-color:#FA8231;
        }
      }
      .description{
        border-color:#FA8231;
        color:#FA8231;
      }
    }
    &.warning{
      .status{
        background-color: #F7B731;
        &:before{
          content: "\e90a";
        }
        &:after{
          border-left-color:#F7B731;
        }
      }
      .description{
        border-color:#F7B731;
        color:#F7B731;
      }
    }
  }
}

@keyframes urgency {
  from { opacity: 0; height: 0; overflow: hidden;}
  to { opacity: 1; height: 50px; overflow:initial;}
}

.search-state{
  text-align: center;
  color:#666666;
  padding: 2rem 0 4rem 0;
  .icon{
    padding-bottom:$grid-gutter-width;
    i{
      font-size: 2rem;
    }
  }
  h4{
    font-family: $title_font;
    font-size: 1.25rem;
    margin: 0;
    line-height: 1;
  }
  &.warning{
    .icon{
      color:$caramel_color;
    }
  }
  &.error{
    .icon{
      color:$red_color;
    }
  }
}

// ------------- Recipes Page -------------
.recipe{
  &__info{
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    .fld{
      width: 40%;
      margin-bottom: 10px;
    }
    .val{
      width: 60%;
      margin-bottom: 10px;
    }
  }
  &__item, &__step{
    background-color: #fff;
    @include border-radius(4px);
    margin: 0 $grid-gutter-width calc($grid-gutter-width * 3) $grid-gutter-width;
    @include box-shadow(1px, 1px, 4px, rgba(0,0,0, 0.15));
    height: calc(100% - calc($grid-gutter-width * 3));
    figure{
      margin: 0;
      img{
        width: 100%;
        @include border-radius(4px 4px 0 0);
      }
    }
    .body{
      padding: 16px;
      h3{
        font-size: 1.375rem;
      }
    }
  }
}


// ------------- loading -------------
.loading-opacity{
  transition-delay: 500ms;
  filter: grayscale(1);
  opacity: 0.2;
}

// ------------- lazy loading -------------
.lazy{
  &__loading{
    position: relative;
    background-color: $gray-light;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $gray-light;
      background-image: url(../../../img/loading/loader.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px;
    }
  }
}

// ------------- Christmas Landing page -------------
.newsletter_box{
  padding: 0 16px;
  ul{
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 0 10px 0;
    li{
      padding: 8px 20px;
      display: flex;
      align-items: center;
    }
  }
  .form{
    display: flex;
    justify-content: center;
    max-width: 320px;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
      max-width: 420px;
    }
    .form-control{
      border:1px solid #fff;
      margin-right:12px;
      flex: 2;
    }
  }
  .msg_box{
    max-width: 320px;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
      max-width: 420px;
    }
    .message{
      margin: 10px auto;
    }
  }
}
.garantie-title{
  font-weight: 300;
  font-size: 5rem !important;
  line-height: 0.8 !important;
  @include transform(rotate(-15deg));
}

.product__item{
  background-color: #fff;
  @include border-radius(4px);
  margin: 0 8px 24px 5px;
  @include box-shadow(1px, 1px, 4px, rgba(0,0,0, 0.15));
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  figure{
    margin: 0;
    a{
      display: flex;
    }
    img{
      width: 100%;
      @include border-radius(4px,4px,0,0);
    }
  }
  > .body{
    padding: 16px;
    flex: 2;
    display: flex;
    flex-direction: column;
    > p{
      flex: 2;
    }
    h3{
      font-size: 1.375rem;
    }
  }
  .product__info{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.highlight--xmas{
    figure{
      a{
        position: relative;
        &::after{
          content: " ";
          position: absolute;
          left: -6px;
          bottom: 10px;
          background-repeat: no-repeat;
          background-size: contain;

          #root .nl & {
            background-image: url(../../../img/package/highlight_xmas_nl.svg);
            width: 110px;
            height: 40px;
          }
          #root .de & {
            background-image: url(../../../img/package/highlight_xmas_de.svg);
            width: 140px;
            height: 38px;
          }
        }
      }
    }
  }
}

.dish{
  .icon{
    display: block;
    font-size: 2.5rem;
    margin-bottom: 6px;
  }
  &__info{
    line-height: 1.2;
    font-size: 0.9rem;
    strong{
      display: block;
    }
    span{
      display: block;
    }
  }
}

// ribbon__below ribbon--bottom ribbon--red
.ribbon{
  &__below{
    position: relative;
    div, section{
      z-index: 10;
      position: relative;
    }
    &::after{
      position: absolute;
      width: 100%;
      height: 200px;
      z-index: 0;
      content:"";
      left: 0;
      @include media-breakpoint-up(md) {
        height: 245px;
      }
    }

    .btn{
      color: #fff !important;

      &:hover{
          color: #333333 !important;
      }
    }
    .recipe__item{
        .body{
            h3{
                a{
                    color: #333 !important;
                }
            }
        }
    }

  }
  &--lg{
    &::after{
      height: 250px;
      @include media-breakpoint-up(md) {
        height: 345px;
      }
    }
  }
  &--bottom{
    &::after{
      bottom: 0;
    }
  }
  &--top{
    &::after{
      top: 0;
    }
  }
  &--red, &--secondary{
    &::after{
      background-color: $secondary_color;
    }
  }
  &--blue, &--primary{
    &::after{
      background-color: $artichoke_dark;
    }
  }
  &--caramel{
    &::after{
      background-color: $caramel_color;
    }
  }
  &--xmas-green{
    &::after{
      background-color: #426935;
    }
  }
}

// anchor link offset
.anchor-box{
    height:65px;
    margin-top:-65px;
    z-index: -1;
    position: relative;
    @include media-breakpoint-up(md) {
      height:92px;
      margin-top:-92px;
    }
    @include media-breakpoint-up(lg) {
      height:auto;
      margin-top:0;
    }
}

// FarmShop link on product full -----
.farmshop{
  &_link{
    text-align: center;
    width: 100%;
    .btn{
      margin: 0 auto;
      width: 100%;
      justify-content: center;
      border-color: $artichoke_dark;
      &:hover{
        background-color: $artichoke_dark;
        color: #fff !important;
      }
    }
    &.nl{
      .package--cow &,
      .package--chicken &,
      .package--pig &,
      // .package--deer &,
      .package--salmon &,
      .package--sheep &
      {
        display: flex !important;
      }
    }
    &.de{
      .package--cow &,
      .package--chicken &,
      .package--pig &,
      .package--duck &,
      .package--sheep &
      {
        display: flex !important;
      }
    }
  }
}

// Rebranding Style
.how-we-work{
  background-color: #f7f3e8;
  h5{
      color: $secondary;
  }
}

// Parallax Row ---------------
.parallax {
  min-height: 400px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @include media-breakpoint-up(lg) {
    min-height: 350px;
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    background-attachment: scroll;
  }

  &__body{
      position: absolute;
      width:100%;
      height: 100%;
      background-color: rgba(0,0,0,0.2);

      display: flex !important;
      justify-content: center;
      align-items: center;

      .heading{
          display: flex;
          max-width: 80%;
          font-size: 1.5rem;
          color: #fff;
          line-height: 1;
          font-family: $title_font;
          @include media-breakpoint-up(md) {
              font-size: 2.5rem;
          }

          &:before{
              content: " “ ";
              margin-right: 1rem;
              color: $red;
              font-size: 7rem;
              height: 80px;
              line-height: 80px;
              font-family: sans-serif;
              letter-spacing: 0em;
              @include media-breakpoint-up(md) {
                  font-size: 10rem;
              }
          }
      }
      &.center-title{
        text-align: center;
        h3{
          &::before{
            content: none;
          }
        }
      }
  }
  &__heading{
    max-width: 970px;
    padding: 0 3rem;
  }
}