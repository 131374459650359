.product-add{
    display: inline-flex;
    background-color: #ECEAE7;
    padding: 5px;
    margin-bottom: 3px;
    min-width: 80px;
    justify-content: space-between;
    input{
        border:0;
        width: 35px;
        background-color: transparent;
        text-align: center;
    }
    span{
        padding:0 5px;
        font-weight: bold;
        cursor: pointer;
        [class^="icon-"] {
            font-size: 0.7rem;
        }
    }
}