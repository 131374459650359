.coupons{
    padding-bottom: 30px;
    padding-top: 30px;

    &__heading{
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px;
        h1{
            margin: 0;
        }
        p{
            margin: 0;
        }
        .img{
            margin-bottom: -20px;
            @include media-breakpoint-up(sm) {
                margin-bottom: -25px;
            }
            @include media-breakpoint-up(md) {
                margin-bottom: -30px;
            }
            img{
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
    }

    &__info{
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
        .info_item{
            text-align: center;
            margin:#{$grid-gutter-width * 2};
            .icon{
                display: flex;
                flex-direction: column;
                height: 50px;
                overflow: hidden;
                margin-bottom: #{$grid-gutter-width * 2};
                &[class*=" icon-"]{
                    font-size: 3rem;
                }
            }
        }
    }

    &__item{
        border:1px solid $artichoke-lightest;
        margin: 0 8px;
        margin-bottom: 20px;
        @include transition(all, 0.2s, linear);
        .cover{
            height: 110px;
            background-color: $artichoke-lightest;
            background-repeat: no-repeat;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url(../../../../img/coupons/coupons_cover.svg);
            background-size: cover; 
            @include media-breakpoint-up(xl) {
                height: 140px;
                background-position: top;  
            } 
            .text{
                line-height: 0.9;
                text-align: center;
                font-family: $title_font;
                h4{
                    margin:0;
                    font-weight: 100;
                    &.name{
                        text-transform: uppercase;
                        font-size: 2rem;
                        @include media-breakpoint-up(xl) {
                            font-size: 2.25rem;
                        } 
                    }
                }

            }
        }
        .body{
            padding: 20px;
        }
        &:hover{
            border: 1px solid $artichoke-regular;
        }  
    }


    &__price{
        display: flex;
        justify-content: space-between;
        .price{
            .holder{
                display: flex;
                font-size: 2.2rem;
                font-family: $title_font;
                line-height: 1;
                @include media-breakpoint-up(md) {
                    font-size: 2.4rem;
                } 
                h4{
                    margin:0;
                    font-weight: 100;
                    sup{
                        top: -0.5rem;
                    }
                }
            }
        }
    }
    &__type{
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        flex-direction: column;
        flex-wrap: wrap;
        .title{
            width: 100%;
        }
        .item{
            width: 100%;
            border:1px solid $gray-light;
            @include transition(all, 0.2s, linear);
            line-height: 1.2;
            padding: 10px;
            padding-left: 40px;
            @include border-radius(4px);
            position: relative;
            cursor: pointer;
            margin-bottom: 10px;
            &:hover{
                border: 1px solid darken($color: $gray_light, $amount:15);
            }
            &:before, &:after{
                content:"";
                display: block;
                @include border-radius(100%);
                position: absolute;
                left: 10px;
                top:50%;
                margin-top: -10px;
            }
            &:before{
                width: 20px;
                height: 20px;
                border:3px solid $gray-light;
            }
            &.selected{
                border-color:$secondary_color;
                &:before{
                    background-color: $secondary_color;
                }
            }
            h4{
              margin: 0;
            }
            div{
                opacity: 0.5;
            }
        }
    }
}