.row {
  &.th {
    font-weight: bold;
    margin-bottom: calc($grid-gutter-width / 2);
    margin-right: $grid-gutter-width;
    margin-left: $grid-gutter-width;
  }
  &.tbody {

    .row {
      width: 100%;
      @include transition(all, 0.3s, ease-out);
      margin-right: calc($grid-gutter-width / 2);
      margin-left: calc($grid-gutter-width / 2);
      &:hover{
        background-color:lighten($color: $gray_light, $amount: 4);
      }
    }
  }
  &.border {
    border-left: 1px solid $gray-base;
    border-right: 1px solid $gray-base;
    border-top: 1px solid $gray-base;
    padding: $grid-gutter-width;
    &:first-of-type {
      border-top: 1px solid $gray-base;
      padding-top: 8px;
    }
    &:last-of-type, &:last-child {
      border-bottom: 1px solid $gray-base;
    }
  }
  &.package__quantity{
    .show-alert & {
      border-left-color: $red;
    }
  }
  &.package__row{
    .pieces{
      @media (max-width: 350px) {
        color: lighten($color: $black, $amount: 60);
      }
    }
  }
  &.package__row, &.package__quantity{
    @media (max-width: 350px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
    .meatcuts{
      @media (max-width: 350px) {
        order: 1;
        max-width: 100%;
        line-height: 1;
      }
    }
    .pieces{
      @media (max-width: 350px) {
        order: 3;
        max-width: 100%;
      }
    }
    .meals{
      @media (max-width: 350px) {
        order: 2;
        grid-column: 2;
        grid-row: 1 / 3;
        max-width: 100%;
      }
    }
  }
  .th{
    @media (max-width: 350px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
  }
  .th-pieces{
    @media (max-width: 350px) {
      display: none;
    }
  }
  &.package__row{
    border-top: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    @media (max-width: 350px) {
      padding-top: 8px;
      border-top: 1px dashed lighten($color: $black, $amount: 80);
    }
  }
  .description {
    display: block;
  }
  .package__meals{
    display: flex;
    line-height: 1;
    align-items: center;
    .count{
      padding-right: 5px;
      white-space: nowrap;
    }
    .plates{
      img{
        width: 25px;
        padding: 0 1px;
      }
    }
  }

  &.accordion{
    border-top: 1px solid #ddd;
    @include media-breakpoint-up(lg) {
      border-top: 0;
    }
  }

  .package{
    &__content{
      display: flex;
      flex-direction: column;
    }
  }
}

#productContent{
  .package__content{
    @include media-breakpoint-down(md) {
      max-height: 0;
      overflow: hidden;
      @include transition(all, 0.4s, ease);
    }
  }
  &.open{
    .package__content{
      max-height: 2000px;
    }
  }
}


.productContent{
  &__holder{
    margin-top: 1rem;
    padding-left: 4px;
    padding-right: 4px;
    .heading{
      width: 100%;
      padding: 1rem 0;
      margin: 0;
      color: $primary-color;
      @extend .heading--h3;
      border-top: 1px solid #ddd;
      @include media-breakpoint-up(lg) {
        border-top: 0px;
        font-size: 1.625rem;
      }
    }

    .item{
      border: 1px solid $artichoke_light;
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      width: 100%;
      border-top:2px solid $primary;
      @include media-breakpoint-up(lg) {
        border-top: 1px solid $artichoke_light;
        margin-top: 0;
      }
      &.show-alert{
        margin-bottom: 0;
        border-left-color: $red;
      }
      .item-body{
        max-height: 0;
        overflow: hidden;
        @include transition(all, 0.5s, ease-in);
        &::before{
          content: " ";
          width: 100%;
          background-color: $artichoke_light;
          height: 1px;
          display: block;
        }
        .th-meatcuts, .th-pieces, .th-meals{
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
        }
        .row.th{
          color: $dark_grey;
          font-size: 0.9rem;
          margin-bottom: 0;
          font-weight: 100;
        }
        .meatcuts{
          line-height: 1;
        }
        .meatcuts, .pieces, .meals{
          display: flex;
          align-items: center;
        }
        .product-add{
          margin-bottom: 0 !important;
        }
      }
      &.step3{
        .title{
          border-bottom: 0;
          cursor: initial !important;
          .text{
            &::after{
              content: none;
            }
          }
        }
      }
      .title{
        font-size: 1rem;
        font-family: $title_font;
        cursor: pointer;
        @include media-breakpoint-up(lg) {
          display: flex;
        }
        .text{
          padding: 0.9rem;
          line-height: 1.2;
          position: relative;
          padding-right: 2rem;
          @include media-breakpoint-up(lg) {
            flex: 2;
          }
          &::after{
            content: "\e923";
            display: block;
            font-size: 1.1rem;
            font-family: "icomoon";
            color: $red-color;
            position: absolute;
            top:0;
            right: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            @include transition(all, 0.2s, ease-in);
          }
        }
      }
      .row.tbody{
        margin-bottom: 0;
      }
      .row.border{
        border-left: 0;
        border-right: 0;
      }
      .row.border:first-of-type, .border.carousel__holder.slider-text-right:first-of-type, .border.carousel__holder.slider-text-left:first-of-type{
        border-top-color: $artichoke_light;
      }
      .row.border, .border.carousel__holder.slider-text-right, .border.carousel__holder.slider-text-left{
        border-top-color: $artichoke_light;
      }
      .row.border:last-of-type, .border.carousel__holder.slider-text-right:last-of-type, .border.carousel__holder.slider-text-left:last-of-type, .row.border:last-child, .border.carousel__holder.slider-text-right:last-child, .border.carousel__holder.slider-text-left:last-child{
        border-bottom: 0 !important;
      }

      .number{
        margin-top: -25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        @include media-breakpoint-up(lg) {
            margin-left: 10px;
            margin-top: 0;
            flex-direction: column;
        }

        &::after, &::before{
            display: block;
            height: 2px;
            background-color: $vanilla_dark;
            flex: 2;
            @include media-breakpoint-up(lg) {
                width: 2px;
                content: " ";
            }
        }

        span{
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $vanilla_dark;
            width: 50px;
            height: 50px;
            @include border-radius(50px);
            font-family: $title_font;
            font-size: 1.2rem;
            color: $darkest_grey;
            background-color: #fff;
            @include media-breakpoint-up(lg) {
              width: 40px;
              height: 40px;
            }
        }
      }

      &.item-open{
        .title{
          .text{
            &::after{
              transform: rotate(180deg);
            }
          }
        }
        .item-body{
          max-height: 1000px;
        }
      }
    }

    .tfoot{
      width: 100%;
    }

    .steps__link{
      margin-bottom: 0.8rem;
      width: 100%;
    }
  }
}

// Table Style for Static page
.content-table{
  box-sizing: border-box;
  th, td{
    padding: .75rem;
    border: 1px solid #bbbbbb;

    .heading{
      font-family: $title_font;
    }
  }
  td{
    box-sizing: border-box;
    vertical-align: middle !important;
  }
  .fix-row{
    background-color: #fff;
    box-shadow: 0px 1px 0px #ddd;
    // position: sticky;
    // top: 0;
  }
  tbody{
    tr{
      &:nth-child(odd){
        background-color: #f5f5f5;
      }
    }
  }
  // responsive
  &__holder{
    overflow-x: scroll;
    @include media-breakpoint-up(lg) {
      overflow: initial;
    }
  }
}