// Global ---------
.color--xmas-green{
    color:#426935;
}
.bg--xmas-green{
    background-color:#426935;
}
.btn-xmas-green, .btn--xmas-green{
    background-color:#426935 !important;
    border-color:#426935 !important;
    &:hover {
        color:#fff;
        background-color: darken(#426935, 10%) !important;
    }
}


// NewsLetter Row --------------
.xmas-newsletter{
    overflow: hidden;
}
.xmas-newsletter-pic{
    width: 85%;
    display: block;
    @include media-breakpoint-up(md) {
        width:100%;
    }
}
.xmas-newsletter-form{
    max-width:100%;
    padding: 20px;
    @include media-breakpoint-up(md) {
        max-width:540px;
    }
}
.xmas-newsletter-form .xmas-form{
    ul{
        padding:0;
        margin:0 0 20px 0;
        list-style:none;
        @include media-breakpoint-up(sm) {
            display:flex;
            flex-wrap: wrap;
        }
        li{
            padding: 10px 0;
            @include media-breakpoint-up(sm) {
                width:50%;
            }
        }
    }
    .form{
        display: flex;
        flex-wrap: wrap;
        .form-control, .btn{
            @include media-breakpoint-only(xs) {
                margin-top: 10px;
            }
        }
        .form-control{
            @include media-breakpoint-only(xs) {
                flex: 2;
            }
            @include media-breakpoint-up(md) {
                flex: 2;
            }

            &:focus {
                outline: 0;
                border-color: #426935;
            }
        }
    }

    .response{
        @extend .message;
        margin-top: 10px;
        &#mce-error-response{
            @extend .message--error;
        }
        &#mce-success-response{
            @extend .message--success;
        }
    }
}
.xmas-newsletter-form .xmas-form ul label{
    margin:0;
    color:#426935;
    cursor: pointer;
}

// Farmer Row -----------------
.farmer-row{
    position: relative;
    @include media-breakpoint-up(sm) {
        padding-top: 50px;
    }
    &--info{
        align-items: flex-end;
        @include media-breakpoint-up(md) {
            align-items: initial;
        }
    }
    .container{
        @include media-breakpoint-down(sm) {
            max-width: 90%;
        }
    }
    .farmer-text-col{
        color: #fff;
        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .farmer-text{
            max-width: 300px;
            padding: 20px 0;
            @include text-shadow(1px, 1px, 5px, #426935);
            @include media-breakpoint-up(lg) {
                max-width: 400px;
            }

            .heading{
                font-family: $title_font;
                font-size: 0.9rem;
                margin-bottom: 30px;
                line-height: 1.4;
                @include media-breakpoint-up(lg) {
                    font-size: 1.2rem;
                }
            }
        }

        .name{
            line-height: 0.8;
            .golden--title{
                font-size: 2rem;
            }
            p{
                margin: 0;
            }
        }
    }
    .bg{
        position: absolute;
        height: 100%;
        @include media-breakpoint-up(sm) {
            height: calc(100% - 50px);
        }
        width: 100%;
        background-color: #426935;
        .farmer-bg{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .farmer-pic{
        margin-top: -50px;
    }
}

// xmas items design "xmas--product-items"
.xmas{
    &--product-items{
        .package__item{
            display: flex;
            flex-direction: column;
            .package__title{
                order: 1;
            }
            .package__slider{
                order: 2;
            }
            // .percentage__box-holder{
            //     display: none;
            // }
            .package__item__farmer{
                order: 3;
            }
            .package__info{
                display: none;
            }
            .short-text{
                order: 4;
                display: flex !important;
                min-height: 115px;
                @include media-breakpoint-up(sm) {
                    min-height: 95px;
                }
            }
            .product-info{
                order: 5;
                .buyers-1{
                    display: none;
                }
                .meals-per-package{
                    display: flex !important;
                    padding-left: 1rem;
                }
            }
            .package__action{
                order: 6;
            }
            .package-per-person{
                .description{
                    display: none;
                }
            }
        }
    }
}
.highlight--xmas-gift{
    .package__item{
        .package__slider{
            a.link{
                &::after{
                    content: " ";
                    position: absolute;
                    left: -6px;
                    top: 10px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    z-index: 10;

                    #root .nl & {
                        background-image: url(../../../../img/package/highlight_xmas_nl.svg);
                        width: 110px;
                        height: 40px;
                    }
                    #root .de & {
                        background-image: url(../../../../img/package/highlight_xmas_de.svg);
                        width: 140px;
                        height: 38px;
                    }
                }
            }
        }
    }
}