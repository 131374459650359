// Package
.list--packages {
  margin-top: #{$grid-gutter-width *5};
  margin-bottom: 100px;
}
.package {
  &__item {
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin-bottom:50px;
    @include box-shadow(1px, 1px, 10px, rgba(0,0,0,0.3));
    @include media-breakpoint-up(lg){
      margin-bottom:30px;
      @include box-shadow(0, 0, 0, transparent);
    }
    @include media-breakpoint-up(sm){
      margin-left:$grid-gutter-width;
      margin-right:$grid-gutter-width;
    }
    .percentage__box-holder{
        background-color: $artichoke-light;
        padding: 0.6rem 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .percentage__box{
      .img__gray{
        img{
            filter: brightness(2);
        }
      }
      .img__fill{
          z-index: 1;
      }
      @include media-breakpoint-down(sm){
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &.package--wine{
      .img__gray{
        img{
            filter: brightness(1.2);
        }
      }
    }
    .product-info{
      padding: 0 0.6rem 0.6rem 0.6rem;
      display: flex;
      align-items: center;
      .item{
          width: auto !important;
          padding: 0;
          &.full-price{
              padding: 0 1rem 0 2px;
          }
      }
      .item__farmer{
          display: none;
      }
      .package-per-person, .buyer{
          display: flex;
          align-items: center;
          min-width: auto !important;
          .tooltip__info{
              width: initial !important;
              .avatar{
                  margin: 0;
                  width: 30px;
                  height: 30px;
                  .icon-user{
                      font-size: 1.3rem !important;
                      color: $artichoke-dark;
                      background-color: $artichoke-lightest;
                  }
              }
          }
          .tooltip__title{
              text-align: left !important;
              .title{
                  height: auto !important;
                  padding-left: 6px;
                  line-height: 1;
              }
          }
          .icon-info{
              display: none;
          }
          .description{
              white-space: nowrap;
              padding-left: 6px;
              line-height: 1;
              sup{
                  top: -0.3em;
              }
          }
          .amount{
              height: auto !important;
              margin: 0 auto;
              .pack-price{
                  margin: 4px 0 0 0;
              }
          }
          .text--green{
              color: $artichoke-dark !important;
          }
      }
      .meals-per-package{
        display: flex;
        align-items: center;
        .icon{
          font-size: 2rem;
        }
        .dish__info{
          padding-left: 6px;
          font-size: 0.8rem;
          line-height: 1;
          span{
            font-size: 0.7rem;
          }
        }
      }
    }
    .short-text{
      padding: 1rem 0.6rem;
      font-size: 0.85rem;
    }
    .package__title{
      z-index: 2;
      a{
        justify-content: flex-start;
        padding: 0.6rem;
        min-height: 60px !important;
        @include media-breakpoint-up(md) {
            min-height: 70px !important;
        }
      }
      .heading{
        font-family: $title_font;
        font-size: 0.9rem;
        line-height: 1.2;
        @include media-breakpoint-up(sm) {
          font-size: 1.1rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 1.2rem;
        }
        @include media-breakpoint-up(xl) {
          font-size: 1.3rem;
        }
      }
    }
    .package__info{
      padding: 1rem 0.6rem;
      margin-top: 0 !important;
      font-size: 0.8rem;
      @include media-breakpoint-up(md) {
          font-size: 0.85rem !important;
      }
    }
    .package {
      &__content {
        padding: #{$grid-gutter-width *2};
        &__title {
          margin-top: 30px;
          @include media-breakpoint-up(md) {
            margin-top: 50px;
          }
        }
        &__action {
          margin-top: 30px;
          @include media-breakpoint-up(md) {
            margin-top: 0;
          }
        }
      }
    }
    .package__slider{
      position: relative;
      .link{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
    .full-price, .meal-price{
      .amount{
        h4{
          font-size: 1.5rem;
          letter-spacing: -1px;
        }
      }
    }
    .full-price{
      .package-per-person{
        min-width: 90px;
      }
      .description{
        letter-spacing: -0.5px;
        font-size: 0.7rem;
        @include media-breakpoint-up(md) {
          font-size: 0.8rem;
        }
      }
    }
    .gallery {
      margin-top: 0;
      img {
        object-fit: cover;
        width: 100%;
      }
    }
    .percentage__box{
      @include media-breakpoint-up(md) {
        min-height: 130px;
      }
    }
    &:hover{
      .carousel__bottom, .carousel__nav{
        opacity: 1; 
      }
    }

    // ear-tag
    &.grutto_combi_cow_pig_chicken{
      .animal-tag{
        top:10px;
        right:25% !important;
        @include media-breakpoint-up(sm) {
          
        }
        @include media-breakpoint-up(lg) {
          right:29% !important;
          top:15px;
        }
        @include media-breakpoint-up(xl) {
          right:25% !important;
          top:12px;
        }
      }
    }

    .package__item__farmer{
      display: flex !important;
      margin-top: -20px;
      padding: 0 0.6rem;
      .avatar{
          margin: 0 !important;
          &:before{
              content: none !important;
          }
          img{
              border-color: #fff !important;
              cursor: initial !important;
          }
      }
      .item__farmer{
          width: 100%;
          z-index: 20;
      }
      .farmer{
          display: flex;
      }
      .tooltip__content{display: none !important;}
      .tooltip .tooltip__info{
          width: initial !important;
      }
      .tooltip__title{
        cursor: initial !important;
        width: calc(100% - 40px);
        justify-content: flex-start;
        padding-left: 0.5rem;
        padding-bottom: 2px;
        align-items: flex-end;
        .title{
            height: auto !important;
            white-space: nowrap;
            text-align: left;
            width: 100%;
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            display: block !important;
            .gray{
                color:$darkest-grey !important;
            }
        }
      }
    }
    // ‌bio
    &.nl, &.de{
      &.package--bio{
        .package__title{
          > a{
            &::after{
              content: " ";
              width: 36px;
              height: 24px;
              background-image: url(../../../../img/bio/organic.svg);
              background-size: 28px;
              background-position: center;
              background-repeat: no-repeat;
              background-color: #a2bd59;
              position: absolute;
              top: 50px;
              right: 1.3rem;
              @include border-radius(1px);

              @include media-breakpoint-up(md) {
                top: 60px;
              }
            }
          }
        }
      }
    }

    &.fr{
      &.package--bio{
        .package__slider{
          .item{
            &:after{
              content: "";
              background: url(../../../../img/bio/agriculture-biologique.svg);
              background-size: contain;
              background-position: center center;
              background-repeat: no-repeat;
              position: absolute;
              left: 10px;
              top: 10px;   
              z-index: 10;
              width: 42px;
              height: 50px;
              @include media-breakpoint-up(md) {
                width: 52px;
                height: 60px;
              }
            }
          }
        }
      }
      .buyers-1{ display: none;}
      .packages-remaining{ display: block !important;}
    }

    &.uk{
      &.package--bio{
        .package__slider{
          .item{
            &:after{
              content: "";
              background: url(../../../../img/bio/sa_organic_black.svg);
              background-size: contain;
              background-position: center center;
              background-repeat: no-repeat;
              position: absolute;
              left: 10px;
              top: 10px;   
              z-index: 10;
              width: 50px;
              height: 50px;
              @include media-breakpoint-up(md) {
                width: 60px;
                height: 60px;
              }
            }
          }
        }
      }

    }
  }
  &--special{
    .bio{
      color:$darkest_grey !important;
    }
  }
  &__info{
    line-height: 1.3;
    margin-top: 8px;
    @include media-breakpoint-up(md) {
      margin-top: 12px;
    }
  }
  &__title{
    a{
      display: flex;
      justify-content: center;
      padding: 0 5px;
      @include media-breakpoint-up(sm) {
        min-height: 50px;
        align-items: center;
      }
      &:hover{
        color:$primary_color;
      }
    }
    .heading{
      color:$darkest_grey;
      margin-bottom: 0;
    }
  }
  &__action{
    padding: 0.6rem;
    padding-top: 0;
    width: 100%;
    .btn{
      white-space: nowrap;
      font-size: 0.9rem;
      @include media-breakpoint-up(md) {
          font-size: 1rem;
      }
    }
    .btn--dark{
      background-color: $artichoke_regular !important;
      border-color: $artichoke_regular;
      color: #fff;

      &:hover{
          background-color: $artichoke_dark !important;
          color: #fff;
      }
    }
  }
  &__full {
    &--info{
      .package__title{
        margin-top: 1.5rem;
        @include media-breakpoint-up(md) {
          margin-top: 2rem;
        }
      }
    }
    .package {
      &__price{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include media-breakpoint-up(sm) {
          flex-wrap: nowrap;
          padding-bottom: 12px;
        }
        .p-prev{
          color:lighten($color: $black, $amount: 50%);
          position: relative;
          margin-right: 8px;
          @include media-breakpoint-up(sm) {
            margin-right: 16px;
          }
          &:after{
            content: "";
            height: 2px;
            width: 100%;
            background-color: lighten($color: $black, $amount: 50%);
            position: absolute;
            top: 45%;
            left: 0;   
            @include transform(rotate(-12deg));
          }
          .amount{
            font-size: 1.5rem;
            font-weight: 100;
            sup {
              font-size: 0.8rem;
              top: -7px;
              left: 0;
            }
          }
        }
      }
      &__spec{
        display: flex;
        margin-bottom: 30px;
        justify-content: space-around;
        text-align: center;
        .item{
          flex: 2;
          padding: 0 2px;
        }
      }
      &__order {
        justify-content: center;
        .amount {
          font-size: 2.125rem;
          font-family: $title_font;
          margin: 0;
          sup {
            font-size: 1.125rem;
            top: -6px;
            left: 0px;
            @include media-breakpoint-up(sm) {
              top: -10px;
              left: -2px;
            }
          }
        }
        .package__meal{
          color: $dark_grey;
          font-size: 0.8rem;
          font-weight: $font-weight-normal;
          width: 100%;
          @include media-breakpoint-up(sm) {
            margin-left: 16px;
            width: initial;
            font-size: 1rem;
          }
        }
        @include media-breakpoint-down(md) {
          background-color: #fff;
          position: fixed;
          z-index: 999;
          bottom:0;
          left: 0;
          width: 100%;
          margin:0;
          border-top:1px solid $gray_light;
          padding: 10px;

          .p-prev .amount{
            font-size: 1.2rem;
            @include media-breakpoint-up(sm) {
              font-size: 1.3rem;
            }
          }
          .p-main .amount{
              line-height: 1;
              font-size: 1.7rem;
              @include media-breakpoint-up(sm) {
                font-size: 1.9rem;
              }
          }

          .btn{
            text-align: center;
            line-height: 1.2;
          }
        }
      }
      &__usp{
        padding: 0;
        margin-top: 1.2rem;
        margin-bottom: 0.6rem;
        list-style: none;
        font-size: 0.9rem;
        li{
          display: flex;
          align-items: center;
          padding: 0.3rem 0;
          line-height: 1;  
          [class^=icon-]{
            color: $artichoke_dark;
            margin-right: 0.4rem;
            font-size: 1.25rem;
            min-width: 22px;
            text-align: center;
          }
        }
      }
    }
    .alert {
      &__total-vacuums {
        display: none;
        padding: 0.5rem 1rem;
        font-size: 0.85rem;
        margin-top: -1px;
        i {
          font-size: 1.4rem;
          margin-right: 0.5rem;
        }
        &.active {
          display: inline-flex;
          align-items: center;
        }
      }
      &__box{
        position: sticky;
        bottom: 98px;
        @media (min-width: 445px) {
          bottom: 76px;
        }
        @include media-breakpoint-up(lg){
          position: initial;
          bottom: initial;
        }
      }
    }
    .percentage__box{
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      max-width: 288px;
      @include media-breakpoint-up(sm){
        max-width: 430px;
      }
      @include media-breakpoint-up(lg){
        max-width: 100%;
      }
    }
    // Easter Package
    &.grutto_farmer_package{
      .percentage__box{
        .animal-tag{
          display: none;  
        }
      }
      .package__spec{
        .profile-breed{
          display: none;
        }
      }
      .tooltip__content{
        .farmers--breed, .farmers--ear-number{
          display: none;
        }
      }
    }

    &.nl, &.fr{   
      .traceability-enable{
        display: flex !important;
      }
    }
  }
  &__slider{
    .carousel{
      height: 190px;
      @include media-breakpoint-up(sm){
        height: 190px;
      }
      @include media-breakpoint-up(md){
        height: 210px;
      }
      @include media-breakpoint-up(lg){
        height: 180px;
      }
      @include media-breakpoint-up(xl){
        height: 200px;
      }
      @include media-breakpoint-up(xxl){
        height: 210px;
      }
      overflow: hidden;
      &__content{
        display: none;
      }
      &__nav{
        padding: 10px;
        @include media-breakpoint-up(lg){
          opacity: 0;
        }
        li{
          margin: 0 2px;
          width: 12px;
          height: 12px;
        }
      }
      &__bottom{
        height: 0;
        bottom:50%;
        margin-bottom: 15px;
        justify-content: space-between;
        padding: 0 10px;
        opacity: 0;
        @include transition(all, 0.3s, ease-in);
        @include media-breakpoint-down(md){
          opacity: 1;
        }
        a{
          padding: 0;
          width: 30px;
          height: 30px;
          background-color: transparent;
          span{
            font-weight: bold;
            opacity: 0.3;
            background-color: #fff;
            font-size: 0.8rem;
          }
          &.active{
            span{
              color:$darkest_grey;
              opacity: 1;
              @include box-shadow(0, 0, 4px, rgba(0,0,0,0.1));
            }
          }
        }
      }
      &__nav{
        margin: 0;
      }
    }
  }

  &--msc{
    .package__slider{
      .item{
        &:after{
          content: "";
          background: url(../../../../img/bio/certified-sustainable-seafood-by-msc.svg);
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          background-color:none;
          position: absolute;
          left: 15px;
          top: 15px;
          z-index: 10;
          width: 40px;
          height: 60px;
        }
      }
    }
  }
  &--label-rouge{
    .package__slider{
      .item{
        &:after{
          content: "";
          background: url(../../../../img/bio/label-rouge.svg);
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          left: 15px;
          top: 15px;
          z-index: 10;
          width: 55px;
          height: 45px;
        }
      }
    }
  }

}
.all-pack{
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  @include media-breakpoint-up(md) {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  a, a:link{
    display:inline-flex;
    align-items: center;
    color:$grey;
    transition: all .2s ease-in;
    padding: 10px;
    &:hover{
      color: $primary_color;
    }
    [class^="icon-"]{
      margin-right: 0.4em;
    }
  }
}
.package{  
  &--label-rouge{
    .package__title{
      a{
        padding: 0 45px;
        &:before{
          content: "";
          width: 90px;
          height: 90px;
          display: block;
          position: absolute;
          right: -4px;
          top: -8px;
          background-repeat: no-repeat;
          background-image: url(../../../../img/package/highlight_label-rouge.svg);
          background-size: contain;
          @include media-breakpoint-up(sm) {
            right: 4px;
          }
          @include media-breakpoint-up(md) {
            width: 100px;
            height: 100px;
            
          }
        }
      }
    }
  }
}

// Product item highlight
.highlight{
  &--grutto_choice{
    @include media-breakpoint-up(md) {
      border:2px solid $artichoke_regular;
      width: calc(100% + 10px);
      margin-left: -5px;
      margin-top: -8px;
    }

    .package__title{
      a{
        padding-right:60px;
        &:before{
          content: "";
          z-index: 5;
          width: 90px !important;
          height: 90px !important;
          display: block;
          position: absolute;
          right: -4px !important;
          top: -7px !important;
          background-repeat: no-repeat;
          background-size: contain;
          @include media-breakpoint-up(sm) {
            right: 3px !important;
          }
          @include media-breakpoint-up(md) {
            width: 100px !important;
            height: 100px !important;
            right: -8px !important;
            top: -15px !important;
          }
        }
      }
    }
    &.de{
      .package__title a:before{
          background-image: url(../../../../img/package/highlight_text_de.svg);
      }
    }
    &.nl{
        .package__title a:before{
            background-image: url(../../../../img/package/highlight_text_nl.svg);
        }
    }
    &.fr{
        .package__title a:before{
            background-image: url(../../../../img/package/highlight_text_fr.svg);
        }
    }
    &.uk{
        .package__title a:before{
            background-image: url(../../../../img/package/highlight_text_uk.svg);
        }
    }
  }

  &--almost_soldout{
    @include media-breakpoint-up(md) {
      @include border-radius(0.35rem);
      border:2px solid $red_color; 
    }

    .package__title{
      a{
        padding-right:60px;
        &:before{
          content: "";
          width: 90px !important;
          height: 90px !important;
          display: block;
          position: absolute;
          right: -4px !important;
          top: -7px !important;
          background-repeat: no-repeat;
          background-size: contain;
          @include media-breakpoint-up(sm) {
            right: 4px !important;
          }
          @include media-breakpoint-up(md) {
            width: 100px !important;
            height: 100px !important;
            right: 4px !important;
            top: -7px !important;
          }
        }
      }
    }
    &.nl{
      .package__title a{
          &:before{background-image: url(../../../../img/package/highlight_soldout_nl.svg);}
      }
    }
    &.de{
      .package__title a{
          &:before{background-image: url(../../../../img/package/highlight_soldout_de.svg);}
      }
    }
    &.fr{
      .package__title a{
        &:before{background-image: url(../../../../img/package/highlight_soldout_fr.svg);}
      }
    }
  }

  &--free_shipping{
    @include media-breakpoint-up(md) {
      border:1px solid $artichoke_regular;
    }
    .package__title{
      a{
        padding-right:60px;
        &:before{
          content: "";
          width: 90px !important;
          height: 90px !important;
          display: block;
          position: absolute;
          right: -4px !important;
          top: -7px !important;
          background-repeat: no-repeat;
          background-size: contain;
          @include media-breakpoint-up(sm) {
            right: 3px !important;
          }
          @include media-breakpoint-up(md) {
            width: 85px !important;
            height: 85px !important;
            right: 6px !important;
            top: -6px !important;
          }

          #root .nl &{
            background-image: url(../../../../img/package/highlight_free_shipping_nl.svg);
          }
          #root .de &{
            background-image: url(../../../../img/package/highlight_free_shipping_de.svg);
          }
        }
      }
    }
  }

  &--bbq{
    .package__title{
      a{
        padding-right:60px;
        @include media-breakpoint-up(md) {
          padding-right:70px;
        }
        &:before{
          content: "";
          width: 45px;
          height: 54px;
          display: block;
          position: absolute;
          right: 10px;
          top: -7px;
          background-repeat: no-repeat;
          background-size: contain;
          @include media-breakpoint-up(sm) {
            right: 18px; 
          }
          @include media-breakpoint-up(lg) {
            right: 20px; 
            width: 59px;
            height: 70px;  
          }
        }
      }
    }
    &.nl{
      .package__title a{
          &:before{background-image: url(../../../../img/package/highlight_bbq_nl.svg);}
      }
    }
    &.de{
      .package__title a{
          &:before{background-image: url(../../../../img/package/highlight_bbq_de.svg);}
      }
    }
    &.uk{
      .package__title a{
        &:before{background-image: url(../../../../img/package/highlight_bbq_uk.svg);}
      }
    }
    &.fr{
      .package__title a{
        &:before{background-image: url(../../../../img/package/highlight_bbq_fr.svg);}
      }
    }
  }
  &--new, &--exclusive{
    .package__title{
      a{
        &:before{
          content: "";
          width: 60px;
          height: 60px;
          display: block;
          position: absolute;
          right: -1px;
          top: -5px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          @include media-breakpoint-up(sm) {
            right: 7px;
            top: -5px;
          }
        }
      }
    }
  }
  &--new{
    .package__title{
      a{
        &:before{
          #root .nl &{
            background-image: url(../../../../img/package/highlight_new_nl.svg);
          }
          #root .de &{
            background-image: url(../../../../img/package/highlight_new_de.svg);
          }
        }
      }
    }
  }
  &--exclusive{
    .package__title{
      a{
        &:before{
          #root .nl &{
            background-image: url(../../../../img/package/highlight_exclusive_nl.svg);
          }
          #root .de &{
            background-image: url(../../../../img/package/highlight_exclusive_de.svg);
          }
        }
      }
    }
  }
  &--free_gift{
    .package__title{
      a{
        padding-right:60px;
        &:before{
          content: "";
          z-index: 5;
          width: 90px !important;
          height: 90px !important;
          display: block;
          position: absolute;
          right: -4px !important;
          top: -7px !important;
          background-repeat: no-repeat;
          background-size: contain;
          @include media-breakpoint-up(sm) {
            right: 5px !important;
          }
        }
      }
    }
    &.nl{
        .package__title a:before{
            background-image: url(../../../../img/package/highlight_free-gift_nl.svg);
        }
    }
  }


  // express delivery
  &--express{
    position: relative;
    .package__title{
      a{
        &:before{
          content: "";
          width: 100%;
          height: 47px;
          display: block;
          position: absolute;
          left: 0;
          top: -28px;
          background-image: url(../../../../img/package/highlight_express.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          @include media-breakpoint-up(lg) {
            top: -24px;
          }
        }
      }
    }
  }
}


// show bank logo on the product page
.products{
  .footer{
    padding-bottom: 94px;
    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }
  }
}


.percentage {
  &__fill {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .img{
      &__fill{
        position: absolute;
        bottom: 0;
        overflow: hidden;
        width: 100%;
        display: flex;
        height: 0;
        @include transition(all, 0.6s, ease-in);
        img{
          height: 100%;
          object-fit: cover;
          object-position: bottom;
        }
      }
    }
  }
  &__box{
    position: relative;

    .package-per-person {
      position: absolute;
      bottom: 0;
      right: #{$grid-gutter-width * 2};
    }
    a{
      .animal-tag{
        color:black;
      }
    }
  }
}



.package-per-person {
  .amount{
    height: 40px;
    margin: 0 0 6px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .prev-price{
      color:lighten($color: $black, $amount: 50%);
      position: relative;
      line-height: 0.9;
      &:after{
        content: "";
        height: 2px;
        width: 100%;
        background-color: lighten($color: $black, $amount: 50%);
        position: absolute;
        top: 45%;
        left: 0;   
        @include transform(rotate(-12deg));
      }
    }
  }
  .pack-price, .p-price{
    color: $darkest_grey;
    margin:0;
    line-height: 1;
    font-family: $title_font;
    sup {
      top: -5px;
    }
  }
  .pack-price{
    font-size: 1.35rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
  }
  .p-price{
    font-size: 1.75rem;
  }
  .description {
    color: $gray-base;
    line-height: 1.2;
    font-size: 0.8rem;
  }
}


// --------------------------------------------
// ---------------- Animal Tag ----------------
// --------------------------------------------
.animal-tag{
  position: absolute;
  z-index: 1;
  &__body{
    text-align: center;
    display: flex;
    line-height: 1;
    .country{
      font-family: $body_font;
      font-size: 0.8rem;
    }
    .desc{
      font-family: $body_font;
      font-size: 0.8rem;
    }
    .amount{
      font-size: 1.1rem;
      font-family: $body_font;
      font-weight: bold;
    }
  }
}


.package{
  // Animal Shape size
  &--pig{
    .percentage__fill{
      @include transform(scale(0.75));
      transform-origin: 60% 100%;
    }
  }
  &--boar{
    .percentage__fill{
      @include transform(scale(0.7));
      transform-origin: 50% 100%;
    }
  }
  &--fish, &--salmon{
    .percentage__fill{
      @include transform(scale(0.65));
      transform-origin:center;
    }
  }
  &--codfish{
    .percentage__fill{
      @include transform(scale(0.88));
      transform-origin:center;
    }
  }
  &--duck{
    .percentage__fill{
      @include transform(scale(0.75));
      transform-origin:50% 100%;
    }
  }

  // Animal Ear-Tag
  &--cow, &--cow_chicken{
    .animal-tag{
      background-repeat: no-repeat;
      background-image: url(../../../../img/animal-tag-1.svg);
      .de & {
        background-image: url(../../../../img/animal-tag-1.svg);
      }
      .nl & {
        background-image: url(../../../../img/animal-tag-2.svg);
      }
      &__body{
        padding:0 0 14px 0;
        flex-direction: column;
        justify-content: flex-end;
        @include transform(rotate(12deg));
        width: 100%;
        height: 100%;
        position: relative;
        right: -4px;
        color: $darkest-grey;
        .desc{
          display: none;
        }
      }
    }
    .products & {
      .animal-tag{
        width: 74px;
        height: 84px;
        right:16%;
        top: 4px;
        @include transform(scale(0.8));
        @include media-breakpoint-up(sm) {
          right:20%;
          top: 17px;
          @include transform(scale(1));
        }
        @include media-breakpoint-up(lg) {
          top: 18px;
        }
        @include media-breakpoint-up(xl) {
          right:23%;
          top: 40px;
          @include transform(scale(1.3));
        }
      }
    }
    &.package__item{
      .animal-tag{
        @include transform(scale(0.85));
        width: 62px;
        height: 74px;
        right: 18%;
        top: 6px;
        @include media-breakpoint-up(lg) {
          right: 21%;
          top: 12px;
        }
        @include media-breakpoint-up(xl) {
          right: 19%;
          top: 8px;
        }
      }
    }
    &.grutto_combi_cow_pig_chicken{
      &.package__full{
        .animal-tag{
          right: 25% !important;
          @include media-breakpoint-up(sm) {
            right: 29% !important;
          }
          @include media-breakpoint-up(lg) {
            right: 30% !important;
          }
        }
      }
    }
  }
  &--chicken, &--roe,
  &--deer, &--sheep, &--turkey, &--boar, &--pig, &--fish, &--duck, &--goose, &--salmon, &--codfish{
    .animal-tag{
      background-image: url(../../../../img/animal-tags.svg);
      background-repeat: no-repeat;
      background-position: calc(50% + 3px);
      width: 120px;
      height: 120px;
      bottom: 0;
      display: flex;
      align-items:flex-start;
      justify-content: center;
      @include media-breakpoint-up(md) {
        left: 8px;
      }
      &__body{
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include border-radius(2px);
        @include transform(rotate(3deg));
        padding:3px 16px 6px 16px;
        background-color: $vanilla_dark;
        .de & {
          background-color: $vanilla_dark;
        }
        .nl & {
          background-color: $vanilla_light;
        }
        &:before{
          content: "";
          background-color: $darkest_grey;
          width: 5px;
          height: 5px;
          margin:2px auto 0 auto;
          @include border-radius(10px);
          display: block;
        }
        .country{
          display: none;
        }
      }
    }
  }
  &--wine{
    .animal-tag{
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 44px;
      top: 0;
      @include media-breakpoint-up(sm) {
        margin-top: 60px;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 70px;
      }
      @include media-breakpoint-up(xl) {
        margin-top: 90px;
      }
      @include media-breakpoint-up(xxl) {
        margin-top: 110px;
      }

      .animal-tag__body{
        align-items: center;
        padding: 0.4rem 0;
        @include border-radius(4px);

        .amount, .desc{
          font-size: 0.6rem;
          @include media-breakpoint-up(sm) {
            font-size: 0.8rem;
          }
        }
        .amount{
          margin-left:0.2rem;
        }
        &::after, &::before{
          content: "";
          background-color: $darkest_grey;
          width: 5px;
          height: 5px;
          margin:2px auto 0 auto;
          @include border-radius(10px);
          display: block;
          margin:0 0.3rem;
        }
        .de & {
          background-color: $vanilla_dark;
        }
        .nl & {
          background-color: $vanilla_light;
        }
      }
      .country{
        display: none
      }
    }
  }
  // Size of Animal-tag on List
  &__item{
    &.package{
      &--chicken, &--roe,
      &--deer, &--sheep, &--turkey, &--boar, &--pig, &--fish, &--duck, &--goose, &--salmon, &--codfish{
        .animal-tag{
          @include transform(scale(0.85));
          transform-origin: bottom left;
          left: -16px;
          @include media-breakpoint-up(md) {
            left: 8px;
          }
        }
      }
      &--wine{
        .animal-tag{
          margin-top: 40px;

          @include media-breakpoint-up(sm) {
            margin-top: 40px;
            @include transform(scale(0.85));
          }
          @include media-breakpoint-up(lg) {
            margin-top: 70px;
          }
          @include media-breakpoint-up(xl) {
            margin-top: 40px;
          }
          @include media-breakpoint-up(xxl) {
            margin-top: 50px;
          }
        }
      }
    }
  }

  &__switch{
    display: flex;
    flex-wrap: wrap;
    .item{
      margin: 5px 0;
      border-top:1px solid $gray_light;
      border-bottom:1px solid $gray_light;
      width: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: $title_font;
      font-weight: bold;
      font-size: 1rem;
      padding: 6px 0;
      .title{
        color:$primary_color;
        display: flex;
        align-items: center;
        [class^="icon-"] {
            font-size: 2rem;
            min-width: 40px;
        }
      }
      .value{
        display: flex;
        a{
          @include border-radius(50px);
          background-color: $gray_light;
          min-width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          margin-left: 4px;
          color:#7A7A79;
          &:hover{
            color:$primary_color;
          }
          &.active{
            background-color: $primary_color;
            color:#fff;
          }
        }
      }
    }
  }

}

.package__full{
  padding-bottom: 20px;
  @include media-breakpoint-up(sm) {
    padding-bottom: 40px;
  }
  &--gallery{
    padding-bottom: 0;
    @include media-breakpoint-up(md) {
      padding-bottom: 30px;
    }
    .carousel{
      height: auto;
      &__nav{
        bottom: 40px;
        padding: 0;
        opacity: 1;
        @include media-breakpoint-up(lg) {
          
        }
        li{
          @include media-breakpoint-down(lg) {
            margin: 0 2px;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    .items .item{
      height: 250px;
      @include media-breakpoint-up(md) {
        height: 350px;
      }
      @include media-breakpoint-up(xxl) {
        height: 420px;
      }
      img{
        width: 100%;
        height: 100%;
      }
    }

    .carousel__bottom{
      .prev, .next{
        padding: 0;
        width: 40px;
        height: 40px;
      }
    }
    &:hover{
      .carousel__bottom{
        .prev, .next{
          opacity: 1;
        }
      }
    }
    .carousel__content{
      background-color: rgba(0,0,0,0.5);
      bottom: 0;
      top:auto;
      color:#fff;
      padding: 3px 6px;
      line-height: 0.8;
      height: 30px;
    }

    &.msc{
      .item{
        &:after{
          content: "";
          background: url(../../../../img/bio/certified-sustainable-seafood-by-msc.svg);
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          background-color: none;
          position: absolute;
          left: 10px;
          top: 20px;
          z-index: 10;
          width: 45px;
          height: 70px;
          @include media-breakpoint-up(lg) {
            top: 35px;
            width: 70px;
            height: 90px;
            left: 25px;
          }
        }
      }
    }

    &.label-rouge{
      .item{   
        &:after{
          content: "";
          background: url(../../../../img/bio/label-rouge.svg);
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          background-color: none;
          position: absolute;
          left: 10px;
          top: 20px;
          z-index: 10;
          width: 70px;
          height: 55px;
          @include media-breakpoint-up(lg) {
            top: 35px;
            width: 90px;
            height: 72px;   
            left: 25px;
          }
        }
      }
    }

  }
  &--body{
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
    &.mobile{
      display: block;
      padding-top:20px;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
    h3{
      @extend .heading--h3;
    }
  }

  &--body2{
    &.accordion{
      border-top: 1px solid #ddd;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  // packages-remaining
  &.fr{
    .packages-remaining{
      display: block !important;
    }
    .buyers-1{
      display:none;
    }
  }

  // CTA colors --> Product Full
  .btn--cta{
    &:hover{
      background-color: $darkest_grey;
    }
  }

  // Bio logo on each Country --> Product Full
  &.nl{
    
    .package__full--gallery{
      &.bio{
        .item{
          &:after{
            content: "";
            background: url(../../../../img/bio/organic-certification.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: #95c11f;
            position: absolute;
            left: 0;
            top: 32px;
            z-index: 10;
            width: 60px;
            height: 40px;
            @include media-breakpoint-up(md) {
              width: 110px;
              height: 70px;
            }
          }
        }
      }
    }

  }

  &.de{
    
    .package__full--gallery{
      &.bio{
        .item{
          &:after{
            content: "";
            background: url(../../../../img/bio/de-bio-number.svg);
            background-size: contain;
            background-position: left center;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            top: 15px;
            z-index: 10;
            width: 70px;
            height: 60px;
            @include media-breakpoint-up(sm) {
              top: 20px;
              width: 90px;
              height: 70px;
            }
            @include media-breakpoint-up(md) {
              width: 100px;
              height: 80px;
            }
          }
        }
      }
    }

  }

  &.fr{
    
    .package__full--gallery{
      &.bio{
        .item{
          &:after{
            content: "";
            background: url(../../../../img/bio/agriculture-biologique.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            left: 10px;
            top: 10px;
            z-index: 10;
            width: 52px;
            height: 60px;
            @include media-breakpoint-up(sm) {
              width: 77px;
              height: 90px;
            }
          }
        }
      }
    }

  }

  &.uk{
    
    .package__full--gallery{
      &.bio{
        .item{
          &:after{
            content: "";
            background: url(../../../../img/bio/sa_organic_black.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            left: 10px;
            top: 10px;
            z-index: 10;
            width: 60px;
            height: 60px;
            @include media-breakpoint-up(md) {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }

  }

  .accordion{
    &__title{
      padding:1rem 0;
      margin: 0;
      @extend .heading--h3;
      color: $primary_color;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2{
        margin: 0;
        @extend .heading--h3;
      }
      p{
        font-size: 13px;
        font-family: $body_font;
        margin: 0;
        font-weight: normal;
        color: #666666;
        padding-top: 6px;
        padding-right: 10px;
        @include media-breakpoint-up(sm) {
          font-size: 15px;
        }
      }
      i{
        margin-right: 20px;
        color: $red_color;
        @include transition(0.1s);
      }

      &.open{
        i{
          @include transform(rotate(-180deg));
        }
      }
    }
    &__body{
      max-height: 0;
      overflow: hidden;
      text-align: justify;
      @include transition(all, 0.4s, ease);
      h3{
        @extend .heading--h3;
      }
      &.open{
        max-height: 2000px;
      }
    }
  }

}

.package--wine{
  .package__info{
    li{
      &:nth-child(2){
        display: none;
      }
    }
  }
  .package__price{
    .package__meal{
      display: none;
    }
  }
  .productContent__holder{
    .tfoot{
      display: none;
    }
  }
  .item-meal-per-person{
    display: none;
  }
}