.footer {
  h4 {
    margin-bottom: 0;
  }
  p,ul,h3{
    margin: 0;
  }
  &__middle{
    background-color: $artichoke_light;
    padding:15px 0 15px 0;
    color:$darkest_grey;
    @include media-breakpoint-up(md) {
      padding:40px 0;
    }
    a, a:link{
      color: $darkest_grey;
      @include transition(all, 0.2s, ease-in);
      &:hover{
        opacity: 0.7;
      }
    }
  }
  &__logo{
    text-align: center;
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
      text-align: initial;
    }
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    img{
      height: 70px;
      width: auto;
      @include media-breakpoint-up(sm) {
        height: 50px;
      }
      @include media-breakpoint-up(lg) {
        height: 60px;
      }
    }
  }
  .heading{
    font-family: $title_font;
    font-size: 1.1rem;
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 0px;
    }
  }
  &__link{
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  &__menu{
    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }
    ul{
      margin-bottom: 10px;
      li{
        line-height: 1.2;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 6px;
        @include media-breakpoint-up(md) {
          padding-right: 0;
        }
        a{
          display: block;
        }
      }
    }
  }
  &__address{
    font-size: 0.9rem;
    padding: 10px 0;
    text-align: center;
    @include media-breakpoint-up(sm) {
      text-align: initial;
      padding: 15px 0;
    }
  }
  &__social{
    display: flex;
    justify-content: center;
    @include media-breakpoint-up(sm) {
      justify-content: flex-start;
    }
    a{
      font-size: 2rem;
      margin: 0 10px;
      display: flex;
      @include media-breakpoint-up(sm) {
        margin:0 20px 0 0;
      }
    }
  }
  .certificate{
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
    @include media-breakpoint-up(md) {
      flex-direction: column;
    }
    &__item{
      display: flex;
      align-items: center;
      padding-right: 10px;
      @include media-breakpoint-up(sm) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
      }
      &:last-child{
        padding-right: 0;
        margin-bottom: 0;
      }
      img{
        display: block;
        width: 60px;
        @include media-breakpoint-up(lg) {
          width: auto;
        }
      }
    }
  }
  &__copy{
    background-color: $artichoke_lightest;
    padding: 15px 0;
    @include media-breakpoint-up(md) {
      padding: 20px 0;
    }
    .container{
      @include media-breakpoint-down(md) {
        max-width: 100%;
        padding: 0;
      }
    }
    .row-item{
      display: flex;
      flex-direction: column;
      text-align: center;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
        align-items:center;
        text-align: initial;
      }
    }
    .copyright{
      line-height: 1.2;
      font-size: 0.9rem;
      padding: 0 0.6rem;
    }
    .payment-methods{
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      justify-content: center;
      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
        margin-top: 0;
      }
      [class^="icon-"], [class*=" icon-"]{
        font-size: 1.2rem;
        margin:2px 0 2px 6px;
        display: block;
        color: #6d6d6d;
        @include media-breakpoint-up(md) {
          margin:2px 0 2px 12px;
          font-size: 2rem;
        }
        &:first-child{
          margin-left: 0;
          @include media-breakpoint-up(md) {
            margin-left: 0;
          }
        }
      }
      .icon-dpd{
        width: 62px;
        padding-right: 6px;
        border-right: 1px solid $artichoke_light;
        @media (min-width: 768px) {
          width: 90px;
          padding-right: 12px;
        }
        &::after{
          content: " ";
          width: 100%;
          height: 100%;
          background-image: url(../../../../img/dpd-food-express.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: block;
        }
      }
      .icon-ups{
        width: 18px;
        @media (min-width: 768px) {
          width: 34px;
        }
        &::after{
          content: " ";
          width: 100%;
          height: 100%;
          background-image: url(../../../../img/ups-express.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: block;
        }
      }
    }
  }  
}