.meatcut{
    &__howToCook{
        background-color: $gray_light;
        position: relative;
        padding-bottom: 5rem;

        .pic{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 230px;   
            @include media-breakpoint-up(lg) {
                height: 400px;
            }

            &::after{
                content: " ";
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                height: 60%;  

                background: rgb(255,255,255);
                background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(244,244,244,0.8) 70%, rgba(244,244,244,1) 100%);
            }

            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .mainTitle{
            padding: 3.5rem 0;
            @include media-breakpoint-up(lg) {
                padding: 8rem 0;
            }

            .heading{
                text-align: center;
                color: #fff;
                text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
                margin: 0;
                padding: 0 2rem; 
            }
        }

        .preparation{
            background-color: #fff;
            padding: 20px;
            text-align: justify;
            @include border-radius(8px);

            ol{
                padding-left: 20px; 
            }
        }

    }

    &__page{
      h1{
        @extend .heading--h1;
      }
      h2{
        @extend .heading--h3;
      }
      h3{
        @extend .heading--h4;
      }
    }
    &__img{
      img{
        display: block;
        max-width: 100%;
        width: 100%;
        margin-bottom: 30px;
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }
    }

    &__traceability{
        &--row{
            background-color: $vanilla_lightest;
        }
        .traceability__box{
            border-top:0;
        }
        .accordion__title{
            padding: 1rem 0;
            margin: 0;
            span{
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                text-align: center;
                line-height: 1;
                h2{
                    margin: 0;
                    padding: 0;
                    @extend .heading--h2;
                }
                p{
                    margin: 0;
                    font-family: $body_font;
                    font-weight: 100;
                    font-size: 1.1rem;
                    color: gray;
                }
            }
            i{
                display: none;
            }
        }
        .accordion__body{
            .body{
                text-align: center;
            }
        }
        .traceability__content{
            .text, &::before{
                @include media-breakpoint-up(lg) {
                    height: 90px;
                }  
            }
        }
        .traceability__steps{
            li{
                .val{
                    display: flex;
                    flex-direction: column;
                }
            }
            li:nth-child(2){
                .val{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row-reverse;
                    @include media-breakpoint-up(lg) {
                        flex-direction: row;
                    } 
                    span{
                        display: flex;
                        align-items: center;
                        font-size: 0.9rem;
                        line-height: 1;
                        min-width: 50%;
                        padding: 4px 0 4px 4px;
                        flex-direction: row-reverse;

                        @include media-breakpoint-up(lg) {
                            min-width: 33%;
                            flex-direction: row;
                            padding: 4px 2px 4px 0;
                        } 
                        &::before{
                            content: " ";
                            width: 5px;
                            height: 5px;
                            background-color: $secondary;
                            display: block;
                            margin:0 0 0 4px;
                            @include media-breakpoint-up(lg) {
                                margin:0 2px 0 0;
                            }
                        }
                    }
                }
            }
            li:nth-child(5){
                .val{
                    span{
                        display: flex;
                        align-items: center;
                        font-size: 0.9rem;
                        line-height: 1;
                        min-width: 100%;
                        padding: 4px 2px 4px 0;
                        &::before{
                            content: " ";
                            width: 5px;
                            height: 5px;
                            background-color: $secondary;
                            display: block;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }

    &__baking{
        .metadata, .metadata-box{
            margin-bottom: 0.8rem;
            .list{
                margin: 0;
                padding-left: 1.2rem;
            }
            .heading{
                font-family: $title_font;
                font-size: 1.2rem;
            }
            .recipe-ingredient-item{
                margin-bottom: 1rem;
                display: flex;
                .amount, .name{
                    line-height: 1;
                    font-size: 0.9rem;
                }
                .amount{
                    min-width: 70px;
                    text-align: right;
                    font-weight: bold;
                }
                .name{
                    padding-left: 0.8rem;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .metadata{
            .itm{
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;
                i{
                    color: $primary;
                    min-width: 20px;
                    margin-right: 4px;
                }
            }
        }
        .metadata-box{
            background-color:$vanilla_lightest;
            padding: 1rem;
        }
        .recipe-steps{
            margin: 0;
            padding: 0;

            li{
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #fff;
                border: 1px solid $vanilla_light;
                margin-bottom: 1rem;
                margin-top: 2.5rem;
                border-top:2px solid $primary;
                min-height: 80px;
                @include media-breakpoint-up(lg) {
                    border-top: 1px solid $lightest_grey;
                    flex-direction: row;
                    padding-top: 0;
                    padding-left: 3rem;
                    margin-top: 0;
                }

                .number{
                    margin-top: -25px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    align-self: stretch;
                    @include media-breakpoint-up(lg) {
                        margin-left: -25px;
                        margin-top: 0;
                        flex-direction: column;
                    }
    
                    &::after, &::before{
                        display: block;
                        height: 2px;
                        background-color: $vanilla_dark;
                        flex: 2;
                        @include media-breakpoint-up(lg) {
                            width: 2px;
                            content: " ";
                        }
                    }
    
                    span{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid $vanilla_dark;
                        width: 50px;
                        height: 50px;
                        @include border-radius(50px);
                        font-family: $title_font;
                        font-size: 1.2rem;
                        color: $darkest_grey;
                        background-color: #fff;
                    }
                }
    
                .text{
                    padding: 0.4rem 1rem 1rem 1rem;
                    text-align: justify;
                    flex: 2;
                    @include media-breakpoint-up(lg) {
                        padding: 1rem;
                    }
                }
    
                .image{
                    align-self: stretch;
                    display: flex;
                    img{
                        width: 100%;
                        height: 200px;
                        object-fit: cover;
                        @include media-breakpoint-up(lg) {
                            width: 300px;
                            height: 100%;
                        }
                    }
                }

                &.with-image{
                    margin-top: 0;
                    overflow: hidden;
                    border-top: 1px solid $lightest_grey;
                    padding-top: 0;
                    @include media-breakpoint-up(lg) {
                        padding-left: 0;
                    }

                    .number{
                        &::after, &::before{
                            content: " ";
                        }
                    }
                }
            }
        }
    }

}

.ingredients{
      margin-bottom: 2rem;
      &__persons{
        background-color: #fff;
        margin-bottom: 8px;
        @include border-radius(5px);
        padding: 8px;

        font-family: $title_font;
        display: flex;
        align-items: center;
        color: $primary;

        &::before{
            content: "\e906";
            font-family: 'icomoon';
            padding: 0 5px;
            font-size: 1.4rem;
        }

      }
      &__item{
          display: flex;
          padding: 8px;
          background-color: rgba(255,255,255,0.5);
          margin-bottom: 8px;
          @include border-radius(5px);

          .weight{
              font-weight: bold;
              min-width: 90px;
              text-align: right;
              padding-right: 20px;
          }
      }
  }
  .needed{
      margin-bottom: 2rem;

      .title{
          font-weight: bold;
          margin-bottom: 6px;
      }
  }