
//Brand colors
$primary:         #919678!default;
$primary_color:   #919678!default;
$secondary:       #F9423A!default;
$secondary_color: #F9423A!default;

$caramel_color:   #F2EBD9!default; //#FED196
$green_color:     #95c11f!default; //#559845
$red_color:       #F9423A!default;

$blue-lagoon:     #00577A!default;
$caramel:         #F2EBD9!default; //#FED196
$red:             #FF3C34!default;
$orange:          #FF5800!default;


//Shades of Grey
$gray_light:      #F4F4F4!default;
$gray-base:       #BBB!default;

$white:           #ffffff!default;
$lightest_grey:   #F2F2F2!default;
$light_grey:      #DDDDDD!default;
$grey:            #999999!default;
$dark_grey:       #666666!default;
$darkest_grey:    #333333!default;
$black:           #111111!default;


// Rebranding New Color
$vanilla_dark:      #CFC5AC!default;
$vanilla_light:     #F2EBD9!default;
$vanilla_lightest:  #F7F3E8!default;

$artichoke_dark:    #919678!default;
$artichoke_regular: #C4C9AB!default;
$artichoke_light:   #D7DAC5!default;
$artichoke_lightest:#EBEDE2!default;


// Body font color
$body-color:      $darkest_grey!default;

// Link
$link-color: $primary_color;

// Typography
$font-size-base:     1rem !default;
$font-size-small:    0.875rem !default;
$font-size-base-big: 1.125rem!default;

$font-display: swap;
$brown-regular-path:          '../vendors/webfonts/brown-regular';
$brown-bold-path:             '../vendors/webfonts/brown-bold';


$grutto-base-colors: (
  "primary":    $primary_color,
  "blue":       $primary_color,
  "secondary":  $secondary_color,
  "gray_light": $gray_light,
  "caramel":    $caramel_color,
  "green":      $green_color,
  "red":        $red_color,
  "orange":     $orange,
  "gray-base":  $gray-base,
  "gray-light": $gray_light,
  "white":          $white,
  "lightest-grey":  $lightest_grey,
  "light-grey":     $light_grey,
  "grey":           $grey,
  "dark-grey":      $dark_grey,
  "darkest-grey":   $darkest_grey,
  "black":          $black,
  "vanilla-dark":       $vanilla_dark,
  "vanilla-light":      $vanilla_light,
  "vanilla-lightest":   $vanilla_lightest,
  "artichoke-dark":     $artichoke_dark,
  "artichoke-regular":  $artichoke_regular,
  "artichoke-light":    $artichoke_light,
  "artichoke-lightest": $artichoke_lightest,
  "lightest_green":     $artichoke_lightest,
);


// Typography
$font-size-base:     1rem !default;
$font-size-small:    0.875rem !default;
$font-size-base-big: 1.125rem!default;

$font-display: swap;

//== Media queries breakpoints
$media-breakpoint-small-devices: 321px!default; //Bigger than iPhone 5

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1380px
) !default;

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  xs: 375px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1180px,
  xxl: 1380px
) !default;

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 8px !default;


// Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 3),
    7: ($spacer * 4),
    8: ($spacer * 6)
  ),
  $spacers
);


// Animals
$cat-values : (
  lamb,
  sheep,
  cow,
  pig,
  deer,
  chicken,
  turkey,
  cow_chicken,
  fish,
  salmon_codfish,
  duck,
  goose,
  boar,
  roe,
  wine
) !default;