.bg {
  @each $key, $val in $grutto-base-colors {
    &--#{$key} {
      background-color: $val!important;
    }
  }
}

.text, .color{
  @each $key, $val in $grutto-base-colors {
    &--#{$key} {
      color: $val!important;
    }
  }
}