@import 'bundle';
@import 'farmshop_item'; 
@import 'farmshop_full';
@import 'overview';
@import 'popup';
@import 'bundle-popup';

.farmshop, .farmshop__full{
    .container{
        @include media-breakpoint-down(xs) {
            max-width: 100%;
            padding-left: 12px;
            padding-right: 12px;
        }
    }
}
.App.boerderij-box, .App.farmbox, .bundle__tabs{
    background-color: $vanilla-light;
}
.App.boerderij-box, .App.farmbox, .App.farmshop-products{
    .footer__middle{
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

// --------------------------------------
.overview-items, .farmshop__item, .farmshop__order{
    .msg{
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.6);
        font-size: 0.9rem;
        line-height: 1;
        border: 1px solid $vanilla-light;
        border-left: 4px solid $artichoke_dark;

        backdrop-filter: blur(3px);
    }
}
.overview-items{
    .msg{
        height: 100%;
        padding: 0.6rem;
    }
}
.farmshop__item{
    .msg{
        min-height: 68px;
        display: flex;
        align-items: center;
        padding: 0.8rem;
    }
}
.farmshop__order{
    @include media-breakpoint-up(lg) {
        position: initial;
    }
    .msg{
        height: 100%;
        width: 100%;
        padding: 0.6rem;
        display: flex;
        align-items: center;
    }
}