// ---- popUp mobile style ----
.bundle__popup{
    .modal-dialog{
        @media (max-width: 575.98px) {
            margin: 65px 0 0 0 !important;
        }
    }
    .modal-header{
        background: #fff;
        border-bottom: 0;
        display: flex;
        justify-content: flex-end;

        @media (max-width: 575.98px) {
            padding: 1.2rem 1.2rem;
            @include border-radius(0px);
            position: sticky;
            top: 65px;
            z-index: 99;
        }

        .back{
            @media (min-width: 576px) {
                display: none;
            }
        }
        &--close{
            display: flex;
            align-items: center;
            // display: none;

            i{
                font-size: 1rem;
                font-style: normal;
                margin-right: 6px;
            }
            [class^="icon-"]{
                font-size: 1rem;
                color: $primary_color;
                display: flex;
                flex-direction: row-reverse;
            }
            @media (min-width: 576px) {
                display: block;
            }
        }
    }
    .modal-content{
        @media (max-width: 575.98px) {
            border: 0 !important;
            @include border-radius(0px);
            min-height: 100vh;
        }
    }
}

// ----------------------------
.bundle_table_item{
    .cart__info{
        .product-price{
            display: flex;
            align-items: center;

            [class^="icon-"]{
                margin-right: 10px;
                font-size: 1.3rem;
            }
        }

        .product-name{
            @media (max-width: 560px) {
                text-align: left;
            }
            .package-title, .package-sub-title{
                @media (max-width: 560px) {
                    text-align: center;
                }
            }
        }

        .qty-price{
            margin-top: -50px;
        }
    }

    .cart__detail{
        > .product-detail{
            padding: 10px 10px 0 10px;
        }
    }


    .bundled_table_item{
        border: 0 !important;
        margin: 0;
        @include border-radius(6px);

        &:nth-child(even){
            background-color: #F5F4F3;
        }

        .product-detail{
            display: none;
        }

        .cart__body{
            padding: 5px;
            margin: 1px;


            .cart__info{
                @media (min-width: 576px) {
                    display: flex;
                    align-items: center;
                }
                .product-name, .bundle__item-info{
                    float: left;
                    width: calc(100% - 110px);
                    line-height: 1.2;
                    @media (min-width: 576px) {
                        float: none;
                        width: initial;
                    }
                }
                .product-name{
                    padding: 0;
                    margin: 0;
                    flex: 2;

                    h2{
                        font-family: $body_font;
                        font-weight: normal !important;
                        padding: 0;
                        margin: 0;
                        font-size: 1rem !important;
                        text-align: left;
                    }
                }
                .bundle__item-info{
                    color: #525252;
                    font-size: 0.9rem;
                }
                .qty-price{
                    margin: -18px 0 0 0;
                    float: right;
                    @media (min-width: 576px) {
                        float: none;
                        margin: 0 0 0 10px;
                    }
                }

                .quantity{
                    .qty{
                        padding: 2px 27px;
                        background-color: #e4e4e4 !important;
                        @include border-radius(20px);
                        border: 0 !important;
                        color: #000 !important;
                        font-size: 1rem;
                        height: 30px;
                        font-family: $body_font;
                    }
                    .minus-css, .plus-css{
                        width: 24px;
                        height: 24px;
                        @include border-radius(20px);
                        font-size: .7rem;
                    }
                    .minus-css{
                        left: 3px;
                    }
                    .plus-css{
                        right: 3px;
                    }
                }
            }
        }
        .cart__detail{
            border: 0 !important;
        }
    }
}

.edit_bundle_in_cart_text{
    border: 1px solid $primary_color;
    padding: 4px 9px;
    display: inline-flex;
    font-size: 1.2rem;
    @include border-radius(4px);
    @include transition(all, 0.3s, ease);

    &:hover{
        background-color: $primary_color !important;
        color: #fff !important;

        small{
            text-decoration: none !important;
        }
    }
}
// Hero Section Image - AB Test -----------
.farmshop{
    &__hero{
        z-index: 1;
        position: relative;
        > .container{
            height: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            height: 250px;
            @include media-breakpoint-up(md) {
                height: 280px;
                justify-content: center;
            }
            .body{
                padding: 4rem 0 1rem 0;
                @include media-breakpoint-up(md) {
                    padding: 2rem 0;
                }
                .heading{
                    font-size: 1.4rem;
                    text-shadow: black 1px 1px;
                    @include media-breakpoint-up(md) {
                        font-size: 2rem;
                    }
                }
                ul{
                    line-height: 1.4;
                    font-size: 0.76rem;
                    @include media-breakpoint-up(sm) {
                        font-size: 0.9rem;
                    }
                    @include media-breakpoint-up(md) {
                        font-size: 1rem;
                    }
                    li{
                        text-shadow: black 1px 1px;
                    }
                }
            }
        }
        &-img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top:0;
            z-index: -1;

            &.object-md-bottom{
                @include media-breakpoint-up(md) {
                    object-position: bottom;
                }
            }
        }

        // Bio Logo title
        span.organic{
            z-index: 1;
            display: block;
            width: 35px;
            height: 47px;
            background-image: url(../../../../../img/farmshop/farmshop-bio.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top center;
            @include border-radius(0, 0, 0px, 0px);
            background-color: #95c11f;
            @media (min-width: 768px) {
                width: 50px;
                height: 70px;
            }
        }
        // AB Test --------
        .badge{
            width: 90px;
            height: 90px;
            position: absolute;
            right: 15px;
            top: 15px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            @include media-breakpoint-up(md) {
                width: 150px;
                height: 150px; 
                margin-top: -75px;
                right: 10%;
                top: 50%;
            }
            // .nl &{
            //     background-image: url(../../../../../img/farmshop/sticker-NL.svg);
            // }
            .de &{
                background-image: url(../../../../../img/farmshop/sticker-de-4.svg);
            }
        }
    }
    // AB Test
    &__top-usp{
        background-color: $artichoke_regular;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        column-gap: 1.5rem;
        @include media-breakpoint-up(sm) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .item{
            display: flex;
            align-items: center;
            column-gap: 0.4rem;
            line-height: 1.2;

            i{
                font-size: 1.6rem;
            }
        }
    }
}

// ----------------------------------------
.bundle{
    &__title{
        padding:1rem 0;
        position: relative;

        // Bio Logo title
        span.organic{
            z-index: 1;
            display: block;
            width: 35px;
            height: 47px;
            position: absolute;
            left: 0;
            top: 0;
            background-image: url(../../../../../img/farmshop/farmshop-bio.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top center;
            @include border-radius(0, 0, 6px, 6px);
            background-color: #95c11f;
            @media (min-width: 768px) {
                width: 50px;
                height: 70px;
                top:0rem;
            }
        }

        h1.heading{
            margin-top: 0;
            margin-bottom: 0.5rem !important;
            font-family: $title_font;
            font-size: 1.05rem !important;
            text-align: center;
            text-transform: initial;
            padding: 0 2.5rem;
            line-height: 1.1;
            letter-spacing: -1px;
            @media (min-width: 576px) {
                font-size: 1.7rem !important;
            }
            @media (min-width: 768px) {
                margin-top: 0rem;
                padding: 0 4rem;
            }
            @media (min-width: 992px) {
                font-size: 2rem !important;
            }
        }

        .desc{
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1.4;
            font-size: 0.82rem;
            @include media-breakpoint-up(md) {
                font-size: 0.9rem;
            }

            p{
                text-align: center;
            }

            img{
                height: 36px;
                @include border-radius(6px);
                margin: 0 0.5rem 0 0;
            }
        }
    }


    &__items{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 2rem;

        .animal_group{
            width: 100%;
            margin: 15px 5px;
            display: flex;
            @include media-breakpoint-up(sm) {
                margin: 15px 10px;
            }
            
            .title{
                display: flex;
                font-family: $title_font;
                width: 100%;
                line-height: 1;
                align-items: flex-end;
                font-size: 1.2rem;
                @include media-breakpoint-up(xs) {
                    font-size: 1.4rem;
                }
                &::after{
                    content: " ";
                    height: 1px;
                    flex: 2;
                    background-color: $vanilla-dark;
                    align-items: flex-end;
                    margin: 0 0 4px 4px;
                    @include media-breakpoint-up(sm) {
                        margin: 0 0 4px 10px;
                    }
                }
                .sep{
                    padding: 0 4px;
                }
                .sep, .desc{
                    font-size: 0.9rem;
                    font-weight: 100;
                    font-family: $body_font;
                    padding-bottom: 2px;
                    opacity: 0.9;
                }
                .desc{
                    font-size: 0.7rem;
                    @include media-breakpoint-up(sm) {
                        font-size: 0.9rem;
                    }
                    .de &{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 0.65rem;
                        @include media-breakpoint-up(sm) {
                            font-size: 0.9rem;
                        }
                    }
                }
            }

            hr{
                margin: 0;
                border: 0;
                margin-top: -145px;
                
                @media (min-width: 768px) {
                    margin-top: -165px;
                }
            }
        }

        &.no-results{
            justify-content: center;
        }
        .search-state{
            width: 100%;
        }
    }

    &__tabs{
        margin: 0;
        display: flex;
        justify-content: center;
        list-style: none;
        position: sticky;
        z-index: 9;
        background-color: #fff;
        box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
        padding: 10px;
        @media (min-width: 576px) {
            width: 100%;
            margin-left: auto;
            box-shadow: none;
        }
        top: 65px;
        @media (min-width: 768px) {
            top: 92px;
        }
        @media (min-width: 992px) {
            top: 0;
        }
        .App.boerderij-box &, .App.farmbox & {
            @media (min-width: 992px) {
                top: 92px;
            }
        }
        .nav-item{
            padding:0 4px 0 0;

            &:last-child{
                padding-right: 0 !important;
            }
        }
        a.farmshop-tab{
            padding: 4px 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            color: $darkest_grey !important;
            background-color: #fff;
            width: 100%;
            text-wrap: nowrap;
            @include media-breakpoint-up(sm) {
                padding: 4px 16px;
            }
            &:hover{
                color: $darkest_grey !important;
                background-color: $artichoke_lightest !important;
            }
            &.active{
                background-color: $artichoke_dark !important;
                color: #fff !important;
                &:hover{
                    color: #fff;
                }
                span{
                    filter: invert(1) brightness(2);
                }
            }
            [class^=ico-cat]{
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 42px;
                height: 30px;
            }
            .ico-cat{
                @each $val in $cat-values {
                    &-#{$val} {
                      background-image: url(../../../../../img/animals-icon/#{$val}/#{$val}_grayscale.svg);
                    }
                }
                &-salmon{
                    background-image: url(../../../../../img/animals-icon/fish/fish_grayscale.svg);
                }
                &-geurlijn{
                    background-image: url(../../../../../img/animals/geurlijn/geurlijn.svg)
                }
                &-bioCow{
                    background-image: url(../../../../../img/animals-icon/cow/cow_grayscale.svg)
                }
                &-christmas{
                    background-image: url(../../../../../img/animals/christmas_grayscale.svg)
                }
                &-easter{
                    background-image: url(../../../../../img/easter-black.svg);
                    background-size: 30px;
                    background-position: bottom;
                }
                &-bbq{
                    background-image: url(../../../../../img/animals/bbq.svg);
                    background-size: 24px;
                    background-position: bottom;
                }
                &-game{
                    background-image: url(../../../../../img/animals-icon/deer/deer_grayscale.svg);
                }
            }
        }
        .data-filter{
            margin: 0 !important;
            justify-content: space-between;
            max-width: 730px;
            overflow: hidden;
            position: relative;
            .action{
                min-height: 58px;
                [class^=icon-]{
                    color: $primary_color;
                }
            }
            nav{
                @include media-breakpoint-up(sm) {
                    overflow: initial;
                }
                ul{
                    padding-right: 0 !important;
                    li{
                        min-width: 64px;
                        @include media-breakpoint-down(sm) {
                            font-size: 13px;
                        }            
                        @include media-breakpoint-up(sm) {
                            min-width: auto;
                        }
                        background-color: transparent !important;
                        &:hover{
                            background-color: transparent !important;
                        }
                    }
                }
            }
            &__search{
                border: 0;
            }
        }
    }
}

// ---------- Cart Total
.bundle{
    &_data{
        display: none;
        background-color: #f5f4f3;
        position: fixed;
        top: 65px;
        width: 100%;
        max-width: 100%;
        max-height: calc(100vh - 65px);
        padding: 60px 20px 20px 20px;
        z-index: 9999;
        right: -100%;
        @include transition(all, 0.8s, ease);
        overflow-y: scroll;

        @media (min-width: 576px) {
            max-width: 330px;
            width: 330px;
        }

        @media (min-width: 767px) {
            top: 92px;
            max-height: calc(100vh - 92px);
        }

        @media (min-width: 992px) {
            position: initial;
            left: initial;
            bottom: initial;
            padding: 10px 10px 0 0;
            max-width: 300px;
            width: 300px;
            background-color: initial;
            overflow-y: initial;
            max-height: initial;
            z-index: initial;
        }

        .bundle__basket-close{
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            padding: 8px 20px;
            display: flex;
            align-items: center;
            color: $primary_color;
            justify-content: flex-end;

            [class^="icon-"]{
                margin-left: 6px;
            }

            @media (min-width: 992px) {
                display: none;
            }
        }
    }
    &__subtotal{
        display: flex;
        justify-content: space-between;
        align-items: center;     
        padding-bottom: 6px;
        margin-bottom: 6px;
        border-bottom: 1px solid #ddd;
        padding-right: 10px;
        padding-left: 10px;
        @media (min-width: 992px) {
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        .price{
            margin-bottom: 0 !important;
            margin-right: 10px !important;
            font-weight: bold;
        }
        .totals-weight{
            text-align: right;
            .lbl{
                color: #999999;
                font-size: 0.9rem;
                line-height: 1;
            }
            .bundle_totals_grutto{
                font-size: 1rem;     
                line-height: 1.1;
                color: #000000;    
            }
        }
    }
    &-order-messages{
        margin-top: 8px;

        .bundle-order-note{
            display: flex;
            align-items: center;
            color: #666666;
            line-height: 1.1;
            font-size: 0.8rem;
            @media (min-width: 768px) {
                font-size: 14px;
            }
            @media (min-width: 992px) {
                line-height: 1.4;
            }
            p{
                margin: 0;
                flex: 2;
            }
            i{
                margin-right: 8px;
                color: #999999;
                font-size: 1.2rem;
                width: 22px;
            }
        }
        .order-note-0{
            color: #000;
        }
        .order-note-50{
            color: #666666;

            i{
                color: $black;
            }
        }
        .order-note-can-order{
            color: $green_color;

            i{
                color: $green_color;
            }
        }
        .order-note-100{
            color: $green_color;

            i{
                color: $green_color;
            }
        }
    }
    &-cart-btn{
        margin-top: 8px;
    }
}

// -------------- PopUp -----------------
.bundle__popup{
    &--header{
        display: flex;
        align-items: center !important;

        .icon-close{
            color: #999999;
            font-size: 1.2rem;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .back{
            display: flex;
            align-items: center;
            color: $primary_color;
            cursor: pointer;

            [class^="icon-"]{
                margin-right: 6px;
            }
        }
    }
    &--body{
        padding: 0;
    }
    &--box{
        padding: 1.2rem;
        h2{
            font-family: $title_font;
            color: $primary_color;
            margin: 0;
            font-size: 1.375rem !important;
            line-height: 26px;
            font-weight: bold;
        }
        .body{
            h2,h3,h4{
                margin-top: 1rem !important;
                margin-bottom: 0.5rem !important;   
            }
            ul{
                margin-left: 0.5rem;
                list-style: none;
                li{
                    padding: 0.2rem 0;
                    &::before{
                        content: "\e909";
                        font-family: "icomoon"!important;
                        margin-right: 0.5rem;
                        font-size: 0.9rem;
                    }
                }
            }
        }
        .bpbox-cta{
            .btn{
                line-height: 1.2;   
            }
        }
    }
    &--meatcut{
        padding: 1.2rem;
        @media (max-width: 575.98px) {
            padding-top: 8px;
        }

        .img-holder{
            position: relative;

            &::before{
                content: " ";
                position: absolute;
                display: block;
                left: 10px;
                top: -8px;
                background-size: contain;
                background-repeat: no-repeat;
                width: 58px;
                height: 70px;
            }
            &::after{
                content: " ";
                position: absolute;
                display: block;
                bottom: 12px;
                left: 12px;
                width: 20px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
        .title{
            font-family: $title_font;
            color: $primary_color;
            margin: 0;
            @media (max-width: 375px) {
                font-size: 1.2rem !important;
            }
        }
        .bundled__details{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.4rem 0;
            margin: 0.6rem 0;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;

            .price{
                white-space: nowrap;
            }
        }
        .farmshop_stock{
            background-color: #ECEAE7;
            color: #666666;
            @include border-radius(3px);
            padding: 0.5rem 1rem;
            min-height: 35px;
            display: flex;
            align-items: center;
            font-weight: bold;
            margin-left: 1.5rem;
            line-height: 1;
            text-align: center;
        }
    }
    &--cta{
        margin: 1rem 0;
        position: relative;

        .qty-box{

            .quantity{
                min-width: 145px;
                height: 35px;

                input.bundled_qty_popup{
                    width: 100%;
                    padding: 0;
                    background: #fff;
                    box-shadow: 0 0 0 #fff;
                    font-weight: bold;
                    display: none;
                    height: 35px;
                    font-size: 1.2rem;

                    &[type='number']::-webkit-inner-spin-button, 
                    &[type='number']::-webkit-outer-spin-button { 
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
                button.minus-css, button.plus-css{
                    width: 35px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border:1px solid $primary_color !important;
                    @include border-radius(6px);

                }
                button.minus-css{
                    left: 0;
                    display: none;
                    &:hover{
                        &::before{
                            color:  $primary_color !important;
                        }
                    }
                }
                button.plus-css{
                    right: 0;
                    color: #fff !important;
                    background-color: $primary_color !important;
                    width: 100%;
                    display: flex;
                    flex-flow: row-reverse;

                    span{
                        line-height: 1;
                        margin-right: 8px;
                        @include transition(all, 0.3s, ease);
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    &:hover{
                        color: #fff !important;
                        background-color: darken($color: $primary_color, $amount: 50);
                        &::before{
                            color: #fff !important;
                        }
                    }
                }
            }

            &.enabled{
                .plus-css{
                    width: 35px !important;

                    span{
                        display: none;
                    }
                }
                .minus-css{
                    left: 0;
                    opacity: 1;
                    display: flex !important;
                }
                input.bundled_qty_popup{
                    opacity: 1;
                    display: block !important;
                }
            }
        }

        .price--info{
            color:#999999;

            .bundle_total_price{
                font-size: 1.1rem;
            }
        }
    }
    // ----------
    &--farmer, &--breed{
        padding: 1rem 0.8rem;
        display: flex;
        align-items: center;

        .img{
            margin-left: 1rem;

            img{
                @include border-radius(50%);
                width: 100px;
                height: 100px;
                object-fit: cover;
                @media (min-width: 768px) {
                    width: 130px;
                    height: 130px;
                }
            }
        }
        .body{
            flex: 2;
            .title-icon{
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;

                span{
                    font-size: 1.2rem;
                    margin-right: 6px;
                }
            }
            h2{
                font-family: $title_font;
                color: $primary_color;
            }
        }
    }

    &--title{
        display: flex;
        align-items: center;
        font-family: $title_font !important;
        font-size: 1.2rem;

        &::before{
            content: " ";
            width: 10px;
            height: 10px;
            @include border-radius(2px);
            background-color: $primary_color;
            margin-right: 6px;
        }
        &::after{
            content: " ";
            flex: 2;
            height: 1px;
            background-color: #DDDDDD;
            margin-left: 6px;
        }

    }

    &--definition{
        padding: 1rem 0.8rem;
        .bundle__popup--title{display: none;}
        h4{
            font-family: $title_font !important;
            font-size: 0.9rem !important;
            color: #000 !important;
            @media (min-width: 425px) {
                font-size: 1rem !important;
            }
        }
    }

    // traceability ----------
    &--traceability{
        padding: 0.8rem;

        strong{
            font-family: $title_font;
        }
    }
}


body.bundle_page{
    .cart-contents.mobile{
        &::after{
            content: " " !important;
            background-image: url(../../../../../img/farmshop/package-open.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 30px !important;
            height: 30px;
            margin-left: -5px;
            @media (min-width: 768px) {
                width: 100%;
                margin: 0;
                background-position: 40% center;
            }
        }
    }
    .bundle_total_price_basket{
        white-space: nowrap;
        background: #559845;
        position: absolute;
        left: 0;
        top: 0;
        padding: 1px 6px;
        border-radius: 6px;
        font-weight: bold;
    }
}

// --------- order-received ---------
.bundled_table_item{
    border-bottom: 1px solid #eaeaea !important;

    .product_title{
        margin: 0;
        h3{
            font-family: $title_font !important;
            font-size: 1.2rem;
        }
    }
    .bundled_table_item_subtotal{
        font-size: 1rem;
        &::after{
            display: none;
        }
    }
    .cb-package-contents{
        display: none;
    }
}

.bundle_total_price {
    font-size: 1.6rem;
    letter-spacing: -1px;
    sup{
        top: -5px;
    }
}
