.header__top {
  border-bottom:1px solid $artichoke_light;
  position: sticky;
  top: 0;
  background-color:$artichoke_light;
  z-index: 9999;
  @include box-shadow(1px, 1px, 10px, rgba(0,0,0,0.10));
  @include media-breakpoint-up(lg) {
    height: auto;
    z-index: initial;
    position: initial;
    @include box-shadow(0, 0, 0, rgba(0,0,0,0));
  }
  .App.boerderij-box &, .App.farmbox &{
    position: sticky;
    top: 0;
    z-index: 9999;
  }
  // color
  a, a:link{
    color: $darkest_grey;
  }
  .header__logo {
    text-align: center;
    min-height: 65px;
    display: flex;
    align-items: center;
    flex: 2;
    justify-content: center;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    @include media-breakpoint-up(md) {
      min-height: 92px;
    }
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      background-color: transparent;
      position: relative;
    }
    .logo{
      display: block;
      width: 80px;
      height: 60px;
      background-image: url(../../../../img/logo/grutto-new-logo.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
      width: 100px;
      @include media-breakpoint-up(sm) {
          width: 110px;
      }
      @include media-breakpoint-up(md) {
          width: 130px;
      }
    }
  }
  &.de{ //Change the logo for ~/de
    .logo{
      display: block;
      width: 120px !important;   
      height: 40px;    
      background-image: url(../../../../img/logo/kaufnekuh.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      @include media-breakpoint-up(sm) {
        width: 140px !important;
        height: 50px;
      }
      @include media-breakpoint-up(md) {
        height: 50px;
        width: 190px !important;
      } 
      @include media-breakpoint-up(lg) {
        background-position: center left;
      }  
      @include media-breakpoint-up(xl) {
        height: 50px;
      }  
    }
  }
  .header__menu {
    width: 100%;
    a {
      &.active {
        color: $secondary_color;
      }
      &:hover {
        color: $secondary_color;
      }
    }
    .menu__logo-holder{
      position: initial;
    }
    .menu__hamburger {
      background: transparent;
      border: none;
      display: block;
      float: left;
      width: 40px;
      padding: 0;
      margin-right: 16px;
      z-index: 99;
      position: relative;
      &:hover, &:focus {
        outline: 0;
        cursor: pointer;
      }
      &.collapse {
        .icon-bar {
          &.top-bar, &.bottom-bar {
            transform: rotate(0);
          }
          &.middle-bar {
            opacity: 1;
          }
        }
      }
      button{
        margin-bottom: 4px;
      }
      .icon-bar {
        width: 28px;
        height: 1px;
        background-color: $darkest_grey;
        display: block;
        @include transition(all, 0.2s, ease-in);
        margin-top: 7px;
        &.top-bar {
          @include transform(rotate(45deg));
          transform: rotate(45deg);
          transform-origin: 10% 10%;
        }
        &.middle-bar {
          opacity: 0;
        }
        &.bottom-bar {
          transform: rotate(-45deg);
          transform-origin: 10% 90%;
        }
      }
      .menu__hamburger__label {
        color: $darkest_grey;
        font-size: $font-size-small;
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
    .header__account {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 99;
      position: relative;
      min-height: 65px;
      @include media-breakpoint-up(md) {
        min-height: 92px;
      }
      @include media-breakpoint-up(lg) {
        min-height: auto;
      }
      a {
        display: inline-block;
        text-align: center;
        padding-left:12px;
        white-space: nowrap;
        font-size: 1rem;
        [class^="icon-"] {
          font-size: 1.8rem;
          display: block;
          @include media-breakpoint-up(md) {
            font-size: 1.75rem;
          }
        }
        &:first-child{
          padding-left: 0;
        }
        .basket{
          display: block;
          width: 40px;
          margin: 0 auto;
          position: relative;
        }
        .basket-count {
          position: absolute;
          right: -8px;
          top: -5px;
          width: 20px;
          height: 20px;
          background-color: $secondary;
          border-radius: 50%;
          color: $white;
          @include transform(translateX(-35%));
          font-size: 12px;
          text-align: center;

          &.is-loading{
            display: flex;
            justify-content: center;
            align-items: center;
            &::after{
              content: " ";
              width:6px;
              height:6px;
              background:#FFF;
              border-radius:100%;
              float:left;
              animation:bounce 1s infinite;
              animation-delay:.1s;
              background:#fff;
            }
          }
        }
        .text{
          display: none;
          @include media-breakpoint-up(md) {
            display: block;
          }
        }
      }
      .basket-link{
        &.is-loading{
          cursor: progress;
          &:hover{
            color: #333;
          }
        }
        // A/B Test
        .icon-basket2{
          @include transition(all, 0.35s, ease);
          height: 28px;
          background-image: url(../../../../img/header/basket-red.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 28px;
          filter: grayscale(1) brightness(0.7);
        }
        &:hover{
          .icon-basket2{
            filter: initial;
          }
        }
      }
    }

    .menu__navigation_items {
      left: -100%;   
      position: fixed;
      top: 66px;
      height:calc(100vh - 66px);
      @include transition(all, 0.2s, ease); 
      background: rgba(0, 0, 0, 0);
      @include media-breakpoint-up(md) {
        top: 92px;
        height:calc(100vh - 92px); 
      }
      @include media-breakpoint-up(lg) {
        display: block;
        margin-top: 0;
        left: initial;
        position: initial;
        top: initial;
        height: auto;
      }

      // Gray Background
      .submenu__gray-bg{
        background-color: rgba(0, 0, 0, 0);
        @include media-breakpoint-up(lg) {
          @include transition(none);
          left: 0;
          top: 92px;
          position: fixed;
          width: 100%;
        }
      }
      .submenu__gray-bg--mobile{
        z-index: -1;
        width: 0;
        right: 0;
        height: 100%;
        top: 66px;
        position: fixed;
        background-color: rgba(0, 0, 0, 0);
        @include transition(background-color, 0.4s, ease);
        @include media-breakpoint-up(md) {
          top: 92px;
        }
        @include media-breakpoint-up(lg) {
          display: none;
        }   
      }
      

      nav{
        height: 100%;
        background-color: #fff;
        width: 85%;
        display: flex;
        position: relative;
        z-index: 99;
        -webkit-overflow-scrolling: touch;
        @include media-breakpoint-up(sm) {
          width: 50%;   
        }
        @include media-breakpoint-up(md) {
          height: 100%; 
        }
        @include media-breakpoint-up(lg) {
          padding-bottom: 0;
          border: 0;
          width: 100%;
          height: auto;
          position: initial;
          z-index: initial;
          -webkit-overflow-scrolling: initial;   
        }
        > ul{
          background-color: #fff;
          padding-bottom: 7rem !important;  
          flex:2;
          overflow-x: auto;
          @include media-breakpoint-up(lg) {
            padding-bottom: 0 !important;
            overflow: initial;
            background-color: $artichoke_light;
          }  
        }
        a.toplink{
          display: block;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin:0;
          font-family: $title_font;
          padding: 18px 10px;
          text-align: center; 
          @include media-breakpoint-up(lg) {
            font-family: $body_font;
            font-size: 1rem;
            padding: 0;
            margin: 0 10px;
            flex-direction: column;
            justify-content: center;
            width: initial;
            min-height: 90px;
          }
          @include media-breakpoint-up(xl) {
            max-width: initial;
          }
          @include media-breakpoint-up(xxl) {
            white-space: nowrap;
          }
          [class^="icon-"]{
            margin: 0 5px 0 0;
            min-width: 45px;
            font-size: 1.7rem;
            @include media-breakpoint-up(lg) {
              display: block;
              min-width: auto;
              margin: 0 auto;
            }
          }
          
          .icon-wild{
            @include media-breakpoint-up(lg) {
              font-size: 2.1rem;
              margin-top: -5px;
            }
          }
        }
        .haschild{
          &.active{
            a.toplink{
              position: relative;
              &:before{
                @include media-breakpoint-up(lg) {
                  content:"";
                  width: 360px;
                  height: 150px;
                  @include transform(rotate(35deg));
                  position: absolute;
                  left: -45px;
                  top:80px;
                }
              }
            }
            // Gray Background - Desktop
            .submenu__gray-bg{  
              @include media-breakpoint-up(lg) {
                background-color: rgba(0, 0, 0, 0.3);  
                z-index: 20;
                height: 100vh;  
              }
            }
          }
          .trigger{
            z-index: 999;
            width: 100%;
            height: 66px;
            position: absolute;
            right: 0;  
            top: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            @include media-breakpoint-up(lg) {
              display: none; 
            }
            &::after{
                font-family: 'icomoon' !important;
                content: "\e923";
                color: $red_color;
                font-size: 1.3rem;
                @include transition(0.1s);
                height: 66px;
                width: 66px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
          }
             
          &.submenu__open, &.submenu_mission__open{
            .submenu {
              @include media-breakpoint-down(md) {
                max-height: 2500px !important;
              }
            }
            .trigger{
              &::after{
                @include transform(rotate(-180deg));
              }
            }
          }
        }
        ul{
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          @include media-breakpoint-up(lg) {
            flex-direction: row;
          }
          .submenu{
            background-color: $white;   
            overflow: hidden;
            max-height: 0px;
            @include transition(all, 0.35s, ease);

            @include media-breakpoint-up(lg) {
              display: none;
              position: absolute;
              left: 0;
              padding: 1rem 0 1.8rem 0;
              border-top: 1px solid $gray_light;
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
              max-height: initial;
              height: auto;
              width: 100%;
              z-index: 99;
            }
            &__body{
              @extend .container;
              padding-left: 0 !important;
              padding-right: 0 !important;

              @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: center;
              }
              .menu-column{
                @include media-breakpoint-up(lg) {
                  max-width: 20%;
                  flex: 2;
                }
                ul{
                  border-bottom: 1px solid #eceae7;
                  @include media-breakpoint-up(lg) {
                    margin-top: 1rem;
                    border-bottom: 0;
                  }
                }
                &:last-child{
                  ul{
                    &:last-child{
                      border-bottom: 0;
                    }
                  }
                }
              }
              ul{
                padding: 0.6rem 0;
                @include media-breakpoint-up(lg) {
                  flex-direction: column;
                  padding: 0 12px;
                  flex: 1;
                }
                li{
                  padding:4px 0 4px 60px;
                  font-size: 1rem;

                  @include media-breakpoint-up(lg) {
                    padding: 0;
                    font-size: 1rem;

                  }
                  &.submenu__title{
                    padding-left: 0;
                  }
                  a{
                    text-align: left;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    line-height: 1;
                    @include media-breakpoint-up(lg) {
                      padding: 8px 0;
                    }
                    &:before{
                      @extend .icon-angle-down;
                      font-family: icomoon;
                      @include transform(rotate(-90deg));
                      font-size: 0.9rem;
                      height: 20px;
                      width: 22px;
                      display: flex;
                      justify-content: center;
                    }
                  }
                }
              }
            }
            &__title{   
              display: flex;
              align-items: center;
              font-family: $title_font;  
              font-size: 1.2rem;
              @include media-breakpoint-up(lg) {
                padding-bottom:10px !important;
              }
              .g-title, .g-link{
                padding: 4px 0 4px 10px;
                width: 100%;
                @include media-breakpoint-up(lg) {
                  padding: 0 0 0 0 !important;
                }
              }
              .g-title{
                display: flex;
                align-items: center;
              }
              a.g-link{
                font-size: 1.1rem !important;
                color: $secondary;
                &:before{
                  content: none !important;
                }
                &:hover{
                  color: $secondary;
                  span{
                    color: $secondary !important;
                  }
                }
              }
              span.text{
                flex: 2;
                line-height: 1.2;
              }
              span[class^="icon-"]{
                width: 45px;
                max-width: 45px;
                height: 30px;
                background-size: contain;
                background-position: center;
                margin-right: 8px;
                position: relative;
                @include media-breakpoint-up(lg) {
                  width: 68px;
                  height: 36px;
                  max-width: initial;
                  background-size: cover;
                }
                &.organic{
                  &:after{
                    content: "";
                    background: url(../../../../img/bio/organic-certification.svg);
                    width: 20px;
                    height: 15px;
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-color: #95c11f;
                    @include border-radius(2px);
                    position: absolute;
                    left: 0;
                    top: 0;
                    @include media-breakpoint-up(lg) {
                      width: 28px;
                      height: 20px;
                    }
                  } 
                }
                &.icon-cat-pig{
                  @include media-breakpoint-up(lg) {
                    width: 60px;
                    background-size: contain;
                  }
                  &.organic{
                    @include media-breakpoint-up(lg) {
                      margin-left: 8px;
                    }
                    &::after{
                      @include media-breakpoint-up(lg) {
                        left: -8px;
                      }
                    }
                  }
                }
                &.icon-cat-chicken{
                  @include media-breakpoint-up(lg) {
                    width: 30px;
                  }
                  &.organic{
                    @include media-breakpoint-up(lg) {
                      margin-left: 20px;
                    }
                    &::after{
                      @include media-breakpoint-up(lg) {
                        left: -20px;
                      }
                    }
                  }
                }
                &.icon-cat-lamb{
                  @include media-breakpoint-up(lg) {
                    width: 44px;
                  }
                }
                &.icon-cat-deer{
                  @include media-breakpoint-up(lg) {
                    width: 38px;
                  }
                }
                &.icon-cat-turkey{
                  @include media-breakpoint-up(lg) {
                    width: 40px;
                  }
                }
                &.icon-cat-fish{
                  @include media-breakpoint-up(lg) {
                    width: 55px;  
                    background-size: contain;
                    background-position: center;
                  }
                }
                &.icon-gift, &.icon-grill, &.icon-meat, &.icon-christmas-tree{
                  color: $darkest_grey;
                  font-size: 1.8rem;
                  @include media-breakpoint-up(lg) {
                    width: 30px;
                  }
                }
                &.icon-cat-package{
                  background-image: url(../../../../img/animals/package-fill.svg);
                  @include media-breakpoint-down(md) {
                    background-position: center;
                    background-size: 64px;
                  }
                  @include media-breakpoint-up(lg) {
                    width: 40px;
                  }
                }
                &.icon-cat-express{ 
                  background-image: url(../../../../img/animals/express_grayscale-main.svg);
                  @include media-breakpoint-down(md) {
                    background-position: center;
                    background-size: 70px;
                  }
                  @include media-breakpoint-up(lg) {
                    width: 55px;
                  }
                }
                &.icon-cat-christmas{
                  @include media-breakpoint-up(lg) {
                    background-image: url(../../../../img/animals/christmas_grayscale-main.svg);
                    width: 40px;
                  }
                }
                &.icon-cat-easter_pack{     
                  @include media-breakpoint-up(lg) {
                    background-image: url(../../../../img/animals/easter_pack_grayscale.svg); 
                    background-size: contain;
                    width: 44px;
                  }  
                }
                &.icon-cat-heart_filled{
                  height: 24px;
                  @include media-breakpoint-up(lg) {
                    width: 36px;
                    height: 36px;
                  }
                }
                &.icon-easter-day{
                  font-size: 2.2rem;
                  color: $darkest_grey;
                  height: 30px;
                  @include media-breakpoint-up(lg) {
                    width: 36px;
                    height: 36px;
                  }
                }
                &.icon-cat-wine{
                  @include media-breakpoint-up(lg) {
                    width: 32px;
                  }
                }
                &:before {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  @include transform(translate(-50%, -50%));
                }
              }
              // category icon on mobile
              .icon-cat {
                @include media-breakpoint-down(md) {
                  @each $val in $cat-values {
                      &-#{$val} {
                          background-image: url(../../../../img/animals-icon-center/#{$val}/#{$val}_grayscale.svg);
                      }
                  }
                }
              }
            }
          }
          li[class^="top-"]{
            border-bottom: 1px solid #ECEAE7;
            @include media-breakpoint-up(lg) {
              border-bottom: 0;
              line-height: 1;
            }
            [class^=icon-]{
              @include media-breakpoint-up(lg) {
                margin-bottom: 4px;
              }
            }
            &.haschild.active{
              .submenu{
                @include media-breakpoint-up(lg) {
                  display: block;
                }
              }
              .toplink{
                @include media-breakpoint-up(lg) {
                  color: $secondary;
                }
              }
            }
            &.active{
              .submenu{
                display: block;
              }
            }
          }
        }
      }
    }

  }
}

body.menu__open {
  @include media-breakpoint-down(md) {
    height: 100vh;
    overflow: hidden;
    width: 100%;
    touch-action: none;
    -ms-touch-action: none;   
  }

  .original_menu{
    .menu__navigation_items{
      @include media-breakpoint-down(md) {
        margin: 0;
        left: 0 !important;
        z-index: 9999;
        padding: 0;   
        width: 100%;
      }
      nav{
        ul{
          li[class^="top-"]{
            @include media-breakpoint-down(md) {
              width: 100%;
              background-color: #fff;
              margin: 0;
              position: relative;
              display: initial;
            }
          }
          .submenu{
            &__body{
              max-width: 100% !important; 
            }
          }
        }
      }
      // Gray Backgound - mobile
      .submenu__gray-bg--mobile{
        background-color: rgba(0, 0, 0, 0.5) !important;
        width: 100% !important;
        @include media-breakpoint-up(lg) {
          display: none;  
        }  
      }
    }
  }


  .package__order{
    display: none;
  }
}

.mainmenu-icon-farmers{
  .toplink{
    &.active{   
      color: $primary_color !important;     
      &:hover{
        color: $secondary_color !important;   
      }
    }
  }
  .submenu__body{
    .submenu__title{
      display: none !important;
    }
    ul{
      @include media-breakpoint-up(lg) {
        display: flex;
        min-width: 600px;
        flex-wrap: wrap;
        flex-direction: row !important;
      }
      li{
        @include media-breakpoint-up(lg) {
          width: 33.33%;
        }
      }
    }
  }
}

@keyframes bounce {
  0%, 100% {
    opacity:0.6;
    width: 10px;
    height: 10px;
  }
  60% {
    opacity:0.0;
    width: 3px;
    height: 3px;
  }
}

// Menu A/B Test ---------------------
.header__top .ab_test_menu{
  .header__logo{
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .menu__hamburger_holder{
    flex: 2;
  }
  .header__account{
    flex: 2;
  }
  .header__menu{
    display: flex;
    align-items: center;
  }

  .menu__navigation_items{
    left: -100%;   
    position: fixed;
    top: 66px;
    height:calc(100% - 66px);
    @include transition(all, 0.2s, ease); 
    background: rgba(0, 0, 0, 0);
    @include media-breakpoint-up(md) {
      top: 92px;
      height:calc(100% - 92px); 
    }
    @include media-breakpoint-up(lg) {
      display: block;
      margin-top: 0;
      left: -100% !important;
      position: fixed;
      top: 92px;
      height:calc(100% - 92px);
    }

    // Gray Background
    .submenu__gray-bg{
      background-color: rgba(0, 0, 0, 0);
      @include media-breakpoint-up(lg) {
        @include transition(none);
        left: 0;
        top: 92px;
        position: fixed;
        width: 100%;
      }
    }

    nav{
      flex-direction: column;
      justify-content: space-between;
      overflow-x: auto;

      ul{
        flex: flex;
        padding-bottom: 0 !important;
        background-color: #fff;
        overflow-x: initial;
        flex: initial;
        @include media-breakpoint-up(lg) {
          flex-direction: column;
        }
      }
      a.toplink{ 
        font-size: 1rem;
        @include media-breakpoint-up(lg) {
          font-family: $title_font;
          padding: 18px 10px;
          margin: 0;
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;
          min-height: initial;
        }
        @include media-breakpoint-up(xl) {
          max-width: initial;
        }
        @include media-breakpoint-up(xxl) {
          white-space: nowrap;
        }
        [class^="icon-"]{
          margin: 0 5px 0 0;
          min-width: 45px;
          font-size: 1.7rem;
          @include media-breakpoint-up(lg) {
            display: block;
            min-width: 45px;
            margin: 0 5px 0 0;
          }
        }
        
        .icon-wild{
          @include media-breakpoint-up(lg) {
            font-size: 2.1rem;
            margin-top: -5px;
          }
        }
      }
      .haschild{
        &.active{
          a.toplink{
            position: relative;
            &:before{
              @include media-breakpoint-up(lg) {
                content:"";
                width: 360px;
                height: 150px;
                @include transform(rotate(35deg));
                position: absolute;
                left: -45px;
                top:80px;
              }
            }
          }
          // Gray Background - Desktop
          .submenu__gray-bg{  
            @include media-breakpoint-up(lg) {
              background-color: rgba(0, 0, 0, 0.3);  
              z-index: 20;
              height: 100vh;  
            }
          }
        }
        .trigger{
          @include media-breakpoint-up(lg) {
            display: flex; 
          }
        }

        .submenu{
          position: initial !important;
          padding: 0 0 0 2rem;
          border-top: 1px solid #F4F4F4;
        
          &__body{
            max-width: 100% !important;
            flex-direction: column;

            > .menu-column{
              background-color: red;
            } 
            .menu-column{
              max-width: 100%;
              ul{
                padding: 0.4rem 0 !important;
                margin: 0;
                border-bottom: 1px solid #eceae7;

                .submenu__title{
                  padding-bottom: 0 !important;
                }
              }
              li{
                padding-left: 60px;
                &.submenu__title{
                  padding-left: 0;
                }
              }
              
            }
          }
          &__title{
            display: flex;
            align-items: center;
            font-family: $title_font;  
            font-size: 1.2rem;
            @include media-breakpoint-up(lg) {
              padding-bottom:10px !important;
            }
            .g-title, .g-link{
              padding: 4px 0 4px 10px;
              width: 100%;
              @include media-breakpoint-up(lg) {
                padding: 4px 0 4px 10px !important;
              }
            }
            .g-title{
              display: flex;
              align-items: center;
            }
            a.g-link{
              font-size: 1.1rem !important;
              color: $secondary;
              &:before{
                content: none !important;
              }
              &:hover{
                color: $secondary;
                span{
                  color: $secondary !important;
                }
              }
            }
            span.text{
              flex: 2;
              line-height: 1.2;
            }
            span[class^="icon-"]{
              width: 45px;
              max-width: 45px;
              height: 30px;
              background-size: contain;
              background-position: center;
              margin-right: 8px;
              position: relative;
              @include media-breakpoint-up(lg) {
                width: 45px;
                max-width: 45px;
                height: 30px;
                background-size: contain;
              }
              &.organic{
                &:after{
                  content: "";
                  background: url(../../../../img/bio/organic-certification.svg);
                  width: 20px;
                  height: 15px;
                  background-size: contain;
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-color: #95c11f;
                  @include border-radius(2px);
                  position: absolute;
                  left: 0;
                  top: 0;
                  @include media-breakpoint-up(lg) {
                    width: 20px;
                    height: 15px;
                  }
                } 
              }
              &.icon-cat-pig{
                @include media-breakpoint-up(lg) {
                  width: 60px;
                  background-size: contain;
                }
                &.organic{
                  @include media-breakpoint-up(lg) {
                    margin-left: 8px;
                  }
                  &::after{
                    @include media-breakpoint-up(lg) {
                      left: -8px;
                    }
                  }
                }
              }
              &.icon-cat-chicken{
                @include media-breakpoint-up(lg) {
                  width: 45px;
                }
              }
              &.icon-cat-lamb{
                @include media-breakpoint-up(lg) {
                  width: 45px;
                }
              }
              &.icon-cat-deer{
                @include media-breakpoint-up(lg) {
                  width: 45px;
                }
              }
              &.icon-cat-turkey{
                @include media-breakpoint-up(lg) {
                  width: 45px;
                }
              }
              &.icon-cat-fish{
                @include media-breakpoint-up(lg) {
                  width: 45px;  
                  background-size: contain;
                  background-position: center;
                }
              }
              &.icon-gift, &.icon-grill, &.icon-meat, &.icon-christmas-tree{
                color: $darkest_grey;
                font-size: 1.8rem;
                @include media-breakpoint-up(lg) {
                  width: 45px;
                }
              }
              &.icon-cat-package{
                background-image: url(../../../../img/animals/package-fill.svg);
                @include media-breakpoint-down(md) {
                  background-position: center;
                  background-size: 64px;
                }
                @include media-breakpoint-up(lg) {
                  width: 45px;
                }
              }
              &.icon-cat-express{ 
                background-image: url(../../../../img/animals/express_grayscale-main.svg);
                @include media-breakpoint-down(md) {
                  background-position: center;
                  background-size: 70px;
                }
                @include media-breakpoint-up(lg) {
                  width: 45px;
                }
              }
              &.icon-cat-christmas{
                @include media-breakpoint-up(lg) {
                  background-image: url(../../../../img/animals/christmas_grayscale-main.svg);
                  width: 45px;
                }
              }
              &.icon-cat-easter_pack{     
                @include media-breakpoint-up(lg) {
                  background-image: url(../../../../img/animals/easter_pack_grayscale.svg); 
                  background-size: contain;
                  width: 44px;
                }  
              }
              &.icon-cat-heart_filled{
                height: 24px;
                @include media-breakpoint-up(lg) {
                  width: 45px;
                  height: 30px;
                }
              }
              &.icon-easter-day{
                font-size: 2.2rem;
                color: $darkest_grey;
                height: 30px;
                @include media-breakpoint-up(lg) {
                  width: 36px;
                  height: 36px;
                }
              }
              &.icon-cat-wine{
                @include media-breakpoint-up(lg) {
                  width: 32px;
                }
              }
              &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
              }
            }
            // category icon on mobile
            .icon-cat {
                @each $val in $cat-values {
                    &-#{$val} {
                        background-image: url(../../../../img/animals-icon-center/#{$val}/#{$val}_grayscale.svg);
                    }
                }
            }
          }
        }
           
        &.submenu__open, &.submenu_mission__open{
          .submenu {
            max-height: 2500px !important;
          }
        }
      }


      ul.second-menu{
        padding:1rem 0.8rem;
        margin-bottom: 1.5rem;
        li{
          a{
            display: block;
            padding:0.2rem 0.4rem;
          }
        }
      }
    }
  }
}
body.menu__open {
  overflow: hidden;
  .ab_test_menu{
    .menu__navigation_items{
      margin: 0;
      left: 0 !important;
      z-index: 9999;
      padding: 0;   
      width: 100%;
      
      nav{
        @include media-breakpoint-up(lg) {
          background-color: #fff;
          padding-bottom: 0;
          border: 0;
          width: 40%;
          max-width: 400px;
          height: 100%;
          position: relative;
          z-index: 30;
          -webkit-overflow-scrolling: initial;   
        }
        ul{
          li[class^="top-"]{
            width: 100%;
            background-color: #fff;
            margin: 0;
            position: relative;
            display: initial;
            border-bottom: 1px solid #ECEAE7;
            
          }

          .haschild{
            &.submenu__open{
              max-height: 2500px !important;

              .submenu{
                display: flex;
              }
            }
          }
        }
      }
      // Gray Backgound - mobile
      .submenu__gray-bg--mobile{
        background-color: rgba(0, 0, 0, 0.5) !important;
        width: 100% !important;
        display: block;
      }
    }
  }
}