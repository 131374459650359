.data-filter {
  width: 100%;
  margin-bottom: 40px;
  margin-top: calc($grid-gutter-width /2);
  nav {
    display: flex;
    position: relative;
    overflow-x:  scroll;
    @include media-breakpoint-up(lg) {
      overflow-x:initial;
    }
    ul {
      list-style-type: none;
      display: flex;
      margin:0;
      padding: 0;
      padding-right: 4px;
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: 100%;
        padding: 0;
      }
      li {
        display: flex;
        background-color: $vanilla_lightest;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-basis: 0;
        flex-grow: 1;
        flex: 1 1 auto;
        padding: 4px 1rem;
        &.data-filter__item {
          margin: 0 2px;
          &.filter__item-text {
            padding: 0 8px;
            line-height: 1.1;
          }
          &:first-child{
            margin-left:0;
          }
          &:last-child{
            margin-right:0;
          }
        }
        &.active, &:hover{
          background-color: $artichoke_dark;
          color: $white;
          [class^=icon-] {
            filter: invert(1) brightness(2);
          }
        }
        &.active {
          color: $white;
          pointer-events: none;
        }
        &:hover {
          pointer-events: auto;
          cursor: pointer;
          @include media-breakpoint-up(lg) {
            color: $white;
          }
        }
        i {
          background-size: contain;
          width: 42px;
          height: 30px;

          &.icon-cat {
            @each $val in $cat-values {
              &-#{$val} {
                background-image: url(../../../../img/animals-icon/#{$val}/#{$val}_grayscale.svg);
              }
            }
          }
        }
      }
    }
  }
  &.search{
    display: flex;
    justify-content: space-between;
    & nav{
      width:100%;
      @include transition(all, 0.2s, ease);
    }
    .nav_holder{
      width:calc(100% - 50px);
      @include transition(all, 0.2s, ease);
      margin-right: 8px;
      @include media-breakpoint-up(md) {
        width:calc(100% - 70px);
      }
    }
  }
  &__search{
    width: 40px;
    border: 1px solid $artichoke_regular;
    @include media-breakpoint-up(md) {
      min-width: 62px;
    }
    background-color: #fff;
    @include transition(all, 0.2s, ease);
    overflow: hidden;
    .action{
      display: flex;
      justify-content: center;
      align-items: center;
      float: right;
      cursor: pointer;
      width: 40px;
      height: 100%;
      @include media-breakpoint-up(md) {
        width: 60px;
      }
    }
    .form{
      width:calc(100% - 72px);
      height: 100%;
      overflow: hidden;
      position: relative;
      &:before{
        position: absolute;
        font-family: 'icomoon' !important;
        @extend .icon-magnify;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        left: 8px;
        top:50%;
        margin-top: -12px;
      }
      input{
        height: 100%;
        width: 100%;
        border: 0;
        outline: 0;
        padding: 0 10px 0 40px;
      }
    }
  }
  &.open{
    .action{
      i{
        @extend .icon-close;
        color:$red_color;
      }
    }
    .data-filter__search{
      width: 100%;
      margin-left: 0;
    }
    .nav_holder{
      width: 0;
      overflow: hidden;
      flex: inherit;
      margin: 0;
      opacity: 0;
    }
  }
  &--noicon{
    .data-filter__list{
      [class^=icon-]{
        display: none;
      }
      li{
        min-height: 60px;
      }
      a{
        color: $darkest_grey;
      }
    }
  }
  &.data-filter--white{
    li{
      background-color: #fff;
    }
  }
}

.data-filter{
  margin-left: auto;
  margin-right: auto;
  flex: auto !important;
  &.item4{
    @extend .col-xxl-6;
    @extend .col-xl-8;
  }
  &.item8{
    @extend .col-xxl-8;
    @extend .col-xl-12;
  }
  &.item10{
    @extend .col-xxl-12;
    @extend .col-xl-12;
  }
}
.nav_holder{
  &.scroll{
    nav{
      overflow-x:scroll !important;
    }
  }
}
.filter-ref{
  padding-top: 90px;
  margin-top: -90px;
  z-index: -1;
  position: relative;
  @include media-breakpoint-up(md) {
    padding-top: 120px;
    margin-top: -120px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 20px;
    margin-top: -20px;
  }
}