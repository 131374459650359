.new-design{
    // Common
    .new-design-block{
        display: block !important;
    }
    .new-design-none{
        display: none !important;
    }

    // All HomePage Title -----------
    .module__recipe-highlight{
        .module__title{
            h3.heading{
                @extend .heading--h2;
            }
        }
    }
    .module__product-highlight, .module__blog_highlight{
        .module__title{
            .heading{
                // @extend .heading-line;
                color: $darkest_grey;
                @extend .heading--h2;
            }
        }
    }

    // 3- fav-boxen-row
    .fav-boxen-row{
        .container{
            @include media-breakpoint-down(sm) {
                max-width: 100%;
            }
        }
        .sub-heading{
            font-size: 1.5rem;
            line-height: 1;
            color: $red;
        }
        .steps{
            border-top: 1px solid $artichoke_regular;
            border-bottom: 1px solid $artichoke_regular;
            margin: 2rem 0.4rem;
            display: flex;
            flex-wrap: wrap;

            .itm{
                width: 100%;
                display: flex;
                text-align: left;
                padding: 0.8rem 1rem;
                @include media-breakpoint-up(lg) {
                    width: 25%;
                }

                &:after{
                    font-family: 'icomoon';
                    margin-left: 8px;
                    font-size: 3rem;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    margin-top: 20px;
                    @include transform(rotate(-90deg));
                    @include media-breakpoint-up(lg) {
                        content: "\e923";
                    }
                }
                &:last-child{
                    &::after{
                        content: none;
                    }
                }

                .num{
                    font-size: 4rem;
                    margin-right: 8px;
                    line-height: 1;
                }

                .txt{
                    flex: 2;
                    h3, p{
                        margin: 0;
                        font-size: 1rem;
                        line-height: 1.2;
                    }
                    h3, .heading{
                        margin-bottom: 0.5rem;
                        margin-top: 0.8rem;
                        font-size: 1.1rem;
                        font-family: $title_font;
                        line-height: 1.2;
                    }
                }
            }
        }
    }


    .new-des__spec{
        h4, p{
            line-height: 1;
        }
        h4{
            color: $red;
            font-size: 1.2rem;
        }
        p{
            font-size: 1.1rem;
        }
    }
    
    .module__recipe-highlight{
        .heading{
            // @extend .heading-line;
            @extend .color--darkest-grey;
        }
    }
    .module__newsletter{
        .heading--h2{
            // @extend .heading-line;
            @extend .color--darkest-grey;
        }
        &.newsletter__text-top{
            .body{
                padding-bottom: 0 !important;
            }
        }
    }
}


.new-des__reviews{
    .container{
        @include media-breakpoint-down(lg) {
            max-width: 100%;
        }
    }
    .nd-review-iteams{
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        padding-bottom: 1rem;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
            overflow-x: initial;
            padding-bottom: 0;
        }

        > div{
            min-width: 210px;
            @include media-breakpoint-up(sm) {
                min-width: 220px;
            }
            @include media-breakpoint-up(lg) {
                min-width: initial;
            }
        }
    }
    .nd-review, .nd-review-main{
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
            margin-bottom: 1.5rem;
            width: 20%;
        }
    }
    .nd-review{
        order: 4;
        .nd-review-user{
            padding-bottom: 8px;
            border-bottom: 1px solid $artichoke_light;
            font-family: $Brown-Bold;
            display: flex;
            align-items: center;

            &::before{
                content: " ";
                display: block;
                min-width: 24px;
                min-height: 24px;
                margin-right: 8px;
                background-size: contain;
                .nl &{
                    background-image: url(../../../../img/reviews/google-review-logo.svg);
                }
                .de &{
                    background-image: url(../../../../img/reviews/trustedshops.svg);
                }
            }
        }
        .nd-review-body{
            padding-top: 6px;
        }
        .nd-review-name{
            padding-top: 0.8rem;
            font-size: 0.8rem;
        }

        &:nth-child(2){
            @include media-breakpoint-up(lg) {
                order: 1;
            }
        }
        &:nth-child(3){
            @include media-breakpoint-up(lg) {
                order: 2;
            }
        }
    }
    .nd-review-main{
        text-align: center;
        align-items: center;
        @include media-breakpoint-up(lg) {
            order: 3;
        }
        img{
            display: block;
            margin: 0 auto;
            max-width: 160px;
            margin-bottom: 10px;
        }
        h4, .sub-title{
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
            font-family: $title_font;
            @include media-breakpoint-up(md) {
                margin-bottom: 3rem;
                margin-top: 0;
            }
        }
        a{
            color: $red;
        }
    }
}

.reviews_v2{
    .container{
        @include media-breakpoint-down(lg) {
            max-width: 100%;
        }
    }
    .nd-review-iteams{
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        padding-bottom: 1rem;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
            overflow-x: initial;
            padding-bottom: 0;
        }

        > div{
            min-width: 260px;
            @include media-breakpoint-up(sm) {
                min-width: 220px;
            }
            @include media-breakpoint-up(lg) {
                min-width: initial;
            }
        }
    }
    .nd-review{
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
            margin-bottom: 1.5rem;
            width: 25%;
        }
    }
    .nd-review{
        order: 4;
        .nd-review-user{
            padding-bottom: 8px;
            border-bottom: 1px solid $artichoke_light;
            font-family: $Brown-Bold;
            display: flex;
            align-items: center;

            &::before{
                content: " ";
                display: block;
                min-width: 24px;
                min-height: 24px;
                margin-right: 8px;
                background-size: contain;
                .nl &{
                    background-image: url(../../../../img/reviews/google-review-logo.svg);
                }
                .de &{
                    background-image: url(../../../../img/reviews/trustedshops.svg);
                }
            }
        }
        .nd-review-body{
            padding-top: 6px;
        }
        .nd-review-name{
            padding-top: 0.8rem;
            font-size: 0.8rem;
        }
    }
    .nd-review-main{
        text-align: center;
        align-items: center;
        img{
            display: block;
            margin: 0 auto;
            max-width: 90px;
            margin-bottom: 8px;
            @include media-breakpoint-up(lg) {
                max-width: 120px;
            }
        }
        a{
            color: $black;
        }
        .desc{
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            justify-content: center;
            font-size: 0.9rem;
            @include media-breakpoint-up(lg) {
                font-size: 1rem;
            }
        }
    }
}