.loader{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  svg{
    width: 130px;
    height: 130px;
  }
  #shadow{
    display: none;
  }
  #horn{
    animation: horn 3s infinite;
    transform-origin: center center;
  }
  #body{
    animation: bodyz 3s infinite;
    transform-origin: center center;
  }
  #line{
    animation: linz 3s infinite;
    transform-origin: center center;
  }
}
@keyframes horn{
  0%{
    transform: rotate(0);
  }
  33%{
    transform: rotate(4deg);
  }
  66%{
    transform: rotate(-4deg);
  }
  100%{
    transform: rotate(0);
  }
}
@keyframes bodyz{
  0%{
    transform: rotate(0);
  }
  33%{
    transform: rotate(-2deg);
  }
  66%{
    transform: rotate(2deg);
  }
  100%{
    transform: rotate(0);
  }
}
@keyframes linz{
  0%{
    transform: rotate(0);
  }
  33%{
    transform: rotate(-3deg);
  }
  66%{
    transform: rotate(3deg);
  }
  100%{
    transform: rotate(0);
  }
}