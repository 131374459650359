.modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after{
      position: fixed;
      content: '';
      left: 0;
      top: 0;
      z-index: 199;
      width: 100%;
      height: 100%;
      background-color: #00000085;
      opacity: 0;
      transition: all 0.5s linear;
      @include transition(all, 0.5s, linear);
    }
    &.show{ //show modal
      position: fixed;
      opacity: 1;
      padding: 30px;
      z-index: 299;
      &::after{
        opacity: 1;  
      }
      > .modal__body{
        z-index: 299;
      }
    }

    // modal design
    &__body{
        background-color: #fff;
        @include border-radius(8px);
        max-width: 580px;
        padding: 30px;
        position: relative;
        text-align: center;
    }
    &__icon{
        [class^="icon-"]{
            display: block;
            margin: 0 auto;
            width: 55px;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $gray_light;
            @include border-radius(50%);
            color: #fff;
            font-size: 1.5rem;

            &.green{
                background-color: $artichoke_dark;
            }
            &.blue{
                background-color: $primary_color;
            }
            &.red{
                background-color: $red_color;
            }
        }

    }
    &__title{
        margin: 18px 0;
        font-family: $title_font;
        font-size: 1rem; //16px
        line-height: 1.1;
        @include media-breakpoint-up(md) {
          font-size: 1.125rem; //18px
        }
        @include media-breakpoint-up(lg) {
          font-size: 1.25rem; //20px
        }
        &.blue{
            color: $primary_color;
        }
        &.red{
            color: $red_color;
        }
        &.green{
            color: $green_color;
        }
    }
    &__text{
        margin: 18px 0;
    }
    &__action{
        margin-top: 2rem;
        .btn{
            padding-right:50px;
            padding-left:50px;
            text-align: center;
            justify-content: center;

            width: 90%;
            @include media-breakpoint-up(sm) {
                width: auto;
            }
        }
    }

}