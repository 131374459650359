.tooltip {
  font-size: 0.9rem;
  text-align: center;
  z-index: 20;
  position: relative;
  margin: 0 auto;
  
  // 1- Tooltip Structure --> Avatar, Title, Popup
  .tooltip__info {
    position: relative;
    width: 70px;
    margin: 0 auto;
    .avatar {
      width: 40px;
      height: 40px;
      margin: 0 auto 6px auto;
      &:before {
        content: '';
        background: $vanilla_light;
        width: 10px;
        height: 10px;
        @include transform(rotate(45deg) translate(50%, 0));
        position: absolute;
        top: -24px;
        left: 50%;
        margin-left: -8px;
        display: none;
      }
      img {
        @include border-radius(40px);
        border: 2px solid transparent;
        object-fit: cover;
        width: 100%;
        height: 100%;
        @include transition(all, 0.2s, linear);
        cursor: pointer;
      }
      [class^="icon-"] {
        @extend img;
        display: block;
        padding: 2px;
        overflow: hidden;
        font-size: 32px;
        color: $gray-base;
        background-color: $gray-light;
      }
      .icon-breed:before{
        content:none !important;
      }
    }
  }

  // 2- Title, Name
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    cursor: pointer;
    [class^="icon-"] {
      margin-right:2px;
      margin-left: 0;
      color:#999999;
    }
    .title{
      height: 33px; 
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; 
      padding-top: 1px;
      font-size: 0.8rem;
      .gray{
        color:#999999;
      }
    }
  }

  // 3- Basic Popup Style 
  div.tooltip__content {
    text-align: left;
    background: $vanilla_light;
    @include border-radius($border-radius);
    padding: #{$grid-gutter-width*2};
    position: absolute;
    top: -#{$grid-gutter-width*2};
    @include transform(translateY(-100%));
    width: 245px;
    &:after {
      content: '';
      background: transparent;
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: -20px;
      left: 0;
      display: block;
      z-index: -1; 
    }
  }
  &--left{
    .tooltip__content{
      left: 0;
      @include media-breakpoint-up(lg) {
        left: -25px;   
      }
    }
  }
  &--right{
    .tooltip__content{
      right: 0;
    }
  }
  &--center{
    .tooltip__content{
      left: -50%;
      margin-left: -50px !important;
    }
  }

  // 4- Design of Popup Content
  .tooltip__content {
    display: none;
    &--icon {
      align-self: center;
      display: flex;
      padding-bottom: $grid-gutter-width;
    }
    &--info {
      [class^="icon-"] {
        font-size: 2.5rem;
        padding-right: #{$grid-gutter-width *2};
      }
      ul {
        padding: 0;
        margin: 0;
        display: inline-block;
        li {
          display: inline-block;
          line-height: 1.2;
          margin-bottom: 5px;
          &.farmers--name{
            margin-bottom: 10px;
            font-family: $title_font;
            width: 100%;
          }
        }
      }
      .heading{
        font-family: $title_font;
        margin: 0;
      }
      span{
        display: block;
      }
    }
    .body, .desc{
      margin: #{$grid-gutter-width * 1} 0;
    }
    .desc{
      padding-top: #{$grid-gutter-width * 1};
      border-top: 1px solid rgba(0,0,0,0.1);
    }
    .icon-close{
      cursor: pointer;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  // -- breed popup
  &__breed{  
    img{
      @include border-radius($border-radius);
      margin-bottom: 12px;
      width: 100%;
    }
    .breed--title{
      font-family: $title_font;
      margin-bottom: 6px;
      a{
          color: $darkest_grey !important;
      }
    }
  }

  &:hover{
    .tooltip__content{
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
    .tooltip__info {
      .avatar {
        &:before{
          @include media-breakpoint-up(lg) {
            display: block;
          }
        }
        img {
          @include media-breakpoint-up(lg) {
            border: 2px solid $vanilla_light;
            color: #949494;
          }
        }
      }
    }
  }


  // custom style
  &.farmer{
    .avatar{
      span{
        padding-top: 8px !important;
      }
    }
    .title.long-name{
      word-break: break-all;  
    }
  }
  &.breed{
    .title.long-name{
      word-break: break-all;
    }
    .icon-breed{
      background-size: 85% 85%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }


  // Remove popups in product card on mobile
  &__content{
    @include media-breakpoint-down(md) {
      display:none !important;
    }
  }
  .avatar{
    &::before{
      @include media-breakpoint-down(md) {
        content: initial !important;
      }
    }
    [class^="icon-"], img{
      @include media-breakpoint-down(md) {
        border-color: transparent !important;
        cursor: auto !important;
      }
    }
  }
  &__title{
    @include media-breakpoint-down(md) {
      cursor: auto !important;
    }
  }  
}

// breed icons - based on animal type
.package-{
  @each $val in $cat-values {
      &-#{$val}{
        .icon-breed{
          background-image: url(../../../../img/animals-icon-center/#{$val}/#{$val}_grayscale.svg);
        }
      }
  }
}