// ----
// FlexBox position helper
// ----
.pin{
  &-top{
    &-left{
      justify-content: flex-start;
      align-items: flex-start;
    }
    &-center{
      justify-content: flex-start;
      align-items: center;
    }
    &-right{
      justify-content: flex-start;
      align-items: flex-end;
    } 
  }
  &-middle{
    &-left{
      justify-content: center;
      align-items: flex-start;
    }
    &-center{
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    &-right{
      justify-content: center;
      align-items: flex-end;
    } 
  }
  &-bottom{
    &-left{
      justify-content: flex-end;
      align-items: flex-start;
    }
    &-center{
      justify-content: flex-end;
      align-items: center;
    }
    &-right{
      justify-content: flex-end;
      align-items: flex-end;
    } 
  }
}