@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms; 
}

@mixin border-radius($top-left:10px, $top-right:null, $bottom-right:null, $bottom-left:null) {
    -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
    -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
    -ms-border-radius: $top-left $top-right $bottom-right $bottom-left;
    border-radius: $top-left $top-right $bottom-right $bottom-left;
}

@mixin transition($property: all, $duration: .3s, $ease: linear) {
    -webkit-transition: $property $duration $ease;
    -moz-transition: $property $duration $ease;
    -o-transition: $property $duration $ease;
    transition: $property $duration $ease;
}

@mixin box-shadow($left, $top, $radius, $color) {
    box-shadow: $left $top $radius $color;
    -webkit-box-shadow: $left $top $radius $color;
    -moz-box-shadow: $left $top $radius $color;
}

@mixin text-shadow($left, $top, $radius, $color) {
    text-shadow: $left $top $radius $color;
    -webkit-text-shadow: $left $top $radius $color;
    -moz-text-shadow: $left $top $radius $color;
}

@mixin gradient($color1, $color2) {
    background-color: $color1;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$color1}, endColorstr=#{$color2});
    background-image: -moz-linear-gradient(center top, $color1, $color2);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($color1), to($color2));
}

// Responsive alignment
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}