/* ---------- List Item Style ---------- */
.bundle-item{
  background-color: $black;
  width: calc(100% - 10px);
  margin: 5px;
  position: relative;
  height: 125px;
  cursor: pointer;
  @include media-breakpoint-up(md){
      width: calc(33.33% - 20px);
      margin: 10px;
      height: 150px;
  }
  .body{
      padding: 1rem;
      position: relative;
      z-index: 1;
      height: 100%;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
  }
  .heading{
      color: #fff;
      line-height: 1;
      margin-bottom: 1rem;
      .title{
          font-family: $title_font;
          font-size: 1.4rem;
          text-shadow: rgba(0, 0, 0, 0.4) 1px 1px 0px;
      }
  }
  .btn{
      font-size: 0.8rem;
  }
  .img-holder{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      picture{
          width: 100%;
      }
      img{
          object-fit: cover;
          width: 100%;
          height: 100%;
      }
  }
}


/* ---------- Popup Style ---------- */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: flex-end;
    z-index: 99990;
    @include media-breakpoint-up(sm) {
      align-items: center;
    }

}
.popup.show {
  display: flex;
}
.popup{
  &-content{
    background: $vanilla_lightest;
    position: relative;
    overflow: hidden;
    width: 100%;
    @include media-breakpoint-up(sm) {
      max-width: 560px;
      @include border-radius(6px);
    }
  }
  &-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #fff;
    z-index: 1;
    @include box-shadow(0px, 4px, 8px, rgb(0 0 0 / 10%));
    position: relative;

    .title{
      font-family: $title_font;
      font-size: 1.2rem;
      @include media-breakpoint-up(sm) {
        font-size: 1.4rem;
      }
    }
    .close-btn {
      background: none;
      border: none;
      font-size: 1rem;
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Notification ------------------
.response-popup{
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  background-color: #fff;
  @include border-radius(5px);
  border: 1px solid;
  display: flex;
  align-items: center;
  line-height: 1.2;
  padding: 10px;
  z-index: 1051;

  &:before{
      font-family: 'icomoon';
      font-size:1.5rem;
      margin-right: 10px;
  }

  &.success, &#mce-success-response{
      border-color:$artichoke-dark;
      color:$artichoke-dark;
      &:before{
        content: "\e930";
      }
  }
  &.error{
      border-color:$red_color;
      color:$red_color;
      &:before{
        content: "\e92f";
      }
  }
  &.warning{
      border-color:$caramel_color;
      color:#666666;
      &:before{
        content: "\e90a";
        color:$caramel_color;
      }
  }
}


/* ---------- Farmshop bundles Style ---------- */
.bundles{
  &__popup-item{
    background-color: #fff;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    margin-bottom: 4px;

    .img{
      min-width: 100px;
      img{
        width: 100px;
        height: 80px;
        object-fit: cover;
      }
    }
    .item-info{
      display: flex;
      flex:2;
      column-gap: 1rem;
      flex-direction: column;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
    }
    .body{
      display: flex;
      flex-direction: column;
      flex: 2;

      .farmer{
        display: flex;
        align-items: center;
        width: 160px;
        margin-bottom: 0.5rem;
        &_image{
          margin-right: 0.5rem;
          img{
            width: 24px;
            height: 24px;
            @include border-radius(50px);
          }
        }
        &_name{
          font-size: 0.8rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .action{
      min-width: 120px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include media-breakpoint-up(sm) {
        align-items: flex-end;
      }
      .farmshop__item__info{
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        .price{
          color: $black;
        }
        .sep, .weight{
          color: $primary;
          font-size: 0.9rem;
        }
      }
      .quantity{
        width: 120px !important;
        font-size: 0.8rem;
        overflow: hidden;
        @include media-breakpoint-up(sm) {
          width: initial;
        }
      }
    }
  }
  &-body{
    padding: 1rem 1rem;
    max-height: 360px;
    overflow-y: scroll;
    position: relative;
    @include media-breakpoint-up(sm) {
      max-height: 400px;
      padding: 1rem 1.2rem;
    }
    &.loading{
      overflow-y: hidden;
      height: 400px;
      @include media-breakpoint-up(sm) {
        height: 400px;
      }
      .desc, .bundles-list{
        filter: blur(2px);
      }
    }
  }
  &-loading{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(../../../../../img/loading/loader-2.svg);
    background-repeat: no-repeat;
    background-position: center;
    @include transition(all, 0.4s, linear);
  }
  &-footer{
    padding: 1rem;
    background-color: #fff;
    position: relative;
    @include box-shadow(4px, 0px, 8px, rgb(0 0 0 / 10%));

    .btn{
      width: 100%;
      justify-content: center;
      font-size: 0.9rem;

      i{
        margin-right: 0.4rem;
      }
    }
  }
}