.hero{
    &__section{
        height:250px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        padding-bottom: 16px;
        padding-top: 16px;
        @include media-breakpoint-up(md) {
            height:330px;
        }
        @include media-breakpoint-up(lg) {
            height:45vh;
        }
        .container, .row, .col-12{
            height: 100%;
            @include media-breakpoint-up(lg) {
                height: 100%; 
            }
        }
    }
    &__img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top:0;
        z-index: -1;

        img{
            display: block;
            height: 250px;
            width: 100%;
            object-fit: cover;

            @include media-breakpoint-up(md) {
                height:350px;
            }
            @include media-breakpoint-up(lg) {
                height:550px;
            }
        }
    }
    &__content{
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(lg) {
            padding-top: #{$grid-gutter-width * 2};
        }
        .svg-headline{
            svg{
                width: 100%;
                height: auto;
                margin-top: 20px;
                @include media-breakpoint-up(md) {
                    width: auto;
                    margin-top: 0;
                }
            }
        }
        &[class*="pin-"]{
            svg{
                margin: 0;
            }
        }
        h1{
            &.golden--title{
                margin: 0.5rem 0;
                font-size: 8vw;
                @include media-breakpoint-up(md) {
                    font-size: 3.75rem;
                    margin-top: 0 !important;
                }
            }
            &.text--xl{
                font-size: 3.5rem;
                @include media-breakpoint-up(md) {
                    font-size: 5.25rem;
                }
            }
        }
    }
    &__action{
        position: absolute;
        width: 100%;
        bottom: 16px;
        left: 0;
        @include media-breakpoint-up(lg) {
            display: none;
        }
        &.position--top{
            bottom: initial;
            top:16px;
        }
    }
    &--video{
        padding: 0;
        iframe{
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    &__video{
        
        iframe{
            display: block;
            width: 100%;
            height: 200px;
            @include media-breakpoint-up(sm) {
                height: 300px;
            }
            @include media-breakpoint-up(md) {
                height: 400px;
            }
            @include media-breakpoint-up(lg) {
                height: 550px;
            }
            @include media-breakpoint-up(xxl) {
                height: 660px;
            }
        }
    }
}

// Home ROW icons
.box__icon{
    display: flex;
    justify-content: center;
    padding: 20px;
    img{
        height: 75px;
    }
}

// Press Logo Row
.press__row{
    padding: 40px 0;
    .heading {
        margin-bottom: 10px;
    }
    .link{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 5px;
        @include transition(all, 0.2s, linear);
        @include media-breakpoint-up(lg) {
            padding: 20px;
        }
        img{
            width: 90%;
        }
        &:hover{
            filter: grayscale(1);
        }
    }

    .container{
        @include media-breakpoint-down(md) {
            padding: 0 10px;
        }
        > .row{
            @include media-breakpoint-down(md) {
                overflow-x: auto;
                padding-left: 2px;
                padding-right: 2px;
                padding-bottom: 10px;

                display: grid;
                grid-template-rows: repeat(2, 100px);
                gap: 4px;
                grid-auto-flow: column;
                grid-auto-columns: 100px 120px;
            }
            > div {
                @include media-breakpoint-down(md) {
                    flex: 0 0 auto;
                    max-width: 100%;
                }
                // item width
                @include media-breakpoint-down(md) {
                    width: 120px;
                    height: 100px;
                }
            }
        }
    }
}