.pagination{
    display: flex;
    list-style: none;
    justify-content: center;
    margin: 20px auto;
    padding: 0;

    li{
        padding:6px;
        &.number{
          display: none;
          @include media-breakpoint-up(lg) {
            display: flex;
          }
        }
        a{
          display: inline-block;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $primary_color!important;
          border:4px solid $gray-light;
          &:hover{
            cursor: pointer;
            color: $white!important;
            background-color: $primary_color;
            border:4px $primary_color;
          }
          &.pagination {
            &__next{
                i{
                  @include transform(rotate(180deg));
                  margin-top: -2px;
                }
            }
            &__prev, &__next{
                width: 90px;
                @include media-breakpoint-up(lg) {
                  width: 72px;
                }
                &.disabled {
                  opacity: 0.8;
                  background: $white;
                  pointer-events: none;
                  background-color: #f5f5f5;
                  color:#cccccc !important;
                }
            }
            &__number{
                font-size: 1.3rem;
            }
          }
        }
        &.active{
            a{
                color:#fff !important;
                background-color: $primary_color;
                border-color: $primary_color !important;
            }
        }
    }
}
