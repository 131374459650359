div.App.abonnement{
    background-color: $vanilla_light;
}
div.App{
    &.abonnement, &.formaat, &.samenstellen, &.samenstelling{
        .footer__middle{
            display: none;
            @include media-breakpoint-up(md) {
                display: flex;
            }
        }
    }
    &.subscription, &.formaat, &.samenstellen, &.samenstelling{
        padding-bottom: 74px;
        @include media-breakpoint-up(md) {
            padding-bottom: 0;
        }
    }
}

.subscription{
    &-box{
        background-color: $vanilla_light;
        min-height: 80vh;
        @include media-breakpoint-up(lg) {
            min-height: 60vh;
        }
        
        .primary-title{
            text-align: center;
            padding: 1rem;
            line-height: 1.2;

            p{
                margin: 0;
            }
        }

        .bundle__tabs .data-filter{
            max-width: 400px;
        }

        .quantity{
            .plus-btn, .minus-btn{
                height: 28px;
            }
            .number{
                height: 28px;
            }
            &.enabled{
                .plus-btn{
                    width: 28px;
                }
                .minus-btn{
                    width: 28px;
                }
            }
        }

        .progress-bar{
            background-color: $artichoke_regular;
            height: 4px;
            width: 100%;
            position: sticky;
            top: 66px;
            z-index: 99;
            @include media-breakpoint-up(md) {
                top: 92px;
            }
            @include media-breakpoint-up(lg) {
                top: 0;
            }

            .fill{
                height: 4px;
                background-color: red;
                @include transition(all, 0.4s, linear);
            }
        }

        .animal_group{
            hr{
                margin-top: -155px;
                @include media-breakpoint-up(md) {
                    margin-top: -175px;
                }
                @include media-breakpoint-up(lg) {
                    margin-top: -90px;
                }
            }
        }

        .farmshop__item{
            figure{
                .msg{
                    position: absolute;
                    bottom: 0;
                    padding: 0.3rem;
                    margin: 0.5rem;
                    font-size: 0.8rem;
                    line-height: 1;
                    background-color: rgba(255,255,255,0.6);
                    color: $black;
                    border-left: 3px solid $red;
                }
            }
        }

        .loading_box{
            background-color: rgba(255,255,255,0.3);
            min-height: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .loader{
                padding: 0;
            }
        }
    }
    &__review{
        background-color: #fff;
        padding: 1rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        position: relative;
        [class^=icon-]{
            font-size: 2rem;
            color: $artichoke_dark;
            margin-right: 1rem;
        }
        .icon-curated{
            @extend .icon-package;
        }
        .icon-customize{
            @extend .icon-meat;
        }
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            flex: 2;
            width: 100%;

            li{
                padding: 0.3rem 0;
                border-top: 1px solid $lightest_grey;
                display: flex;
                justify-content: space-between;

                .name{
                    margin-right: 1rem;
                }
                .desc{
                    color: $grey;
                    font-size: 0.9rem;
                    text-wrap: nowrap;
                }
                .next-date{
                    display: block;
                    color: $grey;
                }
                .order-size{
                    display: block;
                }
                &.column{
                    flex-direction: column;
                }
            }
        }
        &.customize{
            flex-direction: column;
        }
        .info{
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            width: 100%;
        }
        .title{
            flex: 2;
            margin-right: 1rem;
            .heading{
                display: block;
                font-weight: bold;
                margin-bottom: 0.2rem;
            }
            p{
                color: $grey;
                font-size: 0.9rem;
                line-height: 1.2;
            }
        }
        .edit-btn{
            background-image: url(../../../../img/subscription/pencil.svg);
            background-size: 18px;
            background-position: center;
            display: block;
            width: 32px;
            height: 32px;
            background-repeat: no-repeat;
            background-color: #fff;
            border:0;
            opacity: 0.4;
            @include transition(all, 0.2s, ease-in);
            &:hover{
                opacity: 1;
                border: 1px solid $artichoke_dark;
            }
        }
        &.loading{
            min-height: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .img{
                width: 70px;
                height: 70px;
                background-image: url(../../../../img/loading/loader.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 60px;
            }
            
        }

        &:hover{
            .edit-btn{
                opacity: 1;
            }
        }
    }
    &__item{
        background-color: #fff;
        @include border-radius(8px);
        padding: 1rem;
        margin: 0.5rem 0.5rem;
        text-align: left !important;
        .btn{
            font-size: 1rem;
        }
        .body{
            display: flex;
            [class^=icon-]{
                font-size: 3rem;
                margin-right: 1rem;
                color: $grey;
            }
            .icon-customize{
                @extend .icon-meat;
            }
            .icon-curated{
                @extend .icon-package;
            }
        }
        .title{
            margin-bottom: 1rem;
            .heading, p{
                margin: 0;
            }
            .heading{
                padding-right: 2rem;
            }
            p{
                color: $grey;
                line-height: 1.4 !important;
                padding-top: 0.5rem;
                padding-right: 1rem;
            }
        }
        .btn{
            width: 100%;
            justify-content: center;
        }

        &__info{
            padding: 0.4rem 0;
        }

        .more-info{
            padding-top: 0.5rem;
            border-top: 1px solid $light_grey;
            .handle{
                color: #999999;
                padding:0.5rem 0;
                cursor: pointer;
                &::before{
                    font-family: 'icomoon' !important;
                    content: "\e91d";
                    font-size: 0.8rem;
                    margin-right: 0.5rem;
                }
                &:hover{
                    color: $red;
                }
            }
            .info-item{
                max-height: 0px;
                overflow: hidden;
                @include transition(all, 0.3s, linear);
                ul{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li{
                        display: flex;
                        align-items: center;
                        font-size: 0.9rem;
                        padding: 4px 0;
                        &::before{
                            font-family: 'icomoon' !important;
                            font-size: 0.8rem;
                            margin-right: 0.5rem;
                        }
                        &.yes{
                            &::before{
                                color: red;
                                content: "\e909";
                            }
                        }
                        &.no{
                            &::before{
                                color: #999;
                                content: "\e918";
                            }
                        }
                    }
                }
            }
            &.open{
                .info-item{
                    max-height: 300px;
                }
                .handle{
                    color: $red;
                    &::before{
                        content: "\e91e";
                    }
                }
            }
        }
        &:has(input[type="radio"]:checked){
            .body{
                [class^=icon-], .title, p{
                    color: $black;
                }
            }
        }
        .checkbox{
            float: right;
        }
    }
    &__select{
        background-color: #fff;
        @include border-radius(8px);
        padding: 1rem;
        margin: 0.5rem 0.5rem;
        text-align: center;
        border: 1px solid #fff;
        @include transition(all, 0.2s, ease-in);
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .title{
            line-height: 1.2;
            .subtitle{
                color: $grey;
            }
            .heading{
                margin: 0;
            }
            p{
                line-height: 1.2;
            }
        }
        .cat-img{
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            span{
                background-size: contain;
                width: 100%;
                height: 70px;
            }
        }
        .desc{
            color: $grey;
            line-height: 1.2;
        }
        .s-price, .b-price{
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 1.2;

            .before{
                color: $grey;
            }
            .amount{
                font-size: 1.5rem;
                font-family: $title_font;
                padding-top: 0.1rem;
                sup{
                    top: -0.3rem;
                }
            }
            .after{
                color: $grey;
            }
        }
        .s-price{
            border-top: 1px solid $artichoke_light;
            margin-top: 0.8rem;
            padding-top: 0.8rem;
        }
        .b-price{
            padding-top: 0.8rem;
        }

        .checkbox{
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            span{
                width: 24px;
                height: 24px;
                border: 1px solid $artichoke_dark;
                display: flex;
                @include border-radius(20px);
                padding: 2px;
            }
        }
        &:hover, &:has(input[type="radio"]:checked){
            @include box-shadow(2px ,2px , 6px, rgba(0,0,0,0.1));
        }
        input[type="radio"] {
            display: none;
        }
        &:has(input[type="radio"]:checked){
            border-color: $artichoke_dark;
            .checkbox{
                span{
                    &:after{
                        content: "";
                        width: 18px;
                        height: 18px;
                        background-color: $artichoke_dark;
                        @include border-radius(20px);
                    }
                }
            }
        }
    }
    &__cta{
        background-color: $vanilla_light;
        display: flex;
        justify-content: center;
        padding: 1rem;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 5;
        @include box-shadow(0px ,-6px , 10px, rgba(0,0,0,0.03));
        @include media-breakpoint-up(md) {
            position: initial;
            padding-bottom: 3rem;
            @include box-shadow(0px ,0 , 0, rgba(0,0,0,0));
        }
        .btn{
            justify-content: center;
            flex: 2;
            @include media-breakpoint-up(md) {
                flex: initial;
            }
        }
        .price{
            font-size: 1.5rem;
            
            sup{
                font-size: 0.9rem;
            }
        }
        .count{
            display: flex;
            align-items: center;
            padding-right: 10px;

            .ico{
                background-image: url(../../../../img/subscription/package-gray.svg);
                background-size: cover;
                width: 40px;
                height: 40px;
                display: flex;
                margin-right: 6px;
                display: flex;
                align-items: end;
            }
            .ico-fill{
                background-image: url(../../../../img/subscription/package-fill.svg);
                background-size: cover;
                width: 40px;
                display: flex;
                background-position: bottom;
            }
            .numbers{
                white-space: nowrap;
            }
        }
        &.customizeCta{
            padding: 1rem !important;
            display: flex;
            @include media-breakpoint-up(md) {
                background-color: #fff;
                flex-direction: column;
            }
            .btn{
                @include media-breakpoint-up(md) {
                    max-width: initial !important;
                    min-width: initial !important;
                }
            }

            .title{
                display: none;
                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
            
            .count{
                @include media-breakpoint-up(md) {
                    margin-bottom: 1rem;
                    justify-content: center;
                    padding: 1rem 0;
                    background-color: $vanilla_lightest;
                }
            }
        }
        &.loading{
            .btn{
                height: 44px;
                @include media-breakpoint-up(sm) {
                    max-width: 280px;
                    min-width: 280px;
                }
            }
            .btn--loading{
                background-color: $gray-base;
                border-color: $gray-base;
                padding: 0.2rem;

                .ico{
                    background-image: url(../../../../img/loading/loader.svg);
                    background-size: contain;
                    width: 24px;
                    height: 24px;
                    filter: brightness(2);
                }
            }
        }
    }
}