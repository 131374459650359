@import 'themes/grutto/abstracts/variables';
@import 'themes/grutto/vendors/bootstrap/bootstrap';
@import 'themes/grutto/vendors/font-ico/icomoon';

@import 'themes/grutto/abstracts/mixins';
@import 'themes/grutto/abstracts/typography';
@import 'themes/grutto/abstracts/margin_padding_helper_mixin';
@import 'themes/grutto/abstracts/flexbox_position_helper';


@import 'themes/grutto/layout/header';
@import 'themes/grutto/layout/footer';
@import 'themes/grutto/layout/layouts';

// Components
@import 'themes/grutto/components/category';
@import 'themes/grutto/components/tooltip';
@import 'themes/grutto/components/animal_package';
@import 'themes/grutto/components/carousel';
@import 'themes/grutto/components/home_page';
@import 'themes/grutto/components/package_fit';
@import 'themes/grutto/components/compare_row';
@import 'themes/grutto/components/product_add';
@import 'themes/grutto/components/loader';
@import 'themes/grutto/components/table';
@import 'themes/grutto/components/pagination';
@import 'themes/grutto/components/filter';
@import 'themes/grutto/components/coupons';
@import 'themes/grutto/components/modal';
@import 'themes/grutto/components/traceability';
@import 'themes/grutto/components/meatcut';
@import 'themes/grutto/components/xmas';
@import 'themes/grutto/components/farmshop/farmshop';
@import 'themes/grutto/components/blog';
@import 'themes/grutto/components/breadcrumb';
@import 'themes/grutto/components/subscription';

@import 'themes/grutto/common'; 


// for rebranding A/B Test
@import 'themes/grutto/components/new-design';


// just for menu A/B Test
.abtest-none{
  display: none !important;
}

/* added by Arash. fix it later */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// -------------------------------------------------
// freshchat custom styles -------------------------
// -------------------------------------------------

  // place Left style ".place__left"
  #fc_frame.place__left{
    right: auto;
    left: 15px;
  }
  #fc_frame.place__left.fc-mobile-view.h-open-container,
  #fc_frame.place__left.fc-widget-fullscreen.fc-widget-normal,
  #fc_frame.place__left.fc-widget-fullscreen.fc-widget-small{
    left: 0;
    right: initial;
  }

  // for the price on product page
  #fc_frame {
    bottom: 90px !important;
    @include media-breakpoint-up(sm) {
      bottom: 70px !important;
    }
    @include media-breakpoint-up(md) {
      bottom: 15px !important;
    }
  }


// Trusted Shops custom styles --------------------------
div[id^="minimized-trustbadge"], div[id^="minimized-trustbadge"]:hover{
  @include media-breakpoint-down(sm) {
    inset:auto 10px 90px auto !important;
  }
}


// AB Test Temporary Style ----------
.original_menu li.top-menu0.haschild > ul > li > div:nth-child(1) > ul.cat-0 {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}