.farmshop{


    // Farmshop Full
    &__full{
        .back-to-list{
            margin-bottom: 10px;
            padding: 0;
            background-color: #fff;
            position: sticky;
            z-index: 99;
            top: 65px;
            overflow: hidden;
            @include media-breakpoint-up(md) {
                top: 92px;
            }
            @include media-breakpoint-up(lg) {
                position: initial;
            }
            .col{
                display: flex;
                @include media-breakpoint-up(lg) {
                    justify-content: center;
                }
            }
            a{
                display: flex;
                align-items: center;
                padding: 1rem 0rem;
                @include media-breakpoint-up(sm) {
                    padding: 1rem 1rem;
                }
                @include media-breakpoint-up(lg) {
                    padding: 2rem 1.4rem;
                }
                &:hover{
                    color: $darkest-grey;
                }
                i{
                    margin-right: 0.4em;
                    font-size: 1.5rem;
                    @include media-breakpoint-up(lg) {
                        font-size: 1rem;
                    }
                }
            }
        }
        .package__btn{
            .btn{
                text-transform: uppercase;
            }
        }
        .meatcut_description{
            text-align: justify;
            margin-bottom: 2rem;
        }

        .img-holder{
            position: relative;
            margin-bottom: 2rem;
            &.bio{
                &::before{
                    content: " ";
                    position: absolute;
                    display: block;
                    left: initial;
                    right: 10px;
                    top: -8px;
                    background-image: url(../../../../../img/farmshop/highlight_bio_nl.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 58px;
                    height: 70px;
                    z-index: 10;
                    @include media-breakpoint-up(lg) {
                        left: 10px;
                        right: initial;
                    }
                }   
            }
            &::after{
                content: " ";
                position: absolute;
                display: block;
                bottom: 12px;
                left: 12px;
                width: 20px;
                height: 20px;
                background-image: url(../../../../../img/farmshop/frozen.svg);
                background-size: contain;
                background-repeat: no-repeat;
                z-index: 10;
            }
        }
        .package__full--gallery{
            padding-bottom: 0 !important;
            .carousel__nav{
                bottom: 15px;
            }
        }
        &.type--salmon{
            .img-holder{
                &::before{
                    content: "";
                    background-image: url(../../../../../img/bio/certified-sustainable-seafood-by-msc.svg);
                    background-size: contain;
                    background-position: top left;
                    background-repeat: no-repeat;
                    position: absolute;
                    left: 15px;
                    top: 15px;
                    z-index: 10;
                    width: 50px;
                    height: 70px;
                    @include media-breakpoint-up(md) {
                      top: 20px;
                      width: 70px;
                      height: 90px;
                      left: 20px;
                    }
                }  
            } 
        }
        .cross-sell{
            &__info{
                background-color: #919678;
                padding: 1rem;
                margin-bottom: 1.4rem;
                color: #fff;
                p{
                    margin: 0;
                }
                .heading{
                    color: #fff;
                    font-size: 1.2rem;
                    line-height: 1.1;
                }
                .img-txt{
                    display: flex;
                    align-items: center;
                    padding: 0.4rem 0;
        
                    svg{
                        margin-right: 1rem;
                    }
                    p{
                        flex: 2;
                    }
                }
            }
            &__container{
                margin-bottom: 1.4rem;
                .heading{
                    font-family:$title_font !important;
                }
            }
            &__products{
                display: flex;
                justify-content: space-between;
                margin: 0;
                flex-wrap: wrap;
                
                div.farmshop__item{
                    width: calc(50% - 0.6rem) !important;
                    margin: 0 0 1.2rem 0 !important;
                    border: 1px solid $artichoke_lightest;
                }
            }
        }

        .feedback-box{
            max-width: 280px;
            margin: 0 auto;
            @include media-breakpoint-up(md) {
                max-width: 400px;
            }
        }
    }
    &__title{
        margin-bottom: 1rem;
        .heading{
            margin: 0;
            font-family:$title_font !important;
        }
        .info{
            color: #bcbcbc;
            font-size: 14px;
            display: flex;
            .amount{
                margin: 0 0.2rem;
                sup{
                    top: 0em;
                    font-size: 100%;
                }
            }
        }
    }
    &__price{ //full page
        display: flex;
        align-items: center;
        font-family:$title_font;
        .price, .p-prev{
            line-height: 1;
            font-size: 1.7rem;
            margin: 0;
            @include media-breakpoint-up(sm) {
              font-size: 2.125rem;
            }
            sup{
                top: -8px;
                left: 0px;
                font-size: 1rem;
                @include media-breakpoint-up(sm) {
                    font-size: 1.125rem;
                    top: -12px;
                    left: -2px;
                }
            }
            h4{
                margin: 0;
            }
        }
        .sale_price{
            &.p-prev{
                color: $red;
                margin-right: 6px;
            }
            &.price{
                font-weight: normal !important;
                margin-left: 5px;
                position: relative;
                color: #999999;
                .amount{
                    font-weight: normal !important;
                    font-size: 1.1rem !important;
                }
    
                &::after{
                    content: "";
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    background: #666666;
                    left: 0;
                    transform: rotate(-8deg);
                    top: 6px;
                }
                sup{
                    top: -3px !important;
                    font-size: 0.9rem !important;
                }
            }
        }
        .info{
            margin-left: 0.5rem;
            color: $dark_grey;
            font-family: $body-font;
        }
    }
    &__spec{
        display: none;
        justify-content: space-around;
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 2rem;
        }
        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }
    &__order{
        background-color: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 0.6rem;
        border-top: 1px solid #F4F4F4;
        width: 100%;
        z-index: 999;

        @include media-breakpoint-up(lg) {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            margin-bottom: 1rem;
            padding: 0.5rem 0;
            position: initial;
            justify-content: space-between;
        }

        .package__btn{
            min-width: 145px;
        }

        &-holder{
            display: flex;
            justify-content: space-between;
            @include make-container();
            @include make-container-max-widths();
            @include media-breakpoint-up(sm) {
                padding: 0 16px;
            }
            @include media-breakpoint-up(md) {
                padding: 0 8px;
            }
            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
    }
    &__usp{
        list-style: none;
        padding: 0;
        margin-bottom: 1rem;
        font-size: 0.9rem;
        li{
          display: flex;
          align-items: center;
          padding: 0.3rem 0;
          line-height: 1;  
          [class^=icon-]{
            color: $artichoke_dark;
            margin-right: 0.4rem;
            font-size: 1.25rem;
            min-width: 22px;
            text-align: center;
          }
        }
    }
}
.App.farmshop-products{
    padding-bottom: 52px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }
    &.boerderij-box, &.farmbox{
        padding-bottom: 0 !important;
    }
}